<template>
  <Form
    @submit="onSubmitContact"
    :validation-schema="contactSchema"
    v-slot="{ errors }"
  >
    <div class="input-block">
      <label class="form-label"
        >Tên đầy đủ của bạn <span class="text-danger">*</span></label
      >
      <Field
        name="fullName"
        type="text"
        class="form-control mt-2"
        placeholder="Nhập"
        :class="{ 'is-invalid': errors.fullName }"
        v-model="googleData.userInfo.name"
      />
      <div class="invalid-feedback">{{ errors.fullName }}</div>
      <div class="emailshow text-danger" id="fullName"></div>
    </div>
    <div class="input-block mt-3 mb-0">
      <a-checkbox v-model:checked="isRenting">Bạn muốn cho thuê?</a-checkbox>
    </div>
    <div v-if="isRenting">
      <div class="input-block mt-2">
        <label class="form-label">Số zalo</label>
        <Field
          name="zalo"
          type="text"
          class="form-control"
          placeholder="Nhập số zalo"
          :class="{ 'is-invalid': errors.zalo }"
        />
        <div class="invalid-feedback">{{ errors.zalo }}</div>
        <div class="emailshow text-danger" id="zalo"></div>
      </div>
      <div class="input-block mt-3">
        <label class="form-label">Địa chỉ Facebook</label>
        <Field
          name="facebook"
          type="text"
          class="form-control"
          placeholder="Nhập địa chỉ Facebook"
          :class="{ 'is-invalid': errors.facebook }"
        />
        <div class="invalid-feedback">{{ errors.facebook }}</div>
        <div class="emailshow text-danger" id="facebook"></div>
      </div>
      <div class="input-block mt-3">
        <label class="form-label">Số điện thoại </label>
        <div class="pass-group">
          <Field
            name="phone"
            type="text"
            class="form-control pass-input"
            placeholder="Nhập số điện thoại"
            :class="{ 'is-invalid': errors.phone }"
          />

          <div class="invalid-feedback">{{ errors.phone }}</div>
          <div class="emailshow text-danger" id="phone"></div>
        </div>
      </div>

      <p class="emailshow text-danger mt-3" ref="contactErrorRef" />
    </div>
    <div class="input-block">
      <a-checkbox v-model:checked="isAgreeWithTerms">
        Bạn tạo tài khoản là đồng ý với quy định,
        <router-link target="_blank" to="/dieu-khoan">điều khoản </router-link>
        sử dụng của chúng tôi</a-checkbox
      >
    </div>
    <b-button
      class="primary btn-block mt-3"
      type="submit"
      :disabled="!isAgreeWithTerms"
      >Tiếp theo
    </b-button>
  </Form>
  <spinner-modal :visible="isLoading" />
</template>
<script setup>
import { ref, watch, computed, reactive, watchEffect } from "vue";
import { router } from "@/router";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { isValidPhone, isValidFb } from "@/utils/common";
import { message } from "ant-design-vue";
import authService from "@/services/AuthService";
import { decodeCredential } from "vue3-google-login";

const store = useStore();
const loginSucceed = computed(() => store.getters["auth/getSuccess"]);

const isLoading = ref(false);
const isRenting = ref(false);
const contactErrorRef = ref(null);

const googleData = reactive({
  type: "",
  token: "",
  userInfo: { name: "" },
});

const googleLoginData = computed(() => store.getters["auth/googleLoginData"]);
const isAgreeWithTerms = ref(false);

watchEffect(async () => {
  const { value } = googleLoginData;
  try {
    if (value.credential) {
      googleData.type = "credential";
      googleData.token = googleLoginData.value.credential;
      googleData.userInfo = decodeCredential(googleData.token);
    } else {
      googleData.type = "accessToken";
      googleData.token = value.accessToken;

      const res = await authService.getUserGoogleInfo(value.accessToken);
      googleData.userInfo = res.data;
    }
  } catch (error) {
    console.log(error);
    message.error("Lỗi hệ thống, vui lòng thử lại sau");
  }
});

watch(loginSucceed, (newVal, oldVal) => {
  if (newVal !== undefined) {
    isLoading.value = false;
    if (newVal === true) {
      store.commit("auth/succeed", undefined);
      router.push("/");
    } else {
      message.error("Lỗi hệ thống, vui lòng thử lại sau");
    }
    store.commit("auth/succeed", undefined);
  }
});

const contactSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Tên đầy đủ là trường bắt buộc")
    .max(50, "Tên đầy đủ chỉ có tối đa 50 kí tự"),
  zalo: Yup.string()
    .nullable()
    .test("isValidPhone", "Số zalo không hợp lệ", isValidPhone),
  facebook: Yup.string()
    .nullable()
    .test("isValidFb", "Địa chỉ facebook không hợp lệ", isValidFb),
  phone: Yup.string()
    .nullable()
    .test("isValidPhone", "Số điện thoại không hợp lệ", isValidPhone),
});

const onSubmitContact = (values) => {
  if (isRenting.value && !(values.zalo || values.facebook || values.phone)) {
    contactErrorRef.value.innerHTML =
      "Vui lòng điền ít nhất 1 trường thông tin liên hệ";
    return;
  }
  isLoading.value = true;
  store.dispatch("auth/loginWithGoogle", {
    ...(googleData.type === "credential"
      ? { credential: googleData.token }
      : { accessToken: googleData.token }),
    ...values,
  });
};
</script>
