<template>
  <layout-header></layout-header>

  <template v-if="id && isFetching">
    <div></div>
  </template>

  <template v-else-if="!id || currentItem !== undefined">
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <!-- Content Header -->
        <div class="content-header">
          <h4>{{ !id ? "Tạo sản phẩm" : "Cập nhật sản phẩm" }}</h4>
        </div>
        <!-- /Content Header -->

        <div class="row">
          <div class="col-lg-12 d-flex">
            <div class="card book-card flex-fill mb-0">
              <div class="card-body p-5">
                <a-form
                  :model="form"
                  ref="formRef"
                  :rules="formRules"
                  name="basic"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                  autocomplete="off"
                >
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="input-block">
                        <a-form-item label="Sản phẩm" name="type">
                          <a-select
                            v-model:value="form.type"
                            :options="ProductTypeList"
                            placeholder="Vui lòng chọn"
                            :disabled="id"
                          ></a-select>
                        </a-form-item>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <device-form
                        ref="customFormRef"
                        v-if="type === 'device'"
                        :brands="brands"
                        :years="years"
                        :device="currentItem ? item.device : null"
                      />
                      <lightstick-form
                        ref="customFormRef"
                        v-if="type === PRODUCT_TYPE.LIGHTSTICK"
                        :brands="brands"
                        :years="years"
                        :lightstick="currentItem ? item.lightstick : null"
                      />
                      <digital-camera-form
                        ref="customFormRef"
                        v-if="type === PRODUCT_TYPE.DIGITALCAMERA"
                        :brands="brands"
                        :years="years"
                        :digitalcamera="currentItem ? item.digitalcamera : null"
                      />
                      <shoe-form
                        ref="customFormRef"
                        v-if="type === PRODUCT_TYPE.SHOE"
                        :brands="brands"
                        :shoe="currentItem ? item.shoe : null"
                      />
                      <membership-form
                        ref="customFormRef"
                        v-if="type === PRODUCT_TYPE.MEMBERSHIP"
                        :brands="brands"
                        :years="years"
                        :membership="currentItem ? item.membership : null"
                      />
                      <speaker-form
                        ref="customFormRef"
                        v-if="type === PRODUCT_TYPE.SPEAKER"
                        :brands="brands"
                        :years="years"
                        :speaker="currentItem ? item.speaker : null"
                      />
                    </div>

                    <div
                      class="col-lg-12"
                      v-if="
                        form.type &&
                        AccessoryOptions[form.type] &&
                        AccessoryOptions[form.type].length
                      "
                    >
                      <div class="input-block">
                        <a-form-item label="Phụ kiện" name="accessories">
                          <a-checkbox-group v-model:value="form.accessories">
                            <a-checkbox
                              :value="item.value"
                              name="accessories"
                              :key="item"
                              v-for="item in AccessoryOptions[form.type]"
                            >
                              {{ item.label }}</a-checkbox
                            >
                          </a-checkbox-group>
                        </a-form-item>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="input-block">
                        <a-form-item label="Hình ảnh" name="images">
                          <p>Ít nhất 1 ảnh - nhiều nhất 10 ảnh</p>
                          <p><small>Định dạng cho phép: png, jpeg</small></p>
                          <div class="row">
                            <div class="col-lg-4">
                              <multiple-images-upload
                                class="mt-3"
                                :fileList="form.images"
                                :maxCount="10"
                                :minHeight="200"
                                :maxHeight="1500"
                                :accept="'image/png, image/jpg'"
                                @fileListChanged="onImagesChange"
                                @fileRemoved="
                                  (file) => {
                                    if (file.id) removeIds.push(file.id);
                                  }
                                "
                              />
                              <p class="color-red" v-if="isErrorFormat">
                                Vui lòng chọn ảnh có định dạng image/png hoặc
                                image/jpeg
                              </p>
                            </div>
                          </div>
                        </a-form-item>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="input-block">
                        <a-form-item
                          label="Mô tả riêng của bạn về sản phẩm "
                          name="description"
                        >
                          <a-textarea
                            v-model:value="form.description"
                            class="full-width form-control"
                            placeholder="Vui lòng nhập"
                          />
                        </a-form-item>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="input-block">
                        <a-form-item label="Khu vực cho thuê" name="locations">
                          <a-select
                            v-model:value="form.locations"
                            mode="multiple"
                            style="width: 100%"
                            placeholder="Bạn có thể chọn nhiều"
                            :options="locations"
                            :showArrow="true"
                            optionFilterProp="label"
                          ></a-select>
                        </a-form-item>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block">
                        <a-form-item
                          label="Địa điểm giao dịch"
                          name="deliveryType"
                        >
                          <a-checkbox-group v-model:value="form.deliveryType">
                            <a-checkbox
                              :value="DELIVERY_TYPE.AUTOPICK"
                              name="deliveryType"
                              class="font-light"
                            >
                              Khách tới tận nơi</a-checkbox
                            >
                            <a-checkbox
                              :value="DELIVERY_TYPE.SHIP"
                              class="font-light"
                              name="deliveryType"
                            >
                              Bạn giao tận nơi cho khách</a-checkbox
                            >
                          </a-checkbox-group>
                        </a-form-item>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block">
                        <a-form-item label="Tài sản thế chấp" name="collateral">
                          <a-textarea
                            v-model:value="form.collateral"
                            class="full-width form-control"
                            placeholder="Vui lòng nhập"
                          />
                        </a-form-item>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="input-block">
                        <label
                          >Phụ phí phát sinh (nếu không có, bạn có thể bỏ qua
                          phần này)</label
                        >

                        <div
                          v-for="(item, index) in form.extraFees"
                          :key="index"
                          class="fee-item bg-danger-light full-width p-3 border-3 m-0 mt-1 d-flex"
                        >
                          <div class="row" style="flex: 1">
                            <div class="col-md-4">
                              <a-form-item
                                label="Tên phụ phí"
                                :name="['extraFees', index, 'name']"
                                :rules="[
                                  {
                                    required: true,
                                    message: 'Vui lòng nhập',
                                    trigger: 'blur',
                                  },
                                  {
                                    validator: checkEmptyString,
                                    trigger: 'blur',
                                  },
                                ]"
                              >
                                <a-input
                                  v-model:value="item.name"
                                  placeholder="Vui lòng nhập"
                                  class="form-control"
                                />
                              </a-form-item>
                            </div>
                            <div class="col-md-4">
                              <a-form-item
                                label="Mô tả phụ phí"
                                :name="['extraFees', index, 'description']"
                              >
                                <a-input
                                  v-model:value="item.description"
                                  placeholder="Vui lòng nhập"
                                  class="form-control"
                                />
                              </a-form-item>
                            </div>
                            <div class="col-md-4 price-addon">
                              <form-input-number
                                v-model="item.fee"
                                label="Phí"
                                :name="['extraFees', index, 'fee']"
                                :rules="
                                  numberRules({ isRequired: true, min: 0 })
                                "
                                input-class="form-control"
                                addon-after="K"
                                placeholder="Vui lòng nhập"
                              ></form-input-number>
                            </div>
                          </div>
                          <div class="delete-icon">
                            <delete-two-tone
                              two-tone-color="red"
                              @click="onRemoveFee(index)"
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn-light-pink mb-3"
                        @click="
                          form.extraFees.push({
                            name: '',
                            description: '',
                            fee: null,
                          })
                        "
                      >
                        +Thêm phụ phí
                      </button>
                    </div>

                    <div class="col-lg-12">
                      <div class="input-block">
                        <a-form-item
                          label="Điều khoản riêng (nếu có)"
                          name="personalTerm"
                        >
                          <a-textarea
                            v-model:value="form.personalTerm"
                            class="full-width form-control"
                          />
                        </a-form-item>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <router-link
                      to="/quan-ly/san-pham"
                      class="btn-border-default mt-3 mr-3"
                    >
                      Huỷ
                    </router-link>
                    <a-button
                      class="btn-default mt-3"
                      @click="handleFormSubmit"
                    >
                      {{
                        id ? "Cập nhật sản phẩm" : "Tạo sản phẩm cho thuê mới"
                      }}
                    </a-button>
                  </div>
                </a-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <spinner-modal :visible="isLoading" />
</template>

<script setup>
import {
  ref,
  createVNode,
  reactive,
  watchEffect,
  onMounted,
  computed,
  watch,
} from "vue";
import { CheckOutlined, DeleteTwoTone } from "@ant-design/icons-vue";
import MultipleImagesUpload from "@/components/multiple-images-upload.vue";
import { Modal } from "ant-design-vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import DeviceForm from "./product-types/device-form.vue";
import LightstickForm from "./product-types/lightstick-form.vue";
import DigitalCameraForm from "./product-types/digitalcamera-form.vue";
import ShoeForm from "./product-types/shoe-form.vue";
import SpeakerForm from "./product-types/speaker-form.vue";
import MembershipForm from "./product-types/membership-form.vue";
import {
  PRODUCT_TYPE,
  DELIVERY_TYPE,
  ProductTypeList,
  AccessoryOptions,
} from "@/constants";
import {
  trimStringFields,
  checkEmptyString,
  numberRules,
} from "@/utils/common";
import pickBy from "lodash.pickby";
import isEqual from "lodash.isequal";
import get from "lodash.get";
import isObject from "lodash.isobject";

const otherBrandOptionValue = -1;

const store = useStore();
const router = useRouter();

onMounted(() => {
  store.dispatch("location/getList"),
    {
      pagination: {
        pageSize: 100,
      },
      sort: "order",
    };
});

const customFormRef = ref(null);
const isFetching = computed(() => store.getters["thing/getLoading"]);
const currentItem = computed(() => store.getters["thing/getCurrentItem"]);
const currentUser = computed(() => store.getters["auth/currentUser"]);
const id = router.currentRoute.value.params.id;
const isSuccess = computed(() => store.getters["thing/getSuccess"]);
const isLoading = ref(false);
const isErrorFormat = ref(false);

watch(isSuccess, (newVal, oldVal) => {
  if (newVal !== undefined) {
    isLoading.value = false;
    if (newVal === true)
      if (id) {
        message.success("Cập nhật thành công");
        router.push("/quan-ly/san-pham");
      } else {
        Modal.confirm({
          title: "Đăng sản phẩm thành công",
          icon: createVNode(CheckOutlined),
          okText: "Tạo lịch thuê",
          cancelText: "Bỏ qua",
          content:
            "Bạn đã đăng sản phẩm thành công. Hãy tạo lịch cho sản phẩm nhé!",
          onOk() {
            router.push(`/quan-ly/cap-nhat/${currentItem.value.id}/lich`);
          },
          onCancel() {
            router.push("/quan-ly/san-pham");
          },
        });
      }
    else {
      message.error("Có lỗi vui lòng thử lại");
    }
    store.commit("thing/succeed", undefined);
  }
});
const item = reactive({
  device: null,
  [PRODUCT_TYPE.LIGHTSTICK]: null,
  [PRODUCT_TYPE.DIGITALCAMERA]: null,
  // [PRODUCT_TYPE.SHOE]: null,
  // [PRODUCT_TYPE.SPEAKER]: null,
  [PRODUCT_TYPE.MEMBERSHIP]: null,
});
const removeIds = ref([]);

if (id) {
  store.dispatch("thing/loadCurrentItem", {
    id,
    params: {
      populate: [
        "images",
        "brand",
        "extraFees",
        "locations",
        "device",
        "digitalcamera",
        "shoe",
        "lightstick",
        "membership",
        "speaker",
        "owner",
      ],
      select: [
        "type",
        "deliveryType",
        "type",
        "description",
        "collateral",
        "personalTerm",
        "brandOtherText",
      ],
    },
  });
} else {
  store.commit("thing/notFound");
}

const years = Array.from(
  { length: new Date().getFullYear() - 1899 },
  (_, index) => {
    const year = new Date().getFullYear() - index;
    return { label: year.toString(), value: year };
  }
);

const brands = computed(() => {
  const list = store.getters["brand/list"].map((item) => {
    return {
      value: item.id,
      label: item.attributes.name,
    };
  });

  return [...list, { value: otherBrandOptionValue, label: "Khác" }];
});

const locations = computed(() =>
  store.getters["location/list"].map((item) => {
    return {
      value: item.id,
      label: item.attributes.name,
    };
  })
);

const formRef = ref();
const form = reactive({
  type: null,
  images: [],
  deliveryType: [],
  description: "",
  collateral: "",
  personalTerm: "",
  locations: [],
  extraFees: [],
  accessories: [],
});

const formRules = reactive({
  type: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "blur",
    },
  ],
  images: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "blur",
    },
  ],
  locations: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "blur",
    },
  ],
  deliveryType: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "change",
    },
  ],
  collateral: [
    {
      required: true,
      message: "Vui lòng nhập",
      trigger: "blur",
    },
    { validator: checkEmptyString, trigger: "blur" },
  ],
  description: [
    {
      required: true,
      message: "Vui lòng nhập",
      trigger: "blur",
    },
    { validator: checkEmptyString, trigger: "blur" },
  ],
});

const type = computed(() =>
  [PRODUCT_TYPE.PC, PRODUCT_TYPE.PHONE, PRODUCT_TYPE.TABLET].includes(form.type)
    ? "device"
    : form.type
);

watchEffect(() => {
  if (id && !isFetching.value)
    if (currentItem.value === undefined) router.push("/quan-ly/san-pham");
    else {
      if (
        currentItem.value.owner &&
        currentItem.value.owner.id !== currentUser.value.id
      )
        router.push("/");
      // map fields
      const { value } = currentItem;
      form.type = value.type;
      form.images = value.images || [];
      form.deliveryType =
        value.deliveryType === DELIVERY_TYPE.BOTH
          ? [DELIVERY_TYPE.AUTOPICK, DELIVERY_TYPE.SHIP]
          : [value.deliveryType];
      form.locations = (value.locations || []).map((item) => item.id);
      form.description = value.description;
      form.collateral = value.collateral;
      form.personalTerm = value.personalTerm;
      form.extraFees = [...value.extraFees?.map(({ id, ...data }) => data)];
      form.accessories = (AccessoryOptions[value.type] || [])
        .filter((item) => value[type.value][item.value] === true)
        .map((item) => item.value);

      item[type.value] = value[type.value];
      item[type.value].brand = value.brand?.id || otherBrandOptionValue;
      item[type.value].brandOtherText = value.brandOtherText;
    }
});

watchEffect(() => {
  if (type.value) {
    store.dispatch("brand/getList", {
      sort: "name",
      filters: { type: form.type },
    });
  }
});

const handleFormSubmit = async () => {
  let isError = false;

  await Promise.all([
    formRef.value
      .validate()
      .then()
      .catch(() => (isError = true)),
    customFormRef.value
      ? customFormRef.value
          .validateForm()
          .then()
          .catch(() => (isError = true))
      : {},
  ]);

  if (isError) {
    const firstErrorField = document.querySelector(".ant-form-item-has-error");
    if (firstErrorField) {
      const rect = firstErrorField.getBoundingClientRect();
      window.scrollTo({
        top: rect.top + window.scrollY - 30,
        behavior: "smooth",
      });
    }
    return;
  }

  const customValues = customFormRef.value.values;
  const data = {
    ...form,
    brand: customValues.brand,
    brandOtherText: customValues.brandOtherText,
  };

  form.deliveryType.length == 2
    ? (data.deliveryType = DELIVERY_TYPE.BOTH)
    : (data.deliveryType = form.deliveryType[0]);

  if (data.brand === otherBrandOptionValue) delete data.brand;
  else data.brandOtherText = null;

  if (
    [
      PRODUCT_TYPE.PHONE,
      PRODUCT_TYPE.DIGITALCAMERA,
      PRODUCT_TYPE.LIGHTSTICK,
    ].includes(form.type)
  ) {
    const item = { ...customValues };
    (AccessoryOptions[form.type] || []).forEach((accessory) => {
      item[accessory.value] = data.accessories.includes(accessory.value);
    });

    data[type.value] = item;
  } else {
    data[type.value] = customValues;
  }

  delete data.accessories;
  delete data[type.value].accessories;
  delete data.images;
  currentItem.value ? update(id, data) : create(data);
};

const create = (data) => {
  isLoading.value = true;
  store.dispatch("thing/createThenUpdateImages", {
    createData: trimStringFields(data),
    imagesData: {
      removeIds: [],
      images: form.images.map((item) => item.originFileObj),
    },
  });
};

const update = async (id, data) => {
  isLoading.value = true;
  const updateData = trimStringFields(
    getUpdateData({ ...currentItem.value }, { ...data })
  );
  store.dispatch("thing/updateThenUpdateImages", {
    id,
    updateData,
    imagesData: {
      removeIds: removeIds.value,
      images: (form.images.filter((item) => item.originFileObj) || []).map(
        (item) => item.originFileObj
      ),
    },
  });
};

const getUpdateData = (currentData, newData) => {
  currentData.brand = currentData.brand?.id || null;
  return pickBy(newData, (value, key) => {
    if (isObject(value) && isObject(currentData[key])) {
      let current = get(currentData, key);
      if (key === "extraFees") {
        current = current.map(({ id, ...data }) => data);
      } else if (Array.isArray(current)) current = current.map(({ id }) => id);

      return !isEqual(value, current);
    }
    return !isEqual(value, get(currentData, key));
  });
};

const onRemoveFee = (index) => {
  form.extraFees.splice(index, 1);
};

const onImagesChange = (newFileList) => {
  const { file, fileList } = newFileList;
  if (file.status === "removed") isErrorFormat.value = false;
  else isErrorFormat.value = !["image/jpeg", "image/png"].includes(file.type);
  if (!isErrorFormat.value) form.images = fileList;
};
</script>
<style scoped>
.font-light {
  font-weight: 400 !important;
}
.code-box-demo .ant-slider {
  margin-bottom: 16px;
}
.ant-modal-confirm-btns button {
  border: none;
  box-shadow: none;
  color: #3ad93a;
  font-weight: 500;
  background: none;
}
.ant-modal-confirm-btns button:hover {
  border: none !important;
  box-shadow: none !important;
  color: #3ad93a !important;
  font-weight: 600 !important;
  background: none !important;
}
.delete-icon span {
  font-size: 1.2em;
  cursor: pointer;
}
@media only screen and (min-width: 767px) {
  .fee-item {
    gap: 10px;
    align-items: center;
    .delete-icon {
      margin-top: 12px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .card-body {
    padding: 1em !important;
  }
}
.price-addon {
  ::v-deep .ant-input-number-group-wrapper {
    padding: 0 !important;
    border-style: none !important;
  }

  ::v-deep .ant-input-number-group-addon {
    border-style: none !important;
  }
}
</style>
