import { createStore } from "vuex";
import VuexPersist from "vuex-persist";
import auth from "./modules/auth";
import brand from "./modules/brand";
import location from "./modules/location";
import user from "./modules/user";
import thing from "./modules/thing";

const vueLocalStorage = new VuexPersist({
  key: "userInfo",
  storage: window.localStorage,
  reducer: (state) => state.auth,
});

const store = createStore({
  modules: { auth, brand, location, user, thing },
  plugins: [vueLocalStorage.plugin],
});

export default store;
