<template>
  <div class="clearfix">
    <a-upload
      :before-upload="beforeUpload"
      list-type="picture-card"
      :file-list="fileList"
      @preview="handlePreview"
      @change="handleChange"
      :accept="accept"
      :maxCount="maxCount"
    >
      <div v-if="fileList && fileList.length < maxCount">
        <plus-outlined />
        <div class="ant-upload-text">Upload</div>
      </div>
    </a-upload>
    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="handleCancel"
    >
      <img
        alt="example"
        style="width: 100%"
        :src="previewImage"
      />
    </a-modal>
    <p
      id="upload-error"
      class="validate-error color-red"
    ></p>
  </div>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";
import { message } from "ant-design-vue";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default defineComponent({
  props: {
    fileList: Array,
    maxCount: {
      type: Number,
      required: false,
      default: 10,
    },
    minHeight: {
      type: Number,
      required: false,
      default: 200,
    },
    maxHeight: {
      type: Number,
      required: false,
      default: 2000,
    },
    isLandscape: {
      type: Boolean,
      required: false,
      default: false,
    },
    accept: {
      type: String,
      required: false,
      default: "image/*",
    },
  },
  emits: ["fileListChanged", "fileRemoved"],
  components: {
    PlusOutlined,
  },
  setup(props, { emit }) {
    const previewVisible = ref(false);
    const previewImage = ref("");

    const handleCancel = () => {
      previewVisible.value = false;
    };

    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
    };

    const handleChange = ({ file, fileList }) => {
      if (file.status === "removed") {
        emit("fileRemoved", file);
        emit("fileListChanged", { file, fileList });
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const isLandscape = props.isLandscape ? img.width > img.height : true;
        const errorElement = document.getElementById("upload-error");

        if (!isLandscape) {
          errorElement.innerHTML = `Vui lòng chọn ảnh chữ nhật ngang`;
          return;
        }

        if (img.height < props.minHeight) {
          errorElement.innerHTML = `Chiều cao tối thiểu của ảnh là ${props.minHeight}px!`;
          return;
        }

        if (img.height <= props.maxHeight) {
          emit("fileListChanged", { file, fileList });
          return;
        }

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const ratio = props.maxHeight / img.height;
        canvas.width = img.width * ratio;
        canvas.height = props.maxHeight;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          const newFile = new File([blob], file.name, { type: file.type });
          fileList.map((item) => {
            if (item.uid === file.uid) {
              item.originFileObj = newFile;
            }
          });
          emit("fileListChanged", { file: newFile, fileList });
        }, file.type);
      };
    };

    const beforeUpload = () => {
      document.getElementById("upload-error").innerHTML = ``;
      return false;
    };

    return {
      previewVisible,
      previewImage,
      handleCancel,
      handlePreview,
      handleChange,
      beforeUpload,
    };
  },
});
</script>

<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
