<template>
  <layout-header></layout-header>

  <index-banner></index-banner>

  <index-service></index-service>

  <index-popular></index-popular>

  <index-cartypes></index-cartypes>

  <index-rental></index-rental>

  <index-choose></index-choose>

  <home-faq></home-faq>

  <create-product-button></create-product-button>

  <layout-footer></layout-footer>
</template>
