<script setup>
import { ref, reactive, watch } from "vue";
import { MemoryOptions } from "@/constants";
import { checkEmptyString, numberRules } from "@/utils/common";

const props = defineProps({
  brands: { type: Array, required: true },
  years: { type: Array, required: true },
  device: { type: Object, required: false },
});
const emits = defineEmits([]);
const formRef = ref();
const form = reactive({
  brand: null,
  brandOtherText: "",
  model: "",
  expYear: null,
  color: "",
  memory: null,
  remainCapacity: null,
  serialNo: "",
  accessories: [],
  capacity: null,
});
const tooltipOpen = ref(true);

watch(
  () => form.brand,
  (newValue, oldValue) => {
    if (newValue === -1 || oldValue === -1) {
      tooltipOpen.value = false;
      setTimeout(() => {
        tooltipOpen.value = true;
      }, 100);
    }
  }
);

const formRules = reactive({
  brand: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "blur",
    },
  ],
  brandOtherText: [
    {
      required: true,
      message: "Vui lòng nhập",
      trigger: "blur",
    },
    { validator: checkEmptyString, trigger: "blur" },
  ],
  model: [
    {
      required: true,
      message: "Vui lòng nhập",
      trigger: "blur",
    },
    { validator: checkEmptyString, trigger: "blur" },
  ],
  expYear: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "blur",
    },
  ],
  color: [
    {
      required: true,
      message: "Vui lòng nhập",
      trigger: "blur",
    },
    { validator: checkEmptyString, trigger: "blur" },
  ],
  memory: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "blur",
    },
  ],
  remainCapacity: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "blur",
    },
  ],
  capacity: numberRules({ isRequired: true, min: 0 }),
});

if (props.device) {
  Object.assign(form, props.device);
}

const validateForm = async () => {
  return formRef.value.validate();
};
defineExpose({ validateForm, values: form });
</script>

<template>
  <a-form
    :model="form"
    ref="formRef"
    :rules="formRules"
    name="basic"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    class="row"
  >
    <div class="col-lg-6">
      <div class="input-block">
        <a-form-item
          label="Nhãn hiệu"
          name="brand"
        >
          <a-select
            v-model:value="form.brand"
            style="width: 100%"
            :options="props.brands"
            placeholder="Vui lòng chọn"
          ></a-select>
        </a-form-item>
        <div
          v-if="form.brand == -1"
          class="d-flex align-items-start"
        >
          <img
            src="@/assets/img/other_brand_arrow.svg"
            alt="Other brand"
            style="margin-top: 18px"
          />
          <div class="input-block full-width ml-3">
            <a-form-item
              label="Nhãn hiệu khác"
              name="brandOtherText"
            >
              <a-input
                v-model:value="form.brandOtherText"
                class="full-width form-control"
                placeholder="Vui lòng nhập"
              />
            </a-form-item>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="input-block">
        <a-form-item
          label="Dòng máy"
          name="model"
        >
          <a-input
            v-model:value="form.model"
            class="full-width form-control"
            placeholder="Vui lòng nhập"
          />
        </a-form-item>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="input-block">
        <a-form-item
          label="Năm sản xuất"
          name="expYear"
        >
          <a-select
            v-model:value="form.expYear"
            style="width: 100%"
            :options="props.years"
            placeholder="Vui lòng chọn"
          ></a-select>
        </a-form-item>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="input-block">
        <a-form-item
          label="Màu"
          name="color"
        >
          <a-input
            v-model:value="form.color"
            class="full-width form-control"
            placeholder="Vui lòng nhập"
          />
        </a-form-item>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="input-block">
        <a-form-item
          label="RAM"
          name="memory"
        >
          <a-select
            v-model:value="form.memory"
            style="width: 100%"
            :options="MemoryOptions"
            placeholder="Vui lòng chọn"
          ></a-select>
        </a-form-item>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="input-block capacity-input-addon">
        <form-input-number
          v-model="form.capacity"
          label="Dung lượng máy"
          name="capacity"
          :rules="formRules.capacity"
          input-class="form-control"
          addon-after="GB"
          placeholder="Vui lòng nhập"
        ></form-input-number>
      </div>
    </div>
    <div class="col-lg-6 capacity-input-addon">
      <div class="input-block">
        <a-form-item
          label="Dung lượng còn trống"
          name="remainCapacity"
        >
          <a-slider
            v-model:value="form.remainCapacity"
            :tooltip-open="tooltipOpen && form.remainCapacity > 0"
            :tipFormatter="(value) => `${value}%`"
          />
        </a-form-item>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="input-block">
        <a-form-item
          label="Số seri"
          name="serialNo"
        >
          <a-input
            v-model:value="form.serialNo"
            class="full-width form-control"
            placeholder="Vui lòng nhập"
          />
        </a-form-item>
      </div>
    </div>
  </a-form>
</template>
<style scoped>
.capacity-input-addon {
  ::v-deep .ant-input-number-group-wrapper {
    padding: 0 !important;
    border-style: none !important;
  }

  ::v-deep .ant-input-number-group-addon {
    border-style: none !important;
  }
}
</style>
