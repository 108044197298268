<template>
  <div class="main-wrapper login-body">
    <!-- Header -->
    <header class="log-header">
      <router-link to="/"
        ><img
          class="img-fluid logo-dark"
          src="@/assets/img/logo.svg"
          alt="Logo"
      /></router-link>
    </header>
    <!-- /Header -->

    <div class="login-wrapper">
      <div class="loginbox">
        <div class="login-auth">
          <div class="login-auth-wrap">
            <div class="sign-group">
              <router-link to="/" class="btn sign-up"
                ><span
                  ><i class="fe feather-corner-down-left" aria-hidden="true"></i
                ></span>
                Trở về trang chủ</router-link
              >
            </div>
            <h1>Quên mật khẩu</h1>
            <p class="account-subtitle">
              Vui lòng nhập email đăng ký để chúng tôi gửi bạn mã đặt lại mật
              khẩu
            </p>
            <form @submit.prevent="submitForm">
              <div class="input-block">
                <label class="form-label"
                  >Email<span class="text-danger">*</span></label
                >
                <a-input
                  v-model:value="email"
                  class="full-width form-control"
                  type="email"
                />
              </div>
              <button
                to="reset-password"
                :disabled="!email || !isValidEmail(email) || isSubmitted"
                class="btn btn-outline-light w-100 btn-size mt-3"
              >
                Xác nhận
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <auth-footer></auth-footer>
    <!-- /Footer -->
  </div>
</template>

<script setup>
import { isValidEmail } from "@/utils/common";
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
const store = useStore();
const email = ref("");
const isSuccess = computed(() => store.getters["auth/getSuccess"]);
const isSubmitted = ref(false);

watch(isSuccess, (newVal, oldVal) => {
  if (newVal !== undefined) {
    if (newVal === true) {
      message.success("Thành công");
    } else {
      message.error("Có lỗi. Vui lòng thử lại");
      isSubmitted.value = false;
    }
    store.commit("auth/succeed", undefined);
  }
});

const submitForm = () => {
  isSubmitted.value = true;
  store.dispatch("auth/forgotPassword", email.value);
};
</script>
