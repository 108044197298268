<template>
  <div class="review-sec extra-service mb-0">
    <div class="review-header">
      <h4>Mô tả</h4>
    </div>
    <div class="description-list">
      {{ currentItem.description }}
    </div>
  </div>

  <div class="review-sec extra-service mb-0">
    <div class="review-header">
      <h4>Lịch thuê sản phẩm</h4>
    </div>
    <div class="description-list">
      <r-calendar type="small"></r-calendar>
    </div>
  </div>

  <div class="review-sec extra-service mb-0" v-if="currentItem.personalTerm">
    <div class="review-header">
      <h4>Điều khoản riêng</h4>
    </div>
    <div class="description-list">
      {{ currentItem.personalTerm }}
    </div>
  </div>

  <div class="review-sec extra-service mb-0 collateral-section">
    <div class="review-header">
      <h4>Tài sản thế chấp</h4>
    </div>
    <div class="description-list">
      {{ currentItem.collateral }}
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const currentItem = computed(() => store.getters["thing/getCurrentItem"]);
</script>
<style scoped>
@media only screen and (max-width: 992px) {
  .collateral-section {
    margin-bottom: 24px !important;
  }
}
</style>
