import axios from "axios";
import qs from "qs";
import { APIBaseUrl } from "@/constants/api-url";

const instance = axios.create({
  baseURL: `${APIBaseUrl}/api`,
  paramsSerializer: function (params) {
    return qs.stringify(params);
  },
});

instance.interceptors.request.use(
  function (config) {
    const requireAuth =
      config.requireAuth !== undefined ? config.requireAuth : true;
    const token = window.$store.state.auth.token;
    if (requireAuth && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      window.$store.dispatch("auth/logout");
      window.location.href = "/";

      return new Promise(() => {});
    }
    return Promise.reject(error);
  }
);
export default instance;
