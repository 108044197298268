export const PRODUCT_TYPE = {
  PHONE: "phone",
  TABLET: "tablet",
  PC: "pc",
  LIGHTSTICK: "lightstick",
  SHOE: "shoe",
  DIGITALCAMERA: "digitalcamera",
  MEMBERSHIP: "membership",
  SPEAKER: "speaker",

  // OTHER: "other",
};

export const DELIVERY_TYPE = {
  SHIP: "ship",
  AUTOPICK: "autopick",
  BOTH: "both",
};

export const ProductTypeList = [
  { id: 1, label: "Điện thoại", value: PRODUCT_TYPE.PHONE },
  { id: 2, label: "Máy tính", value: PRODUCT_TYPE.PC },
  { id: 3, label: "Máy tính bảng", value: PRODUCT_TYPE.TABLET },
  // { id: 4, label: "Loa", value: PRODUCT_TYPE.SPEAKER },
  { id: 5, label: "Máy ảnh", value: PRODUCT_TYPE.DIGITALCAMERA },
  // { id: 6, label: "Giày", value: PRODUCT_TYPE.SHOE },
  { id: 7, label: "Lightstick", value: PRODUCT_TYPE.LIGHTSTICK },
  { id: 8, label: "Membership", value: PRODUCT_TYPE.MEMBERSHIP },
];

export const AccessoryOptions = {
  [PRODUCT_TYPE.PHONE]: [
    { id: 1, label: "Tai nghe", value: "headPhoneSupport" },
    { id: 2, label: "Củ sạc", value: "chargerSupport" },
    { id: 3, label: "Dây Usb", value: "cableChargerSupport" },
    { id: 4, label: "Ốp lưng", value: "caseSupport" },
    { id: 5, label: "Kính cường lực", value: "screenProtecterSupport" },
  ],
  [PRODUCT_TYPE.DIGITALCAMERA]: [
    { id: 1, label: "Thẻ nhớ", value: "cardSupport" },
    { id: 2, label: "Dây Usb", value: "cableChargerSupport" },
    { id: 3, label: "Túi đựng", value: "pocketSupport" },
  ],
  [PRODUCT_TYPE.LIGHTSTICK]: [
    { id: 1, label: "Bao lightstick", value: "wrapperSupport" },
    { id: 2, label: "Tay cầm", value: "cordSupport" },
    { id: 3, label: "Pin", value: "batterySupport" },
  ],
};

export const MemoryOptions = [
  { id: 1, label: "2GB", value: "gb2" },
  { id: 2, label: "3GB", value: "gb3" },
  { id: 3, label: "4GB", value: "gb4" },
  { id: 4, label: "6GB", value: "gb6" },
  { id: 5, label: "8GB", value: "gb8" },
  { id: 6, label: "12GB", value: "gb12" },
  { id: 7, label: "16GB", value: "gb16" },
  { id: 8, label: "18GB", value: "gb18" },
  { id: 9, label: "24GB", value: "gb24" },
];

export const ShoeTypes = [
  { id: 1, label: "Sneaker", value: "sneaker" },
  { id: 2, label: "Boot", value: "boot" },
  { id: 3, label: "Loafer", value: "loafer" },
  { id: 4, label: "Flipflop", value: "flipflop" },
  { id: 5, label: "Espadrilles", value: "espadrilles" },
  { id: 6, label: "Oxford", value: "oxford" },
  { id: 7, label: "Athletic", value: "athletic" },
  { id: 8, label: "Slippers", value: "slippers" },
  { id: 9, label: "Pump", value: "pump" },
  { id: 10, label: "Clog", value: "clog" },
  { id: 11, label: "Moccasin", value: "moccasin" },
];

export const ShoeStatus = [
  { id: 1, label: "Mới", value: "new" },
  { id: 2, label: "Đã sử dụng", value: "old" },
];

export const LISTING_PARAM_TYPE = {
  "dien-thoai": "phone",
  "may-tinh-bang": "tablet",
  "may-tinh": "pc",
  lightstick: "lightstick",
  // giay: "shoe",
  "may-anh": "digitalcamera",
  membership: "membership",
  // loa: "speaker",
};

export const EMAIL_SUPPORT = "support@thuedocanhan.com";

export const GOOGLE_CLIENT_ID = "573370664868-ma8l94m8n5i7l566tduspa5druia4u3c.apps.googleusercontent.com";

