import axiosInstance from "./axiosConfig";

const locationService = {
  async getLocations(params) {
    return axiosInstance
      .get(`/locations`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error("Fetching locations error: ", error);
      });
  },
};

export default locationService;
