<template>
  <a-modal
    :visible="props.visible"
    :footer="null"
    :closable="false"
    class="text-center"
    style="width: 300px"
  >
    <a-spin
      size="large"
      class="py-3"
    />
  </a-modal>
</template>

<script setup>
const props = defineProps({
  visible: { type: Boolean, required: true },
});
</script>
