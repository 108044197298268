<template>
  <a-modal
    title=""
    v-model:visible="props.visible"
    :footer="null"
    :onCancel="onCancel"
    :maskClosable="!loading"
  >
    <h4>Chỉnh sửa cài đặt giá cơ bản</h4>
    <p class="mt-3 mb-3">Vui lòng nhập giá thuê trung bình cho sản phẩm</p>
    <a-form
      :model="formState"
      name="basic"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
      @finish="onFinish"
    >
      <form-input-number
        v-model="formState.price"
        label="Giá cơ bản"
        name="price"
        :rules="numberRules({ isRequired: true, min: 0, max: 10000 })"
        help="Đơn vị tính là Việt Nam đồng"
        input-class="full-width"
        addon-after="K"
        placeholder="Vui lòng nhập"
      ></form-input-number>

      <a-form-item
        name="action"
        class="mt-3"
      >
        <p class="mb-2">Bạn muốn áp dụng giá cơ bản cho:</p>
        <a-radio-group v-model:value="formState.action">
          <a-radio value="update">
            Áp dụng giá cơ bản mới cho tất cả các ngày trong tương lai.</a-radio
          >
          <a-radio
            value="keep"
            class="mt-1"
            >Chỉ áp dụng giá cơ bản mới cho các ngày (tương lai) chưa chỉnh
            sửa.</a-radio
          >
        </a-radio-group>
      </a-form-item>

      <a-form-item :wrapper-col="{ span: 24 }">
        <div style="display: flex; justify-content: flex-end">
          <a-button @click="onCancel" class="btn-border-default mr-3"
            >Hủy</a-button
          >
          <a-button
            class="btn-default"
            type="primary"
            html-type="submit"
            :loading="loading"
            >Cài đặt</a-button
          >
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import { ref, reactive, watch, computed } from "vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { numberRules } from "@/utils/common";

const props = defineProps({
  visible: { type: Boolean, required: true },
  id: { type: Number, required: true },
});
const emits = defineEmits(["close", "submit"]);
const isSuccess = computed(() => store.getters["thing/getSuccess"]);
const loading = ref(false);

const store = useStore();
const formState = reactive({
  price: "",
  action: "update",
});

const onFinish = (values) => {
  loading.value = true;
  store.dispatch("thing/updatePrice", {
    id: props.id,
    data: { ...values, action: formState.action },
  });
};

watch(isSuccess, (newVal, oldVal) => {
  if (newVal !== undefined) {
    if (newVal === true) {
      message.success("Cập nhật thành công");
      onCancel();
    } else {
      message.error("Có lỗi. Vui lòng thử lại");
    }
    loading.value = false;
    store.commit("thing/succeed", undefined);
  }
});

const onCancel = () => {
  formState.price = "";
  formState.action = "update";
  emits("close");
};
</script>
<style scoped>
::v-deep label.ant-form-item-required {
  font-weight: 600 !important;
  font-size: 16px !important;
}
::v-deep .ant-input-number-input-wrap {
  height: 30px;
  line-height: 30px;
}
</style>
