import axiosInstance from "./axiosConfig";
import axios from "axios";
import { APIBaseUrl } from "@/constants/api-url";
const authService = {
  async getMe() {
    try {
      const response = await axiosInstance.get(`/users/me`, {
        params: { populate: "avatar" },
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },
  async login(identifier, password) {
    try {
      const response = await axiosInstance.post(`/auth/local`, {
        identifier,
        password,
      });
      return response.data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },
  async checkEmailExisted(email) {
    try {
      const response = await axiosInstance.post(
        `/users-permissions/check-email`,
        { email }
      );
      return response.data;
    } catch (error) {
      console.error("Error checking email existence:", error);
      throw error;
    }
  },
  async sendOTP(email) {
    try {
      const response = await axiosInstance.post(`/users-permissions/otp`, {
        email,
      });
      return response.data;
    } catch (error) {
      console.error("Error verifying OTP:", error);
      throw error;
    }
  },
  async register(data) {
    try {
      const response = await axiosInstance.post(`/auth/local/register`, data);
      return response.data;
    } catch (error) {
      console.error("Error registering user:", error);
      throw error;
    }
  },

  async forgotPassword(email) {
    try {
      const response = await axiosInstance.post(`/auth/forgot-password`, {
        email,
      });
      return response.data;
    } catch (error) {
      console.error("Error forgot password:", error);
      throw error;
    }
  },
  async changePassword(data) {
    try {
      const response = await axiosInstance.post(
        `/users-permissions/change-password`,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Error changing password:", error);
      throw error;
    }
  },

  async loginWithGoogle(data) {
    try {
      const response = await axios.post(
        `${APIBaseUrl}/api/users-permissions/google`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        return { isNewUser: true };
      } else throw error;
    }
  },

  async getUserGoogleInfo(accessToen) {
    try {
      return await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: {
          Authorization: `Bearer ${accessToen}`,
        },
      });
    } catch (error) {
      throw error;
    }
  },
};

export default authService;
