<template>
  <div class="col-lg-3 theiaStickySidebar">
    <div class="stickysidebar">
      <div class="settings-widget">
        <div class="settings-menu">
          <ul>
            <li>
              <a class="active" href="javascript:void(0);">
                <i class="feather-user"></i> Thông tin tài khoản
              </a>
            </li>
            <!-- <li>
            <router-link to="/user/user-security">
              <i class="feather-shield"></i> Security
            </router-link>
          </li>
          <li>
            <router-link to="/user/user-preferences">
              <i class="feather-star"></i> Preferences
            </router-link>
          </li>
          <li>
            <router-link to="/user/user-notifications">
              <i class="feather-bell"></i> Notifications
            </router-link>
          </li>
          <li>
            <router-link to="/user/user-integration">
              <i class="feather-git-merge"></i> Integration
            </router-link>
          </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
