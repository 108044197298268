<template>
  <div class="error-page">
    <div class="error-box">
      <img src="@/assets/img/404.png" class="img-fluid" alt="Page not found" />
      <h3>Trang không tìm thấy</h3>
      <p>Chúng tôi không tìm thấy trang mà bạn đang xem.</p>
      <div class="back-button">
        <router-link to="/" class="btn btn-primary"
          >Trở về trang chủ</router-link
        >
      </div>
    </div>
  </div>
</template>
