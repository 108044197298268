<template>
  <div>
    <!-- Banner -->
    <section class="banner-section banner-slider">
      <div class="container">
        <div class="home-banner">
          <div class="row align-items-center">
            <div class="col-lg-6" data-aos="fade-down">
              <p class="explore-text">
                <span><i class="fa-solid fa-thumbs-up me-2"></i></span>100% Ứng
                dụng tìm kiếm và cho thuê đồ
              </p>
              <h1>
                Tìm đồ cần thuê <br />
                <span>Tiết kiệm chi phí</span>
              </h1>
              <p>
                Thuê đồ cá nhân dễ dàng, tiện lợi trực tuyến. Mọi người đều có
                thể tìm thấy những gì cần.
              </p>
              <div class="view-all">
                <a
                  href="#latest"
                  class="btn btn-view d-inline-flex align-items-center"
                  >Xem tất cả sản phẩm
                  <span><i class="feather-arrow-right ms-2"></i></span
                ></a>
              </div>
            </div>
            <div class="col-lg-6" data-aos="fade-down">
              <div class="banner-imgs">
                <img
                  src="@/assets/img/thing-banner.png"
                  class="img-fluid aos"
                  alt="bannerimage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Banner -->

    <!-- Search -->
    <div class="section-search">
      <div class="container">
        <div class="search-box-banner">
          <a-form
            :model="form"
            ref="formRef"
            :rules="formRules"
            name="basic"
            :label-col="{ span: 24 }"
            :wrapper-col="{ span: 24 }"
            autocomplete="off"
          >
            <ul class="align-items-center">
              <li class="column-group-main">
                <div class="input-block">
                  <label>Loại sản phẩm</label>
                  <a-form-item name="type">
                    <a-select
                      v-model:value="form.type"
                      :options="ProductTypeList"
                      :showArrow="true"
                      placeholder="Vui lòng chọn"
                      optionFilterProp="label"
                      class="full-width"
                    ></a-select>
                  </a-form-item>
                </div>
              </li>

              <li class="column-group-main">
                <div class="input-block">
                  <label>Địa điểm</label>
                  <a-form-item name="location">
                    <a-select
                      v-model:value="form.location"
                      placeholder="Vui lòng chọn"
                      :options="locations"
                      :showArrow="true"
                      optionFilterProp="label"
                      class="full-width"
                    ></a-select>
                  </a-form-item>
                </div>
              </li>

              <li class="column-group-main">
                <div class="input-block">
                  <label>Từ ngày - Đến ngày</label>
                  <div class="d-flex gap-2">
                    <a-form-item name="from" class="flex-1">
                      <a-date-picker
                        v-model:value="form.from"
                        class="full-width"
                        :picker="date"
                        :disabledDate="disabledDate"
                        placeholder="Vui lòng nhập"
                        :locale="locale"
                      />
                    </a-form-item>
                    <a-form-item name="to" class="flex-1">
                      <a-date-picker
                        v-model:value="form.to"
                        class="full-width"
                        :picker="date"
                        :disabledDate="disabledDate"
                        placeholder="Vui lòng nhập"
                        :locale="locale"
                      />
                    </a-form-item>
                  </div>
                </div>
              </li>
              <li class="column-group-last mt-1">
                <div class="input-block">
                  <div class="search-btn">
                    <a-form-item>
                      <button
                        class="btn search-button"
                        @click.prevent="onSubmit"
                      >
                        <i class="fa fa-search" aria-hidden="true"></i>Tìm kiếm
                      </button>
                    </a-form-item>
                  </div>
                </div>
              </li>
            </ul>
          </a-form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Search -->
</template>

<script setup>
import { ref, reactive, computed, onMounted, watchEffect, watch } from "vue";
import { useStore } from "vuex";
import { LISTING_PARAM_TYPE, ProductTypeList } from "@/constants";
import dayjs from "dayjs";
import { router } from "@/router";
import locale from "ant-design-vue/es/date-picker/locale/vi_VN";
import "dayjs/locale/vi";
dayjs.locale("vi");

const store = useStore();
const locations = computed(() =>
  store.getters["location/list"].map((item) => {
    return {
      value: item.id,
      label: item.attributes.name,
    };
  })
);

onMounted(() => {
  store.dispatch("location/getList", {
    pagination: {
      pageSize: 100,
    },
    sort: "order",
  });
});

const formRef = ref();
const form = reactive({
  location: null,
  type: "phone",
  from: dayjs(dayjs(), "YYYY-MM-DD"),
  to: dayjs(dayjs(), "YYYY-MM-DD"),
});

const formRules = reactive({
  from: [
    {
      validator: async (_rule, value) => {
        if (form.to) {
          const diff = dayjs(value).diff(dayjs(form.to), "day");
          if (diff > 0)
            return Promise.reject(
              "Vui lòng chọn bằng hoặc trước ngày kết thúc"
            );
        }
      },
      trigger: "change",
    },
  ],
  to: [
    {
      validator: async (_rule, value) => {
        if (form.from) {
          const diff = dayjs(value).diff(dayjs(form.from), "day");
          if (diff < 0)
            return Promise.reject("Vui lòng chọn bằng hoặc sau ngày bắt đầu");
        }
      },
      trigger: "change",
    },
  ],
});

watch(
  () => form.from,
  () => {
    formRef.value.validate();
  }
);

watch(
  () => form.to,
  () => {
    formRef.value.validate();
  }
);

watchEffect(() => {
  if (locations.value && locations.value.length) {
    form.location = locations.value[0].value;
  }
});
const disabledDate = (current) => {
  return current && current < Date.now() - 60 * 60 * 24;
};

const onSubmit = () => {
  formRef.value
    .validate()
    .then(() => {
      store.commit("thing/setFilters", form);
      const type = Object.keys(LISTING_PARAM_TYPE).find(
        (key) => LISTING_PARAM_TYPE[key] === form.type
      );
      router.push(type);
    })
    .catch(() => {
      return;
    });
};
</script>

<style scoped>
::v-deep .ant-picker-clear {
  display: none;
}

::v-deep .ant-form-item-explain-error {
  position: absolute;
}

::v-deep .ant-form-item {
  margin-bottom: 22px;
}
</style>
