<template>
  <template v-if="isLoading">
    <div></div>
  </template>
  <template v-else-if="currentItem !== undefined">
    <layout-header></layout-header>
    <listing-head></listing-head>
    <section class="section product-details">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <listing-carphoto></listing-carphoto>
            <listing-description></listing-description>

            <!--Listing Features Section-->
            <listing-features
              v-if="
                [
                  'phone',
                  'tablet',
                  'pc',
                  'lightstick',
                  'digitalcamera',
                ].includes(currentItem.type)
              "
            ></listing-features>
            <!--/Listing Features Section-->
          </div>
          <listingdetails-sidebar></listingdetails-sidebar>
        </div>
        <listing></listing>
      </div>
    </section>
    <layout-footer></layout-footer>
    <listing-modal></listing-modal>
  </template>
  <template v-else>
    <not-found></not-found>
  </template>
  <create-product-button></create-product-button>
</template>
<script setup>
import { computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { validateUrl } from "../../../../utils/common";
import "../../../../../node_modules/nprogress/nprogress.css";
import NProgress from "nprogress";

const route = useRoute();
const store = useStore();
const isLoading = computed(() => store.getters["thing/getLoading"]);
const currentItem = computed(() => store.getters["thing/getCurrentItem"]);
NProgress.configure({ showSpinner: false });
NProgress.start();

watch(currentItem, (newItem, oldItem) => {
  if (newItem.publishedAt === null) {
    store.commit("thing/notFound");
  }
  document.title = newItem.title;
});

watch(isLoading, (newItem, oldItem) => {
  if (!newItem) {
    NProgress.done();
  }
});

watch(
  () => route.params.title,
  async (newTitle) => {
    const extractId = validateUrl(newTitle);
    if (extractId) {
      store.dispatch("thing/loadCurrentItem", {
        id: extractId,
        params: {
          select: [
            "type",
            "title",
            "description",
            "pastCalendar",
            "price",
            "collateral",
            "brandOtherText",
            "personalTerm",
            "deliveryType",
            "publishedAt",
          ],
          populate: [
            "brand",
            "locations",
            "images",
            "specials",
            "device",
            "lightstick",
            "shoe",
            "digitalcamera",
            "membership",
            "speaker",
            "owner",
            "owner.avatar",
            "extraFees",
          ],
        },
      });
    } else {
      store.commit("thing/notFound");
    }
  },
  {
    immediate: true,
  }
);
</script>
