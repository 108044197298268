<template>
  <div class="card">
    <div class="listing-item pb-0">
      <div class="listing-img">
        <router-link :to="generateUrl(item.id, item.title)">
          <img
            :src="
              item?.images?.length
                ? item.images[0].url
                : `https://ui-avatars.com/api/?name=NA&size=512`
            "
            :class="{
              'img-fluid': true,
              'thing-img': true,
              [props.screen]: true,
            }"
            alt="KIA"
          />
        </router-link>
      </div>
      <div class="listing-content">
        <div class="listing-features">
          <div class="author-img">
            <img
              :src="
                item?.owner?.avatar
                  ? item.owner.avatar.url
                  : `https://ui-avatars.com/api/?name=${item?.owner?.fullName}&size=300`
              "
              alt="author"
            />
          </div>
          <h3 class="listing-title">
            <router-link
              :to="generateUrl(item.id, item.title)"
              class="title-thing"
              >{{ item.title }}</router-link
            >
          </h3>
        </div>
        <div class="listing-details-group d-flex justify-content-between">
          <div class="d-flex gap-2">
            <span>
              <img
                v-if="item.deliveryType !== 'autopick'"
                src="@/assets/img/check.svg"
                alt="check icon"
              />
              <img
                v-else
                src="@/assets/img/close.png"
                alt="close icon"
                style="width: 12px"
              />
            </span>
            <p>Giao tận nơi</p>
          </div>
          <div class="d-flex gap-2">
            <span>
              <img
                v-if="item.deliveryType !== 'ship'"
                src="@/assets/img/check.svg"
                alt="check icon" />
              <img
                v-else
                src="@/assets/img/close.png"
                alt="close icon"
                style="width: 12px"
            /></span>
            <p>Tự đến lấy</p>
          </div>
        </div>
        <div class="listing-location-details">
          <div class="listing-price cut">
            <span class="me-2"><i class="feather-map-pin"></i></span>
            <a-tooltip placement="topLeft">
              <template #title>
                <span>{{
                  item?.locations?.map((location) => location.name).join(", ")
                }}</span>
              </template>
              <span class="default">
                {{
                  item?.locations?.map((location) => location.name).join(", ")
                }}
              </span>
            </a-tooltip>
          </div>
          <div class="listing-price">
            <h6>{{ formatCurrency(item.price) }}K <span>/ Ngày</span></h6>
          </div>
        </div>
        <div class="listing-button">
          <router-link
            :to="generateUrl(item.id, item.title)"
            class="btn btn-order"
            ><span><i class="feather-file-text me-2"></i></span>Xem chi
            tiết</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { formatCurrency, generateUrl } from "../utils/common";

const props = defineProps(["item", "screen"]);
</script>
<style scoped>
.cut {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  text-align: left;
}

.default {
  cursor: default;
}

.thing-img {
  object-fit: cover;
}

.recommend {
  height: 12vw;
}

.latest {
  height: 13vw;
}

.listing {
  height: 14vw;
}

@media only screen and (max-width: 2000px) {
  .latest {
    height: 14vw;
  }
}

@media only screen and (max-width: 1950px) {
  .recommend {
    height: 14vw;
  }
}

@media only screen and (max-width: 1900px) {
  .listing {
    height: 16vw;
  }
}

@media only screen and (max-width: 1800px) {
  .latest {
    height: 15vw;
  }
}

@media only screen and (max-width: 1700px) {
  .recommend {
    height: 16vw;
  }
  .listing {
    height: 19vw;
  }
}

@media only screen and (max-width: 1600px) {
  .latest {
    height: 16vw;
  }
}

@media only screen and (max-width: 1400px) {
  .listing {
    height: 18vw;
  }
}

@media only screen and (max-width: 992px) {
  .recommend {
    height: 50vw;
  }

  .listing {
    height: 26vw;
  }

  .latest {
    height: 26vw;
  }
}

@media only screen and (max-width: 768px) {
  .listing {
    height: 50vw;
  }
}

@media only screen and (max-width: 767px) {
  .latest {
    height: 50vw;
  }
}

.title-thing {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  height: 3em;
}
</style>
