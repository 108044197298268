<template>
  <layout-header></layout-header>
  <breadcrumb
    :title="title"
    :text="title"
  />
  <template v-if="!type">
    <not-found></not-found>
  </template>
  <section v-else class="section car-listing">
    <div class="container">
      <div class="row">
        <listing-sidebar :type="type" @on-submit="onQuery"></listing-sidebar>
        <div class="col-lg-9">
          <no-result v-if="total === 0" text="Không thấy kết quả"></no-result>

          <div class="row">
            <div
              class="col-xl-6 col-lg-6 col-md-6 col-12"
              v-for="item in list"
              :key="item.id"
            >
              <thing-card :item="item" screen="listing"></thing-card>
            </div>
          </div>
          <!--Pagination-->
          <div class="blog-pagination" v-if="pagination.total > 0">
            <nav>
              <ul class="pagination page-item justify-content-center">
                <a-pagination
                  v-model:current="pagination.current"
                  :pageSize="pagination.pageSize"
                  :total="pagination.total"
                />
              </ul>
            </nav>
          </div>
          <!--/Pagination-->
        </div>
      </div>
    </div>
  </section>
  <layout-footer></layout-footer>
  <create-product-button></create-product-button>
</template>
<script setup>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { LISTING_PARAM_TYPE, ProductTypeList } from "@/constants";
import { ref, computed, watchEffect, watch, onMounted } from "vue";
import isEmpty from "lodash.isempty";

const store = useStore();
const route = useRoute();
const pagination = ref({
  current: 1,
  pageSize: 10,
  total: 0,
});
const type = ref(null);
const title = ref("");
watchEffect(() => {
  type.value = LISTING_PARAM_TYPE[route.params.type];
  title.value = ProductTypeList.find((item) => item.value === type.value).label;
  document.title = title.value;
});

const filterParams = ref({});
const list = computed(() => store.getters["thing/list"]);
const total = computed(() => store.getters["thing/total"]);

const getListProducts = (params = {}) => {
  store.dispatch("thing/getList", {
    filters: { ...params, type: type.value },
    sort: {
      createdAt: "DESC",
    },
    perPage: pagination.value.pageSize,
    page: pagination.value.current,
    populate: ["images", "locations", "owner", "owner.avatar"],
    select: ["title", "price", "deliveryType"],
  });
  window.scrollTo({ top: 0, behavior: "smooth" });
};

watch(total, (newVal, oldVal) => {
  pagination.value.total = newVal;
});

onMounted(() => {
  if (isEmpty(filterParams.value)) {
    getListProducts();
  }
});

const onQuery = (params) => {
  pagination.value.current = 1;
  filterParams.value = params;
  getListProducts(filterParams.value);
};
</script>
