import thingService from "@/services/ThingService";
import { shuffle } from "@/utils/common";
import isEmpty from "lodash.isempty";

const state = {
  currentItem: undefined,
  isLoading: true,
  list: [],
  isSuccess: undefined,
  total: 0,
  recommend: [],
  filters: undefined,
  isLatestLoading: true,
  latest: [],
  cacheLatest: {},
  totalByType: {},
  isLoadingList: undefined,
  currentDateView: undefined,
};

const getters = {
  getLoading: (state) => state.isLoading,
  getCurrentItem: (state) => state.currentItem,
  list: (state) => state.list,
  getSuccess: (state) => state.isSuccess,
  total: (state) => state.total,
  recommend: (state) => state.recommend,
  filters: (state) => state.filters,
  isLatestLoading: (state) => state.isLatestLoading,
  latest: (state) => state.latest,
  totalByType: (state) => state.totalByType,
  isListLoading: (state) => state.isLoadingList,
  currentDateView: (state) => state.currentDateView,
};

const actions = {
  async loadCurrentItem({ commit }, { id, params = {}, showLoading = true }) {
    if (showLoading) {
      commit("loading");
    }
    const res = await thingService.getThingById(id, params);
    commit("done", res);
  },
  async create({ commit }, data) {
    commit("loading");
    const res = await thingService.create({ data });
    commit("done", res);
  },
  async update({ commit }, { id, data }) {
    await thingService.update(id, { data });
  },

  async updateImages({ state }, { id, removeIds, images }) {
    await thingService.updateImages({
      id,
      removeIds,
      images,
    });
  },

  async getList({ commit, state }, params) {
    commit("loadingList", true);
    const res = await thingService.getThings(params);
    commit("getListDone", res);
    return res;
  },
  async updatePrice({ commit, dispatch }, { id, data, getSuccess = true }) {
    try {
      await thingService.updatePrice(id, data);
      dispatch("loadCurrentItem", {
        id,
        params: {
          populate: ["specials"],
          select: ["title", "price", "pastCalendar", "publishedAt"],
        },
        showLoading: false,
      });
      if (getSuccess) commit("succeed", true);
    } catch (e) {
      if (getSuccess) commit("succeed", false);
    }
  },
  async updateCalendar({ commit, dispatch }, { id, data, getSuccess = true }) {
    try {
      await thingService.updateCalendar(id, data);
      dispatch("loadCurrentItem", {
        id,
        params: {
          populate: ["specials"],
          select: ["title", "price", "pastCalendar", "publishedAt"],
        },
        showLoading: false,
      });
      if (getSuccess) commit("succeed", true);
    } catch (e) {
      if (getSuccess) commit("succeed", false);
    }
  },

  async createThenUpdateImages(
    { commit, state, dispatch },
    { createData, imagesData }
  ) {
    try {
      await dispatch("create", createData);
      await dispatch("updateImages", {
        ...imagesData,
        id: state.currentItem.id,
      });
      commit("succeed", true);
    } catch (e) {
      commit("succeed", false);
    }
  },

  async updateThenUpdateImages(
    { commit, dispatch },
    { id, updateData, imagesData }
  ) {
    try {
      if (!isEmpty(updateData))
        await dispatch("update", { id, data: updateData });
      if (imagesData.removeIds.length > 0 || imagesData.images.length > 0)
        await dispatch("updateImages", { ...imagesData, id });
      dispatch("loadCurrentItem", {
        id,
        params: {
          populate: [
            "images",
            "brand",
            "extraFees",
            "locations",
            "device",
            "digitalcamera",
            "shoe",
            "lightstick",
            "membership",
            "speaker",
          ],
          select: [
            "type",
            "deliveryType",
            "type",
            "description",
            "collateral",
            "personalTerm",
            "brandOtherText",
          ],
        },
      });
      commit("succeed", true);
    } catch (e) {
      commit("succeed", false);
    }
  },

  async updatePriceThenUpdateCalendar(
    { commit, dispatch },
    { id, priceData, calendarData, isPublished = null }
  ) {
    try {
      await dispatch("updatePrice", { id, data: priceData, getSuccess: false });
      if (calendarData)
        await dispatch("updateCalendar", {
          id,
          data: calendarData,
          getSuccess: false,
        });
      if (isPublished !== null) {
        await dispatch("update", {
          id,
          data: { publishedAt: isPublished ? new Date().toISOString() : null },
        });

        dispatch("loadCurrentItem", {
          id,
          params: {
            populate: ["specials", "owner"],
            select: ["title", "price", "pastCalendar", "publishedAt"],
          },
        });
      }
      commit("succeed", true);
    } catch (e) {
      commit("succeed", false);
    }
  },
  async getRecommend({ commit, state }, { id, type, brand }) {
    const sameBrand = brand
      ? await thingService.getThings({
          filters: {
            id: {
              $ne: id,
            },
            brand,
          },
          sort: { createdAt: "DESC" },
          perPage: 10,
          populate: ["images", "locations", "owner", "owner.avatar"],
          select: ["title", "price", "deliveryType"],
        })
      : undefined;
    const recommend = shuffle(sameBrand?.data || []);
    if (recommend.length < 10) {
      const anotherBrand = await thingService.getThings({
        filters: {
          id: {
            $ne: id,
          },
          ...(brand
            ? {
                $or: [
                  {
                    brand: {
                      id: {
                        $null: true,
                      },
                    },
                  },
                  {
                    brand: {
                      id: {
                        $ne: brand,
                      },
                    },
                  },
                ],
              }
            : {}),
          type,
        },
        sort: { createdAt: "DESC" },
        perPage: 10 - recommend.length,
        populate: ["images", "locations", "owner", "owner.avatar"],
        select: ["title", "price", "deliveryType"],
      });
      state.recommend = [...recommend, ...shuffle(anotherBrand.data)];
    } else {
      state.recommend = recommend;
    }
  },
  async getLatest({ commit, state }, { type, clearCache }) {
    if (clearCache) {
      commit("clearCacheLatest");
    }
    commit("loadingLatest");
    const cache = state.cacheLatest[type || "all"];
    if (cache) {
      commit("doneLatest", {
        list: cache,
        key: type || "all",
      });
    } else {
      const res = await thingService.getThings({
        ...(type
          ? {
              filters: {
                type,
              },
            }
          : {}),
        sort: { createdAt: "DESC" },
        perPage: 6,
        populate: ["images", "locations", "owner", "owner.avatar"],
        select: ["title", "price", "deliveryType"],
      });

      commit("doneLatest", {
        list: res.data,
        key: type || "all",
      });
    }
  },
  async getTotalByType({ commit, state }) {
    const res = await thingService.getThingStat();
    commit("doneLoadTotalByType", res);
  },
  async getHomeRecommend({ commit, state }) {
    const res = await thingService.getThings({
      filters: {
        order: {
          $gt: 0,
        },
      },
      sort: { order: "ASC" },
      perPage: 100,
      populate: ["images", "locations", "owner", "owner.avatar"],
      select: ["title", "price", "deliveryType"],
    });
    state.recommend = res.data;
  },
};

const mutations = {
  notFound(state) {
    state.isLoading = false;
    state.currentItem = undefined;
  },
  loading(state) {
    state.isLoading = true;
  },
  done(state, action) {
    state.isLoading = false;
    state.currentItem = action;
  },
  succeed(state, action) {
    state.isSuccess = action;
  },
  setFilters(state, action) {
    state.filters = action;
  },
  loadingList(state, action) {
    state.isLoadingList = action;
  },
  getListDone(state, action) {
    state.isLoadingList = false;
    state.list = action.data;
    state.total = action.total;
  },
  loadingLatest(state) {
    state.isLatestLoading = true;
  },
  doneLatest(state, action) {
    state.isLatestLoading = false;
    state.latest = action.list;
    state.cacheLatest = {
      ...state.cacheLatest,
      [action.key]: action.list,
    };
  },
  clearCacheLatest(state) {
    state.cacheLatest = {};
  },
  doneLoadTotalByType(state, action) {
    state.totalByType = action;
  },
  setCurrentDateView(state, date) {
    state.currentDateView = date;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
