<template>
  <!-- Rental deals -->
  <section class="section popular-services">
    <div class="container">
      <!-- Heading title-->
      <div class="section-heading" data-aos="fade-down">
        <h2>Tiêu biểu</h2>
        <p>Hãy xem qua những sản phẩm tiêu biểu nhé</p>
      </div>
      <!-- /Heading title -->
      <div class="row">
        <div class="popular-slider-group">
          <div class="owl-carousel rental-deal-slider owl-theme">
            <!-- owl carousel item -->
            <Carousel
              :wrap-around="true"
              :settings="settings"
              :breakpoints="breakpoints"
            >
              <Slide v-for="item in recommend" :key="item.id">
                <thing-card :item="item" screen="recommend"></thing-card>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
            <!-- /owl carousel item -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Rental deals -->
</template>
<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ProductTypeList } from "@/constants";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

const settings = computed(() => ({
  itemsToShow: 1,
  snapAlign: "center",
}));

const breakpoints = computed(() => ({
  575: {
    itemsToShow: 1,
    snapAlign: "center",
  },
  767: {
    itemsToShow: 1,
    snapAlign: "center",
  },
  991: {
    itemsToShow: 3,
    snapAlign: "center",
  },
  1024: {
    itemsToShow: 3,
    snapAlign: "start",
  },
}));
const store = useStore();

const recommend = computed(() => store.getters["thing/recommend"]);

store.dispatch("thing/getHomeRecommend");
</script>
<style scoped>
.owl-carousel li {
  padding: 0 12px;
}
</style>
