import brandService from "@/services/BrandService";

const state = {
  list: [],
  cache: {},
};

const getters = {
  list: (state) => state.list,
  cache: (state) => state.cache,
};

const actions = {
  async getList({ commit, state }, data) {
    const type = data.filters.type || "all";
    if (state.cache[type]) commit("getListDone", state.cache[type]);
    else {
      const res = await brandService.getBrands(data);
      commit("getListDone", res.data);
      commit("setCache", {
        ...state.cache,
        [type]: res.data,
      });
    }
  },
};

const mutations = {
  getListDone(state, action) {
    state.list = action;
  },
  setCache(state, action) {
    state.cache = action;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
