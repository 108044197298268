<template>
  <section class="product-detail-head">
    <div class="container">
      <div class="detail-page-head">
        <div class="detail-headings">
          <div class="star-rated">
            <div class="camaro-info">
              <h3>{{ currentItem.title }}</h3>
              <div class="camaro-location">
                <div class="camaro-location-inner">
                  <i class="feather-map-pin me-2"></i>

                  <span class="me-2">
                    <b>Địa điểm :</b>
                    {{
                      currentItem.locations
                        .map((location) => location.name)
                        .join(", ")
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const currentItem = computed(() => store.getters["thing/getCurrentItem"]);
</script>
