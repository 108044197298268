import axiosInstance from "./axiosConfig";
import FormData from "form-data";

const thingService = {
  async getThingById(id, params) {
    try {
      const response = await axiosInstance.get(`/things/${id}`, {
        params,
      });
      return response.data;
    } catch (error) {
      return undefined;
    }
  },
  async create(data) {
    try {
      const response = await axiosInstance.post(`/things`, data);

      return response.data;
    } catch (error) {
      console.error("Error creating thing:", error);
      throw error;
    }
  },
  async update(id, data) {
    try {
      const response = await axiosInstance.put(`/things/${id}`, data);
      return response.data;
    } catch (error) {
      console.error("Error updating thing:", error);
      throw error;
    }
  },
  async updateImages({ id, removeIds, images }) {
    try {
      const formData = new FormData();
      formData.append("removeIds", JSON.stringify(removeIds));
      images.forEach((image, index) => {
        formData.append("images", image, image.name);
      });
      const response = await axiosInstance.post(
        `/things/${id}/images`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating images:", error);
      throw error;
    }
  },

  async getThings(data) {
    try {
      const { requireAuth, ...params } = data;
      const response = await axiosInstance.get(`/things`, {
        params,
        requireAuth,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching products:", error);
      throw error;
    }
  },

  async updatePrice(id, data) {
    try {
      const response = await axiosInstance.put(`/things/${id}/price`, data);
      return response.data;
    } catch (error) {
      console.error("Error updating price:", error);
      throw error;
    }
  },

  async updateCalendar(id, data) {
    try {
      const response = await axiosInstance.post(`/things/${id}/calendar`, data);
      return response.data;
    } catch (error) {
      console.error("Error updating price:", error);
      throw error;
    }
  },

  async getThingStat() {
    try {
      const response = await axiosInstance.get("/things/stat");
      return response.data;
    } catch (error) {
      console.error("Error fetching stat:", error);
      throw error;
    }
  },
};

export default thingService;
