<template>
  <div
    :class="{
      main: true,
      'main-hover': !hide && props.type === 'large',
      'main-disabled': hide,
      'main-trans': props.type === 'large',
      'bg-light-blue': info.type === 'booked' && !hide,
    }"
    @click="
      ((!isMobile && info.type === 'booked') || info.type !== 'booked') &&
      props.type === 'large'
        ? onClick()
        : undefined
    "
  >
    <div
      :class="{
        head: true,
        'head-large': props.type === 'large',
        'head-small': props.type === 'small',
      }"
    >
      <div
        :class="{
          day: true,
          'day-large': props.type === 'large',
          'day-small': props.type === 'small',
          past: hide,
        }"
      >
        {{ props.day.toString().padStart(2, "0") }}
      </div>
      <div
        :class="{
          'status-container': true,
          hidden: hide,
        }"
      >
        <div
          :class="{
            circle: true,
            'off-circle': info.type === 'off',
            'circle-large': props.type === 'large',
            'circle-small': props.type === 'small',
            'booked-circle': info.type === 'booked',
            'on-circle': info.type === 'on',
          }"
        />
        <a-dropdown :trigger="['click']" :disabled="props.type === 'small'">
          <div
            @click.stop
            :class="{
              status: true,
              'status-large': props.type === 'large',
              'status-small': props.type === 'small',
            }"
          >
            <div class="status-label">
              <span v-if="info.type === 'off'" class="label-off"> Tắt</span>
              <span v-if="info.type === 'on'" class="label-on"> Mở</span>
              <span v-if="info.type === 'booked'" class="label-booked">
                Thuê</span
              >
            </div>
            <svg
              v-if="props.type === 'large'"
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.35491 4.97807C9.30843 4.92808 9.25313 4.88841 9.1922 4.86133C9.13127 4.83425 9.06591 4.82031 8.99991 4.82031C8.9339 4.82031 8.86855 4.83425 8.80762 4.86133C8.74669 4.88841 8.69139 4.92808 8.64491 4.97807L6.35491 7.42073C6.30843 7.47072 6.25313 7.5104 6.1922 7.53747C6.13127 7.56455 6.06591 7.57849 5.99991 7.57849C5.9339 7.57849 5.86855 7.56455 5.80762 7.53747C5.74669 7.5104 5.69139 7.47072 5.64491 7.42073L3.35491 4.97807C3.30843 4.92808 3.25313 4.88841 3.1922 4.86133C3.13127 4.83425 3.06591 4.82031 2.99991 4.82031C2.9339 4.82031 2.86855 4.83425 2.80762 4.86133C2.74669 4.88841 2.69139 4.92808 2.64491 4.97807C2.55178 5.078 2.49951 5.21317 2.49951 5.35407C2.49951 5.49497 2.55178 5.63014 2.64491 5.73007L4.93991 8.17807C5.22116 8.47769 5.60241 8.64599 5.99991 8.64599C6.39741 8.64599 6.77866 8.47769 7.05991 8.17807L9.35491 5.73007C9.44803 5.63014 9.50031 5.49497 9.50031 5.35407C9.50031 5.21317 9.44803 5.078 9.35491 4.97807Z"
                fill="#C3C4C8"
              />
            </svg>
          </div>
          <template #overlay>
            <a-menu>
              <a-menu-item
                key="0"
                :disabled="info.type === 'on'"
                @click.stop="onUpdateType('on')"
              >
                <div class="option">
                  <div class="circle on-circle" />
                  <div>Mở</div>
                </div>
              </a-menu-item>
              <a-menu-item
                key="1"
                :disabled="info.type === 'off'"
                @click.stop="onUpdateType('off')"
              >
                <div class="option">
                  <div class="circle off-circle" />
                  <div>Tắt</div>
                </div>
              </a-menu-item>
              <a-menu-item
                key="2"
                :disabled="info.type === 'booked'"
                @click.stop="onUpdateType('booked')"
              >
                <div class="option">
                  <div class="circle booked-circle" />
                  <div>Thuê</div>
                </div>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
    <div
      :class="{
        price: true,
        'price-large': props.type === 'large',
        'price-small': props.type === 'small',
        center: true,
        past: hide,
      }"
      :style="`${info.type === 'off' ? 'opacity:30%' : ''}`"
    >
      <div v-if="info.price">{{ formatCurrency(info.price) }}K</div>
    </div>
    <div>
      <a-popover v-if="!isUpdating">
        <template #content>
          <div class="d-flex align-items-start gap-1">
            <img
              src="@/assets/img/info.svg"
              alt="Icon"
            />
            <div>
              <h6>Ghi chú</h6>
              <p>Người thuê: {{ info?.nameInfo }}</p>
              <p>Điện thoại: {{ info?.phoneInfo }}</p>
              <p>Ghi chú: {{ info?.noteInfo }}</p>
              <a-button
                class="mt-2"
                v-if="isMobile && !hide"
                @click="onClick"
                >Sửa</a-button
              >
            </div>
          </div>
        </template>

        <div
          v-if="info.type === 'booked'"
          :class="{
            center: true,
            booked: true,
            'booked-large': props.type === 'large',
            hand: hide,
            past: hide,
            'booked-small': props.type === 'small',
          }"
        >
          <div
            :class="{
              info: true,
              'info-large': props.type === 'large',
              'info-small': props.type === 'small',
            }"
          >
            Thông tin thuê
          </div>
          <svg
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_369_6285)">
              <path
                d="M10 0.5H2C1.46957 0.5 0.960859 0.710714 0.585786 1.08579C0.210714 1.46086 0 1.96957 0 2.5L0 8.5C0 9.03043 0.210714 9.53914 0.585786 9.91421C0.960859 10.2893 1.46957 10.5 2 10.5H3.45L5.6755 12.3815C5.76578 12.4579 5.88023 12.4998 5.9985 12.4998C6.11677 12.4998 6.23122 12.4579 6.3215 12.3815L8.55 10.5H10C10.5304 10.5 11.0391 10.2893 11.4142 9.91421C11.7893 9.53914 12 9.03043 12 8.5V2.5C12 1.96957 11.7893 1.46086 11.4142 1.08579C11.0391 0.710714 10.5304 0.5 10 0.5V0.5ZM11 8.5C11 8.76522 10.8946 9.01957 10.7071 9.20711C10.5196 9.39464 10.2652 9.5 10 9.5H8.55C8.31365 9.50007 8.08496 9.58386 7.9045 9.7365L6 11.345L4.0965 9.7365C3.91578 9.58364 3.6867 9.49984 3.45 9.5H2C1.73478 9.5 1.48043 9.39464 1.29289 9.20711C1.10536 9.01957 1 8.76522 1 8.5V2.5C1 2.23478 1.10536 1.98043 1.29289 1.79289C1.48043 1.60536 1.73478 1.5 2 1.5H10C10.2652 1.5 10.5196 1.60536 10.7071 1.79289C10.8946 1.98043 11 2.23478 11 2.5V8.5Z"
                fill="#0591C0"
              />
              <path
                d="M3.5 4H6C6.13261 4 6.25979 3.94732 6.35355 3.85355C6.44732 3.75978 6.5 3.63261 6.5 3.5C6.5 3.36739 6.44732 3.24021 6.35355 3.14645C6.25979 3.05268 6.13261 3 6 3H3.5C3.36739 3 3.24021 3.05268 3.14645 3.14645C3.05268 3.24021 3 3.36739 3 3.5C3 3.63261 3.05268 3.75978 3.14645 3.85355C3.24021 3.94732 3.36739 4 3.5 4Z"
                fill="#0591C0"
              />
              <path
                d="M8.5 5H3.5C3.36739 5 3.24021 5.05268 3.14645 5.14645C3.05268 5.24021 3 5.36739 3 5.5C3 5.63261 3.05268 5.75978 3.14645 5.85355C3.24021 5.94732 3.36739 6 3.5 6H8.5C8.63261 6 8.75979 5.94732 8.85355 5.85355C8.94732 5.75978 9 5.63261 9 5.5C9 5.36739 8.94732 5.24021 8.85355 5.14645C8.75979 5.05268 8.63261 5 8.5 5Z"
                fill="#0591C0"
              />
              <path
                d="M8.5 7H3.5C3.36739 7 3.24021 7.05268 3.14645 7.14645C3.05268 7.24022 3 7.36739 3 7.5C3 7.63261 3.05268 7.75979 3.14645 7.85356C3.24021 7.94733 3.36739 8.00001 3.5 8.00001H8.5C8.63261 8.00001 8.75979 7.94733 8.85355 7.85356C8.94732 7.75979 9 7.63261 9 7.5C9 7.36739 8.94732 7.24022 8.85355 7.14645C8.75979 7.05268 8.63261 7 8.5 7Z"
                fill="#0591C0"
              />
            </g>
            <defs>
              <clipPath id="clip0_369_6285">
                <rect
                  width="12"
                  height="12"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </a-popover>
    </div>
  </div>
  <update-day-status-modal
    v-if="isUpdating"
    :visible="isUpdating"
    :date="date"
    :info="updatingInfo"
    :id="+id"
    @close="isUpdating = false"
  />
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import {
  isCalendarDisabledDate,
  getDate,
  dayOfWeekSupported,
  formatDate,
  formatCurrency,
} from "../utils/common";
import { useStore } from "vuex";
import pick from "lodash.pick";
import { message } from "ant-design-vue";

const props = defineProps(["day", "month", "year", "type", "info"]);

const store = useStore();
const currentItem = computed(() => store.getters["thing/getCurrentItem"]);
const isSuccess = computed(() => store.getters["thing/getSuccess"]);
const isUpdating = ref(false);
const updatingInfo = ref({});

const { id, price, pastCalendar, specials } = currentItem.value;

const date = computed(() => getDate(+props.day, +props.month, +props.year));
const hide = computed(() => isCalendarDisabledDate(date.value));

const info = ref({});

const isDispatching = ref(false);
const isMobile = ref(false);
onMounted(() => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    isMobile.value = true;
  }
});

function isDateInSchedule(date, schedule) {
  const fromDate = new Date(schedule.from).setHours(0, 0, 0, 0);
  const toDate = new Date(schedule.to).setHours(0, 0, 0, 0);
  if (date >= fromDate && date <= toDate) {
    const dayOfWeek = date.getDay(); // [0,1,2,3,4,5,6]
    return schedule[dayOfWeekSupported[dayOfWeek]];
  } else {
    return false;
  }
}

info.value = { type: "on", price: hide.value ? null : price };
const source = hide.value ? pastCalendar : specials;
(source || []).forEach((item) => {
  if (isDateInSchedule(date.value, item)) {
    const { type } = item;
    info.value = {
      ...info.value,
      ...pick(item, ["type", "price", "nameInfo", "noteInfo", "phoneInfo"]),
    };
    if (type === "off" && !hide.value) {
      info.value.price = price;
    }
    if (type === "on" && hide.value) {
      info.value.price = null;
    }
    return;
  }
});

const onClick = () => {
  if (!hide.value) {
    updatingInfo.value = { ...info.value };
    isUpdating.value = true;
  }
};

const onUpdateType = (type) => {
  if (type === "booked") {
    updatingInfo.value = { type, noteInfo: "" };
    isUpdating.value = true;
  } else {
    isDispatching.value = true;
    store.dispatch("thing/updateCalendar", {
      id,
      data: {
        type,
        from: formatDate(date.value),
        to: formatDate(date.value),
        dayOfWeeks: [date.value.getDay()],
      },
    });
  }
};

watch(isSuccess, (newVal, oldVal) => {
  if (newVal !== undefined && isDispatching.value) {
    if (newVal === true) {
      message.success("Cập nhật thành công");
      store.commit("thing/setCurrentDateView", new Date(date.value));
    } else {
      message.error("Có lỗi. Vui lòng thử lại");
    }
    isDispatching.value = false;
    store.commit("thing/succeed", undefined);
  }
});
</script>
<style scoped>
.head {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}

.status-container {
  display: flex;
  align-items: center;
}

.booked-small {
  display: none !important;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}

.off-circle {
  background-color: #c8cec4;
}

.on-circle {
  background-color: #de6b00;
}

.booked-circle {
  background-color: #0591c0;
}

.status {
  display: flex;
  align-items: center;
  margin-left: 5px;

  & svg {
    margin-left: 3px;
  }
}

.status-large {
  cursor: pointer;
}

.status-label {
  font-size: 12px;
  .label-on {
    color: #de6b00;
  }
  .label-off {
    color: #c8cec4;
  }

  .label-booked {
    color: #0591c0;
  }
}

.main {
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div {
    flex: 1;
  }
}

.main-trans {
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.main-hover:hover {
  background-color: #ededed;
}

.main-hover.bg-light-blue:hover {
  background-color: rgb(240, 248, 255, 0.3) !important;
}

.price {
  font-size: 16px;
  color: #575965;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.booked {
  padding: 0 10px;
}

.info {
  flex: 1;
  text-align: center;
  color: #0591c0;
  font-size: 10px;
}

.option {
  padding-right: 20px;
  display: flex;
  align-items: center;

  & > div:last-child {
    margin-left: 6px;
  }
}

.day {
  font-size: 15px;
  color: #575965;
}

.main-disabled {
  background-color: #ebebe7;
  transition: none;
  cursor: not-allowed;
}

.hand {
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

.past {
  opacity: 0.5;
}

@media only screen and (max-width: 760px) {
  .status-large,
  .info-large {
    display: none;
  }

  .day-large,
  .price-large {
    font-size: 14px;
  }

  .head-large {
    padding: 0 8px;
  }

  .circle-large {
    width: 9px;
    height: 9px;
  }
}

@media only screen and (max-width: 600px) {
  .day-large,
  .price-large {
    font-size: 13px;
  }

  .head-large {
    padding: 0 6px;
  }

  .circle-large {
    width: 8px;
    height: 8px;
  }
}

@media only screen and (max-width: 500px) {
  .day-large,
  .price-large {
    font-size: 12px;
  }

  .head-large {
    padding: 0 4px;
  }

  .booked-large svg {
    width: 10px;
    height: 10px;
  }

  .circle-large {
    width: 7px;
    height: 7px;
  }
}

@media only screen and (max-width: 1400px) {
  .head-small {
    padding: 0 8px;
  }
  .day-small,
  .price-small {
    font-size: 14px;
  }
  .circle-small {
    width: 9px;
    height: 9px;
  }
}

@media only screen and (max-width: 1200px) {
  .head-small {
    padding: 0 6px;
  }
  .day-small,
  .price-small {
    font-size: 13px;
  }
  .circle-small {
    width: 8px;
    height: 8px;
  }
}

@media only screen and (max-width: 992px) {
  .head-small {
    padding: 0 10px;
  }
  .day-small,
  .price-small {
    font-size: 15px;
  }
  .circle-small {
    width: 10px;
    height: 10px;
  }
}

@media only screen and (max-width: 670px) {
  .status-small {
    display: none;
  }

  .head-small {
    padding: 0 4px;
  }
  .day-small,
  .price-small {
    font-size: 12px;
  }
}

@media only screen and (max-width: 500px) {
  .circle-small {
    width: 7px;
    height: 7px;
  }
}
</style>
