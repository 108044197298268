<template>
  <div class="detail-product">
    <Carousel
      class="slider detail-bigimg big"
      id="gallery"
      :items-to-show="1"
      :wrap-around="false"
      :mouse-drag="false"
      :touch-drag="false"
      v-model="bigSlide"
      @slide-start="handleSlideStart"
    >
      <Slide
        v-for="(image, index) in bigImages"
        :key="index"
        class="product-img"
      >
        <div class="carousel__item image-container">
          <a-image
            :src="image"
            alt="image"
            class="big-img"
          />
        </div>
      </Slide>
      <template #addons>
        <Navigation />
      </template>
    </Carousel>

    <Carousel
      id="thumbnails"
      :items-to-show="4"
      :wrap-around="true"
      snap-align="start"
      :mouse-drag="bigImages.length > 4"
      :touch-drag="bigImages.length > 4"
      v-model="smallSlide"
      ref="carousel"
    >
      <Slide v-for="(image, index) in smallImages" :key="index">
        <div
          :class="{
            carousel__item: true,
            slider: true,
            'slider-nav-thumbnails': true,
            hidden: image === '',
            pointer: image !== '',
          }"
          @click="slideTo(index)"
        >
          <img :src="image" alt="image" class="small-img" />
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { Carousel, Slide, Navigation } from "vue3-carousel";

const store = useStore();
const currentItem = computed(() => store.getters["thing/getCurrentItem"]);

const bigSlide = ref(0);
const smallSlide = ref(0);

const slideTo = (val) => {
  bigSlide.value = val;
};

const handleSlideStart = (data) => {
  if (smallImages.value.length > 4) {
    smallSlide.value = data.slidingToIndex;
  }
};

const bigImages = computed(() =>
  (currentItem.value.images || []).map((image) => image.url)
);

const smallImages = computed(() => {
  const extractImages = (currentItem.value.images || []).map(
    (image) => image.url
  );
  return [
    ...extractImages,
    ...(extractImages.length < 4
      ? [...Array(4 - extractImages.length).keys()].map((item) => "")
      : []),
  ];
});
</script>
<style scoped>
.image-container {
  width: 100%;
}

::v-deep .image-container img {
  width: 100%;
  height: 24vw;
  object-fit: cover;
  border-radius: 10px;
}

::v-deep .ant-image {
  width: 100%;
  border-radius: 10px;
}

::v-deep .ant-image-mask {
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
  width: 100%;
}

.big {
  margin: 0 10px 15px;
}

.big-img {
  border-radius: 10px;
}

.small-img {
  height: 6vw;
  object-fit: cover;
}

@media only screen and (max-width: 2000px) {
  ::v-deep .image-container img {
    height: 27vw;
  }
}

@media only screen and (max-width: 1900px) {
  .small-img {
    height: 7vw;
  }
}

@media only screen and (max-width: 1800px) {
  ::v-deep .image-container img {
    height: 30vw;
  }
}

@media only screen and (max-width: 1600px) {
  ::v-deep .image-container img {
    height: 32vw;
  }

  .small-img {
    height: 8vw;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1301px) {
  .small-img {
    height: 7vw;
  }
}

@media only screen and (max-width: 1200px) {
  .small-img {
    height: 9vw;
  }
}

@media only screen and (max-width: 992px) {
  .small-img {
    height: 13vw;
  }

  ::v-deep .image-container img {
    height: 50vw;
  }
}
</style>
