import userService from "@/services/UserService";
import isEmpty from "lodash.isempty";

const state = {
  isLoading: undefined,
  isSuccess: undefined,
  user: undefined,
};

const getters = {
  getLoading: (state) => state.isLoading,
  getSuccess: (state) => state.isSuccess,
  getUser: (state) => state.user,
};

const actions = {
  async update({ commit, dispatch }, { id, data }) {
    await userService.update(id, data);
    dispatch("auth/getMe", null, { root: true });
  },

  async updateAvatar({ commit, dispatch }, file) {
    await userService.updateAvatar(file);
    dispatch("auth/getMe", null, { root: true });
  },

  async getById({ commit }, { id, params = {} }) {
    const res = await userService.getById(id, params);
    if (res) {
      if (!res.avatar && res.fullName) {
        res.avatar = {
          url: `https://ui-avatars.com/api/?name=${res.fullName}`,
        };
      }
    }
    commit("setUser", res);
  },

  async updateInfoThenUpdateAvatar({ commit, dispatch }, { id, info, avatar }) {
    try {
      commit("setLoading", true);
      if (!isEmpty(info)) await dispatch("update", { id, data: info });
      if (avatar) await dispatch("updateAvatar", avatar);
      commit("succeed", true);
      commit("setLoading", false);
    } catch (e) {
      commit("succeed", false);
      commit("setLoading", false);
    }
  },
};

const mutations = {
  setLoading(state, action) {
    state.isLoading = action;
  },
  succeed(state, action) {
    state.isSuccess = action;
  },
  setUser(state, action) {
    state.user = action;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
