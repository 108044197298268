<template>
  <layout-header></layout-header>
  <template v-if="isLoading">
    <div></div>
  </template>

  <template v-else-if="currentItem !== undefined">
    <div class="content p-0">
      <div class="container">
        <div class="row mb-3">
          <div
            class="col-xl-6 col-lg-6 col-md-5 d-flex align-items-start gap-3"
          >
            <router-link
              to="/quan-ly/san-pham"
              class="btn-default d-flex gap-2"
            >
              <rollback-outlined style="font-size: 18px" /> Quay lại</router-link
            >

            <h3>{{ currentItem.title }}</h3>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-7 button-container">
            <div
              class="d-flex justify-content-between flex-wrap button-options"
              style="flex-direction: row-reverse"
            >
              <a-button
                class="btn-border-default"
                @click="showPriceBasicUpdateModal = true"
                >Chỉnh sửa cài đặt cơ bản</a-button
              >
              <a-button
                class="btn-default"
                @click="showGeneralUpdateDrawer = true"
                >Chỉnh sửa đồng loạt</a-button
              >
            </div>
          </div>
        </div>
        <div class="d-flex">
          <span
            class="text-blue mr-3"
            style="font-weight: 500"
            >Tình trạng sản phẩm:
          </span>
          <a-switch
            v-model:checked="isPublishedRef"
            checked-children="Hiện"
            un-checked-children="Ẩn"
            @change="onChangeStatus"
          />
        </div>
        <p class="my-2">
          <small class="grey"
            >Khi bạn ẩn sản phẩm, người khác sẽ không tìm thấy sản phẩm của bạn
            trên hệ thống</small
          >
        </p>
        <div style="padding-top: 24px">
          <r-calendar
            type="large"
            :key="mainKey"
          />
        </div>
      </div>
    </div>
  </template>

  <general-price-update-drawer
    v-if="showGeneralUpdateDrawer"
    :visible="showGeneralUpdateDrawer"
    @close="showGeneralUpdateDrawer = false"
    :id="id"
  />

  <calendar-basic-update-modal
    v-if="showCalendarBasicUpdateModal"
    :visible="showCalendarBasicUpdateModal"
    @close="showCalendarBasicUpdateModal = false"
    :id="id"
  />

  <price-basic-update-modal
    v-if="showPriceBasicUpdateModal"
    :visible="showPriceBasicUpdateModal"
    @close="showPriceBasicUpdateModal = false"
    :id="id"
  />
</template>

<script setup>
import { ref, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import debounce from "lodash.debounce";
import { RollbackOutlined } from "@ant-design/icons-vue";
import { getMonthNow } from "@/utils/common";
const router = useRouter();
const store = useStore();

const mainKey = ref(new Date().getTime());

const showCalendarBasicUpdateModal = ref(false);
const showGeneralUpdateDrawer = ref(false);
const showPriceBasicUpdateModal = ref(false);

const isLoading = computed(() => store.getters["thing/getLoading"]);
const currentItem = computed(() => store.getters["thing/getCurrentItem"]);
const currentUser = computed(() => store.getters["auth/currentUser"]);

const id = router.currentRoute.value.params.id;
const isPublishedRef = ref(false);
const isPublished = ref(null);
store.commit("thing/setCurrentDateView", getMonthNow());
const loadCurrentItem = (showLoading) => {
  store.dispatch("thing/loadCurrentItem", {
    id,
    params: {
      populate: ["specials", "owner"],
      select: ["title", "price", "pastCalendar", "publishedAt"],
    },
    showLoading,
  });
};

if (id) {
  loadCurrentItem(true);
} else {
  store.commit("thing/notFound");
}

watchEffect(() => {
  if (!isLoading.value) {
    if (currentItem.value === undefined) router.push("/user/user-dashboard");
    else {
      if (
        currentItem.value.owner &&
        currentItem.value.owner.id !== currentUser.value.id
      )
        router.push("/");
      if (currentItem.value.price === 0) {
        showCalendarBasicUpdateModal.value = true;
      } else {
        mainKey.value = new Date().getTime();
      }

      isPublishedRef.value = !!currentItem.value.publishedAt;
      isPublished.value = !!currentItem.value.publishedAt;
    }
  }
});

const onChangeStatus = debounce((value) => {
  if (value !== isPublished.value) {
    isPublished.value = value;
    store.dispatch("thing/update", {
      id,
      data: { publishedAt: value ? new Date().toISOString() : null },
    });
  }
}, 1000);
</script>

<style scoped>
.content {
  padding-top: 40px !important;
}
@media only screen and (max-width: 767.9px) {
  .button-options {
    margin-top: 1em;
  }
}

@media only screen and (min-width: 768px) {
  .button-options {
    gap: 52px;
    width: fit-content;
    float: right;
  }

  .button-container {
    min-width: 400px;
  }
}

@media only screen and (min-width: 1341px) {
  .container {
    width: 60vw !important;
  }
}

@media only screen and (max-width: 1340px) {
  .container {
    width: 65vw !important;
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    width: 72vw !important;
  }
}

@media only screen and (max-width: 1070px) {
  .container {
    width: 79vw !important;
  }
}

@media only screen and (max-width: 960px) {
  .container {
    width: 86vw !important;
  }
}

@media only screen and (max-width: 860px) {
  .container {
    width: 100% !important;
  }
}
</style>
