<template>
  <section class="section popular-car-type">
    <div class="container">
      <!-- Heading title-->
      <div class="section-heading" data-aos="fade-down">
        <h2>Các loại sản phẩm</h2>
        <p>Danh sách loại sản phẩm mà chúng tôi hỗ trợ</p>
      </div>
      <!-- /Heading title -->
      <div class="row">
        <div class="popular-slider-group">
          <div class="owl-carousel popular-cartype-slider owl-theme">
            <!-- owl carousel item -->
            <Carousel
              :wrap-around="true"
              :settings="settings"
              :breakpoints="breakpoints"
            >
              <Slide v-for="item in ProductTypeList" :key="item.id">
                <div class="listing-owl-item">
                  <div class="listing-owl-group">
                    <div class="listing-owl-img">
                      <img
                        :src="
                          require(`@/assets/img/categories/${item.value}.png`)
                        "
                        class="img-fluid"
                        alt="Popular"
                      />
                    </div>
                    <h6>{{ item.label }}</h6>
                    <p>
                      {{
                        totalByType && totalByType[item.value]
                          ? totalByType[item.value]
                          : 0
                      }}
                      cái
                    </p>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
            <!-- /owl carousel item -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { ProductTypeList } from "@/constants";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

const settings = computed(() => ({
  itemsToShow: 1,
  snapAlign: "center",
}));

const breakpoints = computed(() => ({
  575: {
    itemsToShow: 3,
    snapAlign: "center",
  },
  767: {
    itemsToShow: 3,
    snapAlign: "center",
  },
  991: {
    itemsToShow: 3,
    snapAlign: "center",
  },
  1024: {
    itemsToShow: 4.9,
    snapAlign: "start",
  },
}));
const store = useStore();

const totalByType = computed(() => store.getters["thing/totalByType"]);

store.dispatch("thing/getTotalByType");
</script>
