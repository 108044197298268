<template>
  <div class="review-sec listing-feature">
    <div class="review-header">
      <h4>Hỗ trợ đi kèm</h4>
    </div>
    <div class="listing-description">
      <div class="row">
        <div class="col-md-4" v-for="(item, index) in data" :key="index">
          <ul>
            <li>
              <i
                :class="{
                  'feather-check': item.value,
                  'feather-x': !item.value,
                  'allow-type': item.value,
                  'disallow-type': !item.value,
                }"
              ></i
              >{{ item.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import { shoeMapping, shoeStatusMapping } from "../../../../utils/common";

const store = useStore();
const currentItem = computed(() => store.getters["thing/getCurrentItem"]);

const data = computed(() => {
  switch (currentItem.value.type) {
    case "phone":
    case "tablet":
    case "pc":
      return [
        {
          title: "Tai nghe",
          value: currentItem.value.device.headPhoneSupport,
        },
        {
          title: "Củ sạc",
          value: currentItem.value.device.chargerSupport,
        },
        {
          title: "Dây sạc USB",
          value: currentItem.value.device.cableChargerSupport,
        },
        {
          title: "Ốp lưng",
          value: currentItem.value.device.caseSupport,
        },
        {
          title: "Kính cường lực",
          value: currentItem.value.device.screenProtecterSupport,
        },
      ];
    case "lightstick":
      return [
        {
          title: "Bao lightstick",
          value: currentItem.value.lightstick.wrapperSupport,
        },
        {
          title: "Dây cầm",
          value: currentItem.value.lightstick.cordSupport,
        },
        {
          title: "Pin",
          value: currentItem.value.lightstick.batterySupport,
        },
      ];
    case "digitalcamera":
      return [
        {
          title: "Thẻ nhớ",
          value: currentItem.value.digitalcamera.cardSupport,
        },
        {
          title: "Túi đựng",
          value: currentItem.value.digitalcamera.pocketSupport,
        },
        {
          title: "Dây sạc USB",
          value: currentItem.value.digitalcamera.cableChargerSupport,
        },
      ];
    default:
      return [];
  }
});
</script>
