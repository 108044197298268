<template>
  <!-- FAQ  -->
  <section class="section faq-section bg-light-primary">
    <div class="container">
      <!-- Heading title-->
      <div class="section-heading" data-aos="fade-down">
        <h2>Câu hỏi thường gặp</h2>
        <p>Dưới đây là cách bạn có thể sử dụng trang web này</p>
      </div>
      <!-- /Heading title -->
      <div class="faq-info">
        <div
          class="faq-card bg-white"
          data-aos="fade-down"
          v-for="item in data"
        >
          <h4 class="faq-title">
            <a
              class="collapsed"
              data-bs-toggle="collapse"
              :href="`#${item.href}`"
              aria-expanded="false"
              >{{ item.title }}</a
            >
          </h4>
          <div :id="item.href" class="card-collapse collapse">
            <p v-html="item.description"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /FAQ -->
</template>
<script setup>
import { computed } from "vue";
import { EMAIL_SUPPORT } from "@/constants";
const data = computed(() => [
  {
    href: "how-to-rent",
    title: "Cách thuê sản phẩm",
    description: `<p>Chào mừng bạn đến với trang web Thuedocanhan! Dưới đây là cách bạn có thể sử dụng trang web này:</p>
    <br>
    <ul>
    <li><b>Tìm kiếm sản phẩm cho thuê:</b>  Truy cập vào trang chủ của trang web.
    Sử dụng tính năng tìm kiếm để nhập từ khóa sản phẩm bạn quan tâm.
    Kết quả tìm kiếm sẽ hiển thị các sản phẩm có sẵn để thuê vào ngày bạn cần.
    </li>
    <li> <b>Xem chi tiết sản phẩm và lịch trống:</b> Nhấp vào sản phẩm để xem thông tin chi tiết như mô tả, hình ảnh và giá thuê. Kiểm tra lịch trống của sản phẩm để biết những ngày trong tương lai gần mà sản phẩm có sẵn.</li>
    <li><b>Đăng nhập để xem thông tin liên hệ:</b>  Để xem thông tin liên hệ của chủ sở hữu sản phẩm, bạn cần đăng nhập vào tài khoản của mình. Sau khi đăng nhập thành công, bạn có thể xem được thông tin liên lạc của chủ sở hữu để tiếp cận và thương lượng thuê sản phẩm. <br>
    Lựa chọn phương thức giao dịch (ví dụ: trực tiếp thanh toán, cọc hoặc thế chấp). Nếu cần yêu cầu thế chấp, đưa ra các yêu cầu cụ thể để bảo vệ sản phẩm của bạn. Sau khi điền đầy đủ thông tin, kiểm tra lại và nhấn "Đăng sản phẩm". Sản phẩm của bạn sẽ được duyệt và đăng trên trang web để mọi người có thể tìm kiếm và thuê.
    </li>
    <li><b>Thương lượng và giao dịch: </b>Sau khi liên hệ với chủ sở hữu, bạn và chủ sở hữu sẽ tự thương lượng và thực hiện giao dịch thuê sản phẩm theo thỏa thuận của hai bên.</li>
    </ul>
    <p><b>Lưu ý:</b> Nếu bạn chưa có tài khoản, vui lòng đăng ký để có thể sử dụng đầy đủ tính năng của trang web. Nếu cần hỗ trợ hoặc có câu hỏi, vui lòng liên hệ với chúng tôi qua email <a href="mailto:support@thuedocanhan.com">support@thuedocanhan.com</a>. Việc giao dịch và thuê sản phẩm là trách nhiệm của người thuê và người cho thuê. Người thuê nên đảm bảo rằng họ hiểu rõ các điều khoản và điều kiện của giao dịch trước khi thực hiện.</p>`,
  },
  {
    href: "how-to-create",
    title: "Cách tạo sản phẩm cho thuê",
    description: `<p>Chào mừng bạn đến với trang web Thuedocanhan! Dưới đây là cách bạn có thể sử dụng trang web này:</p>
    <br>
    <ul>
    <li><b>Đăng nhập vào tài khoản của bạn:</b> Truy cập vào trang Đồ của tôi và đăng nhập vào tài khoản của bạn</li>
    <li> <b>Tạo chi tiết sản phẩm và lịch trống:</b> Sau khi đăng nhập thành công, truy cập vào trang “Đồ của tôi”. Nhấp vào nút "Tạo sản phẩm thuê mới" để bắt đầu.</li>
    <li><b>Điền thông tin sản phẩm:</b> Điền các thông tin cần thiết về sản phẩm thuê như tên sản phẩm, mô tả chi tiết, và các đặc điểm quan trọng. Thêm hình ảnh chất lượng cao của sản phẩm để thu hút người thuê. <br>
    Lựa chọn phương thức giao dịch (ví dụ: trực tiếp thanh toán, cọc hoặc thế chấp). Nếu cần yêu cầu thế chấp, đưa ra các yêu cầu cụ thể để bảo vệ sản phẩm của bạn. Sau khi điền đầy đủ thông tin, kiểm tra lại và nhấn "Đăng sản phẩm". Sản phẩm của bạn sẽ được duyệt và đăng trên trang web để mọi người có thể tìm kiếm và thuê.
    </li>
    <li><b>Đặt giá và ngày bắt đầu cho thuê: </b>Xác định giá thuê cơ bản cho sản phẩm của bạn. Chọn ngày bắt đầu và kết thúc có thể cho thuê sản phẩm.
    Thiết lập lịch và giá thuê đặc biệt (tuỳ chọn):
    Nếu muốn, bạn có thể thiết lập giá riêng cho từng ngày hoặc khoảng thời gian đặc biệt. Quyết định các mốc thời gian và giá cụ thể để linh hoạt hơn trong việc cho thuê  sản phẩm.
    </li>
    </ul>
    <p><b>Lưu ý:</b> Hãy đảm bảo rằng mô tả và hình ảnh sản phẩm của bạn chính xác và hấp dẫn để thu hút người thuê. <br>
    Luôn kiểm tra lại các thông tin trước khi đăng sản phẩm để đảm bảo tính chính xác và đầy đủ. Nếu gặp bất kỳ vấn đề nào trong quá trình tạo sản phẩm, vui lòng liên hệ với hỗ trợ của chúng tôi để được trợ giúp.
    </p>`,
  },
  {
    href: "search",
    title: "Tìm kiếm sản phẩm cho thuê",
    description:
      "<ul><li>Truy cập vào trang chủ của trang web.</li><li>Sử dụng tính năng tìm kiếm để nhập từ khóa sản phẩm bạn quan tâm.</li><li>Kết quả tìm kiếm sẽ hiển thị các sản phẩm có sẵn để thuê vào ngày bạn cần.</li></ul>",
  },
  {
    href: "detail",
    title: "Xem chi tiết sản phẩm và lịch trống",
    description:
      "<ul><li>Nhấp vào sản phẩm để xem thông tin chi tiết như mô tả, hình ảnh và giá thuê.</li><li>Kiểm tra lịch trống của sản phẩm để biết những ngày trong tương lai gần mà sản phẩm có sẵn.</li></ul>",
  },
  {
    href: "info",
    title: "Đăng nhập để xem thông tin liên hệ",
    description:
      "<ul><li>Để xem thông tin liên hệ của chủ sở hữu sản phẩm, bạn cần đăng nhập vào tài khoản của mình.</li><li>Sau khi đăng nhập thành công, bạn có thể xem được thông tin liên lạc của chủ sở hữu để tiếp cận và thương lượng thuê sản phẩm.</li></ul>",
  },
  {
    href: "book",
    title: "Thương lượng và giao dịch",
    description:
      "Sau khi liên hệ với chủ sở hữu, bạn và chủ sở hữu sẽ tự thương lượng và thực hiện giao dịch thuê sản phẩm theo thỏa thuận của hai bên.",
  },
  {
    href: "bookNote",
    title: "Lưu ý khi thuê",
    description: `<ul><li>Nếu bạn chưa có tài khoản, vui lòng đăng ký để có thể sử dụng đầy đủ tính năng của trang web.</li><li>Nếu cần hỗ trợ hoặc có câu hỏi, vui lòng liên hệ với chúng tôi qua email ${EMAIL_SUPPORT}.</li><li>Việc giao dịch và thuê sản phẩm là trách nhiệm của người thuê và người cho thuê. Người thuê nên đảm bảo rằng họ hiểu rõ các điều khoản và điều kiện của giao dịch trước khi thực hiện.</li></ul>`,
  },
  {
    href: "login",
    title: "Đăng nhập vào tài khoản của bạn",
    description:
      "Truy cập vào trang Đăng nhập và tiến hành đăng nhập vào tài khoản của bạn.",
  },
  {
    href: "calendar",
    title: "Xem chi tiết sản phẩm và lịch trống",
    description:
      '<ul><li>Sau khi đăng nhập thành công, truy cập vào trang "Sản phẩm của tôi".</li><li>Nhấp vào nút "Tạo sản phẩm thuê mới" để bắt đầu.</li></ul>',
  },
  {
    href: "create",
    title: "Điền thông tin sản phẩm",
    description:
      '<ul><li>Điền các thông tin cần thiết về sản phẩm thuê như tên sản phẩm, mô tả chi tiết, và các đặc điểm quan trọng.</li><li>Thêm hình ảnh chất lượng cao của sản phẩm để thu hút người thuê.</li><li>Lựa chọn phương thức giao dịch (ví dụ: trực tiếp thanh toán, cọc hoặc thế chấp).</li><li>Nếu cần yêu cầu thế chấp, đưa ra các yêu cầu cụ thể để bảo vệ sản phẩm của bạn.</li><li>Sau khi điền đầy đủ thông tin, kiểm tra lại và nhấn "Đăng sản phẩm".</li><li>Sản phẩm của bạn sẽ được duyệt và đăng trên trang web để mọi người có thể tìm kiếm và thuê.</li></ul>',
  },
  {
    href: "price",
    title: "Đặt giá và ngày bắt đầu cho thuê",
    description:
      "<ul><li>Xác định giá thuê cơ bản cho sản phẩm của bạn.</li><li>Chọn ngày bắt đầu và kết thúc có thể cho thuê sản phẩm.</li><li>Thiết lập lịch và giá thuê đặc biệt (tuỳ chọn)</li><li>Nếu muốn, bạn có thể thiết lập giá riêng cho từng ngày hoặc khoảng thời gian đặc biệt.</li><li>Quyết định các mốc thời gian và giá cụ thể để linh hoạt hơn trong việc cho thuê sản phẩm.</li></ul>",
  },
  {
    href: "rentNote",
    title: "Lưu ý khi cho thuê",
    description:
      "<ul><li>Hãy đảm bảo rằng mô tả và hình ảnh sản phẩm của bạn chính xác và hấp dẫn để thu hút người thuê.</li><li>Luôn kiểm tra lại các thông tin trước khi đăng sản phẩm để đảm bảo tính chính xác và đầy đủ.</li><li>Nếu gặp bất kỳ vấn đề nào trong quá trình tạo sản phẩm, vui lòng liên hệ với hỗ trợ của chúng tôi để được trợ giúp.</li></ul>",
  },
  {
    href: "term",
    title: "Lưu ý cho người thuê và trách nhiệm pháp lý ",
    description:
      '<ul><li>Trang web của chúng tôi là nơi kết nối giữa người cho thuê và người thuê để thuận tiện trong việc giao dịch sản phẩm. Chúng tôi không phải là bên thứ ba trong giao dịch mà chỉ cung cấp nền tảng để các bên liên lạc và thương lượng.</li><li>Không chịu trách nhiệm pháp lý: Vui lòng đọc chi tiết ở <a href="/term" target="_blank">Quy định và Điều khoản</a>.</li><li>Việc sử dụng trang web của chúng tôi có nghĩa là người dùng đồng ý với các điều khoản và điều kiện của trang web, bao gồm lưu ý và trách nhiệm pháp lý nêu trên.</li></ul>',
  },
]);
</script>
