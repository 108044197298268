<!-- CustomInputNumber.vue -->
<template>
  <a-form-item
    :name="name"
    :label="label"
    :class="labelClass"
    :rules="rules"
  >
    <p
      class="grey mb-1"
      v-if="description"
      >{{ description }}</p
    >
    <a-input-number
      :class="inputClass"
      :placeholder="placeholder"
      :addon-after="addonAfter"
      :value="modelValue"
      @change="updateValue"
      @input="
        (value) => {
          isValidNumber = !value || !isNaN(value);
        }
      "
      :status="!isValidNumber ? 'error' : ''"
    />
    <span class="grey">{{ help }}</span>
    <p
      v-if="!isValidNumber"
      class="mt-1 validate-error"
      >Vui lòng một nhập số</p
    >
  </a-form-item>
</template>

<script setup>
import { ref } from "vue";
const emits = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: 0,
  name: "",
  label: "",
  labelClass: "",
  inputClass: "",
  placeholder: "",
  help: "",
  rules: null,
  addonAfter: "",
  description: "",
});
const isValidNumber = ref(true);

const updateValue = (newValue) => {
  emits("update:modelValue", newValue);
};
</script>
