<template>
  <div
    :class="{
      'full-width mb-5': props.type === 'large',
      'label-small': props.type === 'small',
    }"
  >
    <a-date-picker
      v-model:value="monthView"
      :format="monthFormat"
      :inputReadOnly="true"
      picker="month"
      :allowClear="false"
      class="picker-month"
      @change="onChange"
    >
      <template #monthCellRender="{ current: value }">
        <div
          :class="{
            'month-selected': isSameMonth(new Date(value), currentDate),
          }"
        >
          {{ `Th. ${new Date(value).getMonth() + 1}` }}
        </div>
      </template></a-date-picker
    >
  </div>

  <div class="status-annotation">
    <p>Chú thích trạng thái</p>

    <div class="row annotation-list">
      <div class="col-md-3 item"
        ><div class="status on"></div> Trạng thái Mở</div
      >
      <div class="col-md-3 item"
        ><div class="status off"></div> Trạng thái Tắt</div
      >
      <div class="col-md-3 item"
        ><div class="status rental"></div> Trạng thái đang Thuê</div
      >
      <div class="col-md-3 item"
        ><div class="past"></div> Ngày trong quá khứ</div
      >
    </div>
  </div>
  <div
    id="label"
    :class="{
      'full-width': props.type === 'large',
      'label-small': props.type === 'small',
    }"
  >
    <div @click="prevMonth">
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.59986 7.70982C2.50613 7.61685 2.43174 7.50625 2.38097 7.38439C2.3302 7.26253 2.30406 7.13183 2.30406 6.99982C2.30406 6.86781 2.3302 6.7371 2.38097 6.61524C2.43174 6.49338 2.50613 6.38278 2.59986 6.28982L7.18986 1.70982C7.28359 1.61685 7.35798 1.50625 7.40875 1.38439C7.45952 1.26253 7.48566 1.13183 7.48566 0.999816C7.48566 0.867804 7.45952 0.737098 7.40875 0.615239C7.35798 0.49338 7.28359 0.382779 7.18986 0.289816C7.0025 0.103565 6.74905 -0.000976562 6.48486 -0.000976562C6.22067 -0.000976562 5.96722 0.103565 5.77986 0.289816L1.18986 4.87982C0.628058 5.44232 0.3125 6.20481 0.3125 6.99982C0.3125 7.79482 0.628058 8.55732 1.18986 9.11982L5.77986 13.7098C5.96612 13.8946 6.21752 13.9987 6.47986 13.9998C6.61147 14.0006 6.74193 13.9754 6.86377 13.9256C6.9856 13.8758 7.09642 13.8025 7.18986 13.7098C7.28359 13.6169 7.35798 13.5063 7.40875 13.3844C7.45952 13.2625 7.48566 13.1318 7.48566 12.9998C7.48566 12.8678 7.45952 12.7371 7.40875 12.6152C7.35798 12.4934 7.28359 12.3828 7.18986 12.2898L2.59986 7.70982Z"
          fill="#575965"
        />
      </svg>
    </div>
    <div>
      {{ `Tháng ${currentDate.getMonth() + 1}/${currentDate.getFullYear()}` }}
    </div>
    <div @click="nextMonth">
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.3998 4.87982L1.8098 0.289816C1.62244 0.103565 1.36899 -0.000976562 1.1048 -0.000976562C0.840616 -0.000976562 0.587165 0.103565 0.399802 0.289816C0.306074 0.382779 0.23168 0.49338 0.180911 0.615239C0.130142 0.737098 0.104004 0.867804 0.104004 0.999816C0.104004 1.13183 0.130142 1.26253 0.180911 1.38439C0.23168 1.50625 0.306074 1.61685 0.399802 1.70982L4.9998 6.28982C5.09353 6.38278 5.16793 6.49338 5.21869 6.61524C5.26946 6.7371 5.2956 6.86781 5.2956 6.99982C5.2956 7.13183 5.26946 7.26253 5.21869 7.38439C5.16793 7.50625 5.09353 7.61685 4.9998 7.70982L0.399802 12.2898C0.211499 12.4768 0.105184 12.7309 0.104246 12.9963C0.103308 13.2616 0.207825 13.5165 0.394803 13.7048C0.581781 13.8931 0.835903 13.9994 1.10127 14.0004C1.36663 14.0013 1.6215 13.8968 1.8098 13.7098L6.3998 9.11982C6.96161 8.55732 7.27716 7.79482 7.27716 6.99982C7.27716 6.20481 6.96161 5.44232 6.3998 4.87982Z"
          fill="#575965"
        />
      </svg>
    </div>
  </div>
  <div class="calender-container bg-white">
    <table
      :class="{
        'padding-width': props.type === 'large',
      }"
    >
      <thead>
        <tr>
          <th
            v-for="(day, index) in days"
            :key="day"
          >
            <div class="day-of-week dayLarge">{{ day }}</div>
            <div class="day-of-week daySmall">{{ daysMobile[index] }}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(week, weekIndex) in weeks"
          :key="week"
        >
          <td
            v-for="(day, index) in week"
            :key="day"
            :class="{
              large: props.type === 'large',
              small: props.type === 'small',
              'empty-cell-top':
                day === '' &&
                (index === 0 || week[index - 1] === '') &&
                weekIndex !== weeks.length - 1,
              'empty-cell-left':
                day === '' && (index === 0 || week[index - 1] === ''),
              'border-cell-right': index === 6 && day !== '',
              'border-cell-bottom':
                weekIndex === weeks.length - 1 && day !== '',
            }"
          >
            <r-calendar-item
              :day="day"
              :month="currentDate.getMonth()"
              :year="currentDate.getFullYear()"
              v-bind="props"
              v-if="day !== ''"
            />
          </td>
        </tr>
        <tr v-if="props.type === 'large'">
          <th>
            <div class="day-of-week dayLarge"></div>
            <div class="day-of-week daySmall"></div>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect } from "vue";
import dayjs from "dayjs";
import { isSameMonth, getMonthNow } from "../utils/common";
import { useStore } from "vuex";

const store = useStore();
const currentDateView = computed(() => store.getters["thing/currentDateView"]);
const currentDate = ref();
watchEffect(() => {
  currentDate.value = currentDateView.value || getMonthNow();
});
const props = defineProps(["type"]);
const monthFormat = "MM/YYYY";
const days = [
  "Thứ Hai",
  "Thứ Ba",
  "Thứ Tư",
  "Thứ Năm",
  "Thứ Sáu",
  "Thứ Bảy",
  "Chủ Nhật",
];
const daysMobile = ["T2", "T3", "T4", "T5", "T6", "T7", "CN"];

const monthView = ref(
  dayjs(
    `${(currentDate.value.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${currentDate.value.getFullYear()}`,
    monthFormat
  )
);

const onChange = (date, dateStr) => {
  const [m, y] = dateStr.split("/");
  const newDate = new Date(currentDate.value.getTime());
  newDate.setMonth(+m - 1);
  newDate.setFullYear(+y);
  currentDate.value = newDate;
};

const prevMonth = () => {
  const newDate = new Date(currentDate.value.getTime());
  newDate.setMonth(currentDate.value.getMonth() - 1);
  currentDate.value = newDate;
  monthView.value = dayjs(
    `${(newDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${newDate.getFullYear()}`,
    monthFormat
  );
};

const nextMonth = () => {
  const newDate = new Date(currentDate.value.getTime());
  newDate.setMonth(currentDate.value.getMonth() + 1);
  currentDate.value = newDate;
  monthView.value = dayjs(
    `${(newDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${newDate.getFullYear()}`,
    monthFormat
  );
};

const weeks = computed(() => {
  const firstDayOfMonth = new Date(
    currentDate.value.getFullYear(),
    currentDate.value.getMonth(),
    1
  );
  const firstDay = firstDayOfMonth.getDay();
  const offset = firstDay === 0 ? 6 : firstDay - 1;

  const daysInMonth = new Date(
    currentDate.value.getFullYear(),
    currentDate.value.getMonth() + 1,
    0
  ).getDate();

  const weeks = [];
  let week = [];
  for (let i = 0; i < offset; i++) {
    week.push("");
  }
  for (let i = 1; i <= daysInMonth; i++) {
    week.push(i);
    if (week.length === 7) {
      weeks.push(week);
      week = [];
    }
  }
  if (week.length > 0) {
    while (week.length < 7) {
      week.push("");
    }
    weeks.push(week);
  }
  return weeks;
});
</script>
<style scoped>
table {
  margin: auto;
}

td {
  width: 8vw;
  height: 8vw;
  vertical-align: top;
  border-top: 1px solid #dae3bb;
  border-left: 1px solid #dae3bb;
}

.small {
  width: 8vw;
  height: 6vw;
}

#label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;

  & > div:first-child,
  & > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  & > div:nth-child(2) {
    font-weight: 800;
    font-size: 20px;
    color: #657541;
  }
}

.label-large {
  width: 56vw;
  margin-left: auto;
  margin-right: auto;
}

.label-small {
  width: 100% !important;
}

.status-annotation {
  background-color: white;
  padding: 2em 1.5em;
  border-radius: 12px;

  p {
    color: #657541;
    font-weight: 500;
  }

  .annotation-list .item {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 3px 0;
  }
  .annotation-list .item .status {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    &.on {
      background-color: #de6b00;
    }
    &.off {
      background-color: #c8cec4;
    }
    &.rental {
      background-color: #0591c0;
    }
  }
  .annotation-list .item .past {
    width: 30px;
    height: 20px;
    background-color: #ebebe7;
  }
}

.calender-container {
  background-color: white;
  border-radius: 12px;
  table.padding-width {
    width: 92%;
  }
}
.day-of-week {
  margin-top: 14px;
}

@media only screen and (max-width: 1340px) {
  .large {
    width: 9vw;
    height: 9vw;
  }
  .label-large {
    width: 63vw !important;
  }
}

@media only screen and (max-width: 1200px) {
  .large {
    width: 10vw;
    height: 10vw;
  }
  .label-large {
    width: 70vw !important;
  }
}

@media only screen and (max-width: 1070px) {
  .large {
    width: 11vw;
    height: 11vw;
  }
  .label-large {
    width: 77vw !important;
  }
}

@media only screen and (max-width: 960px) {
  .large {
    width: 12vw;
    height: 12vw;
  }
  .label-large {
    width: 84vw !important;
  }
}

@media only screen and (max-width: 860px) {
  .large {
    width: 14vw;
    height: 14vw;
  }
  .label-large {
    width: 100% !important;
  }
}

.empty-cell-top {
  border-top-color: transparent;
}

.empty-cell-left {
  border-left-color: transparent;
}

.border-cell-right {
  border-right: 1px solid #dae3bb;
}

.border-cell-bottom {
  border-bottom: 1px solid #dae3bb;
}

th {
  text-align: center;
  padding-bottom: 10px;
}

.daySmall {
  display: none;
}

@media only screen and (max-width: 600px) {
  .dayLarge {
    display: none;
  }

  .daySmall {
    display: block;
  }
}

@media only screen and (max-width: 1800px) {
  .small {
    width: 8vw;
    height: 7vw;
  }
}

@media only screen and (max-width: 1600px) {
  .small {
    width: 8vw;
    height: 8vw;
  }
}

@media only screen and (max-width: 1400px) {
  .small {
    width: 8vw;
    height: 7vw;
  }
}

@media only screen and (max-width: 1200px) {
  .small {
    width: 9vw;
    height: 9vw;
  }
}

@media only screen and (max-width: 1150px) {
  .small {
    width: 9vw;
    height: 8vw;
  }
}

@media only screen and (max-width: 992px) {
  .small {
    width: 13vw;
    height: 13vw;
  }
  .label-small {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 780px) {
  .small {
    width: 13vw;
    height: 12vw;
  }
}
@media only screen and (min-width: 992.1px) {
  .annotation-list {
    margin-top: 1rem;
  }
}

.month-selected {
  color: red;
}
</style>
