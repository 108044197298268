<template>
  <div :class="`no-result-container ${props.class}`">
    <div class="text-center">
      <img
        src="@/assets/img/xin_loi.svg"
        alt="Xin lỗi"
      />
      <p class="mt-3">{{ props.text }}</p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  text: { type: String, required: true },
  class: { type: String, required: false, default: "" },
});
</script>

<style scoped>
.no-result-container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;

  div.text-center {
    max-width: 300px;
  }
}
</style>
