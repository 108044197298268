<template>
  <div class="main-wrapper login-body">
    <!-- Header -->
    <header class="log-header">
      <router-link to="/"
        ><img
          class="img-fluid logo-dark"
          src="@/assets/img/logo.svg"
          alt="Logo"
      /></router-link>
    </header>
    <!-- /Header -->

    <div class="login-wrapper">
      <div class="loginbox">
        <div class="login-auth">
          <div class="login-auth-wrap">
            <div class="sign-group">
              <router-link to="/" class="btn sign-up"
                ><span
                  ><i class="fe feather-corner-down-left" aria-hidden="true"></i
                ></span>
                Trở về trang chủ</router-link
              >
            </div>
            <h1>Đăng kí tài khoản</h1>
            <p class="account-subtitle">
              {{
                step === REGISTER_STEP.OTP
                  ? 'Chúng tôi đã gửi cho bạn một mã OTP. Vui lòng kiểm tra trong hộp thư đến (inbox) hoặc thư rác (spam). Mã này có hiệu lực trong vòng 5 phút'
                  : step === REGISTER_STEP.CONTACT
                  ? "Thêm thông tin liên hệ của bạn"
                  : `Tạo tài khoản dễ dàng và nhanh chóng. Nếu bạn muốn cho thuê đồ,
              hãy chọn "bạn muốn cho thuê" nhé`
              }}
            </p>
            <google-login-form v-if="isSignupWithGoogle" />
            <div v-else>
              <Form
                @submit="onSubmitInfo"
                :validation-schema="infoSchema"
                v-slot="{ errors }"
                v-if="step === REGISTER_STEP.INFO"
              >
                <div class="input-block">
                  <label class="form-label"
                    >Tên đầy đủ của bạn
                    <span class="text-danger">*</span></label
                  >
                  <Field
                    name="fullName"
                    type="text"
                    class="form-control mt-2"
                    placeholder="Nhập"
                    :class="{ 'is-invalid': errors.fullName }"
                  />
                  <div class="invalid-feedback">{{ errors.fullName }}</div>
                  <div class="emailshow text-danger" id="fullName"></div>
                </div>
                <div class="input-block mt-3">
                  <label class="form-label"
                    >Email <span class="text-danger">*</span></label
                  >
                  <Field
                    name="email"
                    type="text"
                    class="form-control"
                    placeholder="Email"
                    :class="{ 'is-invalid': errors.email }"
                  />
                  <div class="invalid-feedback">{{ errors.email }}</div>
                  <div class="emailshow text-danger" id="email"></div>
                </div>
                <div class="input-block mt-3">
                  <label class="form-label"
                    >Mật khẩu <span class="text-danger">*</span></label
                  >
                  <div class="pass-group">
                    <Field
                      name="password"
                      :type="showPassword ? 'text' : 'password'"
                      class="form-control pass-input mt-2"
                      placeholder="Điền mật khẩu"
                      :class="{ 'is-invalid': errors.password }"
                    />
                    <span
                      @click="showPassword = !showPassword"
                      class="toggle-password"
                    >
                      <i
                        :class="{
                          'fas fa-eye': showPassword,
                          'fas fa-eye-slash': !showPassword,
                        }"
                      ></i>
                    </span>
                    <div class="invalid-feedback">{{ errors.password }}</div>
                    <div class="emailshow text-danger" id="password"></div>
                  </div>
                </div>
                <div class="input-block mt-3 mb-0">
                  <a-checkbox v-model:checked="isRenting"
                    >Bạn muốn cho thuê?</a-checkbox
                  >
                </div>

                <div class="input-block">
                  <a-checkbox v-model:checked="isAgreeWithTerms">
                    Bạn tạo tài khoản là đồng ý với quy định,
                    <router-link target="_blank" to="/dieu-khoan"
                      >điều khoản
                    </router-link>
                    sử dụng của chúng tôi</a-checkbox
                  >
                </div>

                <b-button
                  :disabled="!isAgreeWithTerms"
                  class="primary btn-block mt-3"
                  type="submit"
                  >Tạo tài khoản
                </b-button>
                <div class="login-or">
                  <span class="or-line"></span>
                  <span class="span-or-log">Hoặc</span>
                </div>
                <!-- /Social Login -->

                <google-login-button />

                <div class="text-center dont-have">
                  Bạn đã có tài khoản?
                  <router-link to="/dang-nhap">Đăng nhập</router-link>
                </div>
              </Form>

              <Form
                @submit="onSubmitContact"
                :validation-schema="contactSchema"
                v-slot="{ errors }"
                v-if="step === REGISTER_STEP.CONTACT"
              >
                <div class="input-block">
                  <label class="form-label">Số zalo</label>
                  <Field
                    name="zalo"
                    type="text"
                    class="form-control"
                    placeholder="Nhập số zalo"
                    :class="{ 'is-invalid': errors.zalo }"
                  />
                  <div class="invalid-feedback">{{ errors.zalo }}</div>
                  <div class="emailshow text-danger" id="zalo"></div>
                </div>
                <div class="input-block mt-3">
                  <label class="form-label">Địa chỉ Facebook</label>
                  <Field
                    name="facebook"
                    type="text"
                    class="form-control"
                    placeholder="Nhập địa chỉ Facebook"
                    :class="{ 'is-invalid': errors.facebook }"
                  />
                  <div class="invalid-feedback">{{ errors.facebook }}</div>
                  <div class="emailshow text-danger" id="facebook"></div>
                </div>
                <div class="input-block mt-3">
                  <label class="form-label">Số điện thoại </label>
                  <div class="pass-group">
                    <Field
                      name="phone"
                      type="text"
                      class="form-control pass-input"
                      placeholder="Nhập số điện thoại"
                      :class="{ 'is-invalid': errors.phone }"
                    />

                    <div class="invalid-feedback">{{ errors.phone }}</div>
                    <div class="emailshow text-danger" id="phone"></div>
                  </div>
                </div>

                <p class="emailshow text-danger mt-3" ref="contactErrorRef" />

                <b-button class="primary btn-block mt-3" type="submit"
                  >Tiếp theo
                </b-button>
              </Form>

              <div class="input-block" v-if="step === REGISTER_STEP.OTP">
                <label class="form-label"
                  >OTP <span class="text-danger">*</span></label
                >
                <input
                  name="otp"
                  type="text"
                  class="form-control mt-2"
                  placeholder="OTP"
                  v-model="otp"
                />
                <div class="emailshow text-danger" id="otp"></div>
                <b-button
                  class="primary btn-block mt-3"
                  type="button"
                  @click="onSubmitRegister()"
                  :disabled="!otp"
                  >Xác nhận
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <auth-footer></auth-footer>
    <!-- /Footer -->
  </div>
  <spinner-modal :visible="isLoading" />
</template>
<script setup>
import { ref, computed, watch, onUnmounted } from "vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { router } from "@/router";
import { useStore } from "vuex";
import { isValidFb, isValidPhone } from "@/utils/common";
import { message } from "ant-design-vue";
import { trimStringFields } from "@/utils/common";

const REGISTER_STEP = {
  INFO: "info",
  CONTACT: "contact",
  OTP: "otp",
};
const store = useStore();
const showPassword = ref(false);
const registerData = ref(null);
const step = ref(REGISTER_STEP.INFO);
const otp = ref("");
const contactErrorRef = ref(null);
const registerSucceed = computed(() => store.getters["auth/getSuccess"]);
const isAgreeWithTerms = ref(false);
const isSignupWithGoogle = computed(
  () => store.getters["auth/isSignupWithGoogle"]
);

const isRenting = ref(false);
const isLoading = ref(false);

watch(isSignupWithGoogle, (newVal) => {
  if (newVal !== undefined) {
    isLoading.value = false;
  }
});

watch(registerSucceed, (newVal, oldVal) => {
  if (newVal !== undefined) {
    isLoading.value = false;
    if (newVal === true) {
      router.push("/");
    } else {
      if (step.value === REGISTER_STEP.OTP)
        document.getElementById("otp").innerHTML = "OTP không hợp lệ";
    }
    store.commit("auth/succeed", undefined);
  }
});

const infoSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Tên đầy đủ là trường bắt buộc")
    .max(50, "Tên đầy đủ chỉ có tối đa 50 kí tự"),
  email: Yup.string()
    .required("Email là trường bắt buộc")
    .email("Email không hợp lệ"),
  password: Yup.string()
    .min(8, "Mật khẩu phải có ít nhất 8 kí tự")
    .required("Mật khẩu là trường bắt buộc"),
});

const onSubmitInfo = async (values) => {
  const data = trimStringFields(values, ["password"]);
  const emailCheck = await store.dispatch("auth/checkEmailExisted", data.email);
  if (!emailCheck.valid) {
    document.getElementById("email").innerHTML =
      "Email này đã được đăng ký trước đó, vui lòng đăng nhập hoặc sử dụng email khác";
  } else {
    registerData.value = data;
    if (isRenting.value === true) {
      step.value = REGISTER_STEP.CONTACT;
    } else {
      store.dispatch("auth/sendOTP", registerData.value.email);
      step.value = REGISTER_STEP.OTP;
    }
  }
};

const contactSchema = Yup.object().shape({
  zalo: Yup.string()
    .nullable()
    .test("isValidPhone", "Số zalo không hợp lệ", isValidPhone),
  facebook: Yup.string()
    .nullable()
    .test("isValidFb", "Địa chỉ facebook không hợp lệ", isValidFb),
  phone: Yup.string()
    .nullable()
    .test("isValidPhone", "Số điện thoại không hợp lệ", isValidPhone),
});

const onSubmitContact = (values) => {
  if (isRenting.value && !(values.zalo || values.facebook || values.phone)) {
    contactErrorRef.value.innerHTML =
      "Vui lòng điền ít nhất 1 trường thông tin liên hệ";
    return;
  }

  registerData.value = { ...registerData.value, ...values };
  store.dispatch("auth/sendOTP", registerData.value.email);
  step.value = REGISTER_STEP.OTP;
};

const onSubmitRegister = () => {
  store.dispatch("auth/register", {
    ...registerData.value,
    otp: otp.value,
  });
};

onUnmounted(() => {
  store.commit("auth/setIsSignupWithGoogle", undefined);
  store.commit("auth/setGoogleLoginData", undefined);
});
</script>
