<template>
  <a-modal
    title=""
    v-model:visible="props.visible"
    :footer="null"
    :onCancel="onClose"
    :maskClosable="!loading"
  >
    <h4>Thay đổi mật khẩu</h4>
    <a-form
      class="mt-3"
      ref="formRef"
      :model="formState"
      :rules="rules"
      name="basic"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
    >
      <div class="row">
        <div class="col-md-12">
          <a-form-item
            class="label-medium"
            label="Mật khẩu hiện tại"
            name="old"
          >
            <a-input-password
              v-model:value="formState.old"
              placeholder="............."
              ref="oldPasswordInput"
            />
            <p
              class="color-red mt-0 mb-3"
              v-if="wrongOldPassword"
              >Mật khẩu hiện tại không đúng, vui lòng nhập lại</p
            >
          </a-form-item>
        </div>
        <div class="col-md-12">
          <a-form-item
            class="label-medium"
            label="Mật khẩu mới"
            name="new"
          >
            <a-input-password
              v-model:value="formState.new"
              placeholder="............."
            />
          </a-form-item>
        </div>
        <div class="col-md-12">
          <a-form-item
            class="label-medium"
            label="Xác nhận mật khẩu mới"
            name="confirm"
          >
            <a-input-password
              v-model:value="formState.confirm"
              placeholder="............."
            />
          </a-form-item>
        </div>
      </div>
      <a-form-item :wrapper-col="{ span: 24 }">
        <div class="footer-btn mt-3">
          <a-button
            class="btn-border-default mr-3"
            @click="onClose"
            >Hủy</a-button
          >
          <a-button
            type="submit"
            @click="onSubmitChangePassword"
            class="btn-default"
            :loading="loading"
            >Lưu</a-button
          >
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { ref, reactive, watchEffect, computed, watch } from "vue";
const props = defineProps({
  visible: { type: Boolean, required: true },
});
const emits = defineEmits(["close"]);

const store = useStore();
const isSuccess = computed(() => store.getters["auth/getSuccess"]);

const oldPasswordInput = ref(null);
const wrongOldPassword = ref(false);
const formRef = ref();
const formState = reactive({
  old: "",
  confirm: "",
  new: "",
});

const rules = reactive({
  old: [
    {
      required: true,
      message: "Vui lòng nhập",
    },
  ],
  new: [
    {
      required: true,
      message: "Vui lòng nhập",
    },
    {
      min: 8,
      message: "Mật khẩu phải có ít nhất 8 kí tự",
      trigger: "blur",
    },
  ],
  confirm: [
    {
      required: true,
      message: "Vui lòng nhập",
    },
    {
      validator: async (_rule, value) => {
        if (formState.new && value !== formState.new) {
          return Promise.reject("Mật khẩu mới và xác nhận mật khẩu không đúng");
        }
      },
    },
  ],
});

const loading = ref(false);
watch(isSuccess, (newValue, oldValue) => {
  if (newValue !== undefined) {
    if (newValue === true) {
      message.success("Cập nhật thành công");
      onClose();
    } else {
      wrongOldPassword.value = true;
      oldPasswordInput.value.focus();
    }
    loading.value = false;
    store.commit("auth/succeed", undefined);
  }
});

const onSubmitChangePassword = () => {
  wrongOldPassword.value = false;
  loading.value = true;
  formRef.value
    .validate()
    .then(() => {
      store.dispatch("auth/changePassword", {
        oldPassword: formState.old,
        newPassword: formState.new,
      });
    })
    .catch((e) => {
      loading.value = false;
      return;
    });
};

const onClose = () => {
  formRef.value.resetFields();
  emits("close");
};
</script>
<style scoped>
::v-deep label {
  font-weight: 600 !important;
  font-size: 16px !important;
}

.footer-btn {
  button {
    min-width: 80px;
  }
}
</style>
