<template>
  <div class="row" v-if="recommend && recommend.length > 0">
    <div class="col-md-12">
      <div class="details-car-grid">
        <div class="details-slider-heading">
          <h3>Bạn có thể thích chúng</h3>
        </div>
        <div
          v-if="recommend.length >= 3"
          class="car-details-slider owl-carousel"
        >
          <Carousel
            :wrap-around="true"
            :settings="settings"
            :breakpoints="breakpoints"
            :class="{ product: true }"
          >
            <Slide v-for="item in recommend" :key="item.id">
              <thing-card :item="item" screen="recommend"></thing-card>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
        <div v-else class="row">
          <div
            class="col-lg-4 col-md-6 col-12"
            v-for="item in recommend"
            :key="item.id"
            data-aos="fade-down"
          >
            <thing-card :item="item" screen="recommend"></thing-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { Carousel, Navigation, Slide } from "vue3-carousel";

const store = useStore();
const settings = computed(() => ({
  itemsToShow: 1,
  snapAlign: "center",
}));
const breakpoints = computed(() => ({
  575: {
    itemsToShow: 1,
    snapAlign: "center",
  },
  767: {
    itemsToShow: 1,
    snapAlign: "center",
  },
  991: {
    itemsToShow: 3,
    snapAlign: "center",
  },
  1024: {
    itemsToShow: 3,
    snapAlign: "start",
  },
}));
const recommend = computed(() => store.getters["thing/recommend"]);
const currentItem = computed(() => store.getters["thing/getCurrentItem"]);

store.dispatch("thing/getRecommend", {
  id: currentItem.value.id,
  type: currentItem.value.type,
  brand: currentItem.value.brand?.id,
});
</script>
<style scoped>
.owl-carousel li {
  padding: 0 12px;
}

.details-slider-heading {
  padding-left: 12px;
}
</style>
