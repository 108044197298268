<template>
  <!-- services -->
  <section class="section services">
    <div class="service-right">
      <img
        src="@/assets/img/bg/service-right.svg"
        class="img-fluid"
        alt="services right"
      />
    </div>
    <div class="container">
      <!-- Heading title-->
      <div class="section-heading" data-aos="fade-down">
        <h2>Hướng dẫn thuê đồ cá nhân</h2>
        <p>Tìm kiếm và chọn cho mình sản phẩm chỉ với 3 bước đơn giản</p>
      </div>
      <!-- /Heading title -->
      <div class="services-work">
        <div class="row">
          <div
            class="col-lg-4 col-md-4 col-12"
            v-for="item in IndexService"
            :key="item.id"
            data-aos="fade-down"
          >
            <div class="services-group">
              <div class="services-icon border-secondary">
                <div :class="item.ImgClass" alt="Choose Locations">
                  <img
                    :src="require(`@/assets/img/icons/${item.Image}`)"
                    class="icon"
                  />
                </div>
              </div>
              <div class="services-content">
                <h3>{{ item.Title }}</h3>
                <p>
                  {{ item.Description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /services -->
</template>

<script setup>
import { computed } from "vue";
const IndexService = computed(() => [
  {
    id: 1,
    Image: "cursor.svg",
    Title: "1. Chọn loại đồ cần thuê",
    ImgClass: "icon-img bg-secondary",
    Description: "Ví dụ: Điện thoại, Laptop, Lightstick, ...",
  },
  {
    id: 2,
    Image: "location.svg",
    Title: "2. Chọn địa điểm thuê",
    ImgClass: "icon-img bg-warning",
    Description: "Vd: Hồ Chí Minh, Thái Lan, ...",
  },
  {
    id: 3,
    Image: "calendar.svg",
    Title: "3. Chọn ngày thuê - trả",
    ImgClass: "icon-img bg-dark",
    Description: "Chọn ngày thuê để hiển thị sản phẩm trống lịch",
  },
]);
</script>
<style scoped>
.icon {
  margin: 10px;
}
</style>
