<template>
  <!-- Popular Services -->
  <section class="section popular-services popular-explore" id="latest">
    <div class="container">
      <!-- Heading title-->
      <div class="section-heading" data-aos="fade-down">
        <h2>Sản phẩm mới đăng</h2>
        <p>Cùng theo dõi những sản phẩm mới toanh nhé</p>
      </div>
      <!-- /Heading title -->
      <div class="row justify-content-center">
        <div class="col-lg-12" data-aos="fade-down">
          <div class="listing-tabs-group">
            <ul class="nav listing-buttons gap-3" data-bs-tabs="tabs">
              <li>
                <a
                  class="active"
                  aria-current="true"
                  data-bs-toggle="tab"
                  @click="update(undefined)"
                >
                  Tất cả
                </a>
              </li>
              <li v-for="item in ProductTypeList">
                <a data-bs-toggle="tab" @click="update(item.value)">
                  {{ item.label }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <a-spin :spinning="isLatestLoading">
        <div class="tab-content">
          <div class="row">
            <no-result
              v-if="latest.length === 0"
              class="p-5"
              text="Chưa có sản phẩm"
            ></no-result>
            <div
              class="col-lg-4 col-md-6 col-12"
              v-for="item in latest"
              :key="item.id"
              data-aos="fade-down"
            >
              <thing-card :item="item" screen="latest"></thing-card>
            </div>
          </div>
        </div>
      </a-spin>
    </div>
  </section>
  <!-- /Popular Services -->
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { ProductTypeList } from "@/constants";

const store = useStore();
const latest = computed(() => store.getters["thing/latest"]);
const isLatestLoading = computed(() => store.getters["thing/isLatestLoading"]);

store.dispatch("thing/getLatest", {
  clearCache: true,
});

const update = (type) => {
  store.dispatch("thing/getLatest", {
    clearCache: false,
    type,
  });
};
</script>
