<script setup>
import { ref, reactive } from "vue";
import { ShoeStatus, ShoeTypes } from "@/constants";
import { checkEmptyString } from "@/utils/common";

const props = defineProps({
  brands: { type: Array, required: true },
  shoe: { type: Object, required: false },
});
const emits = defineEmits([]);
const formRef = ref();
const form = reactive({
  brand: null,
  brandOtherText: "",
  color: "",
  size: null,
  height: null,
  status: null,
  type: null,
});

const formRules = reactive({
  brand: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "blur",
    },
  ],
  brandOtherText: [
    {
      required: true,
      message: "Vui lòng nhập",
      trigger: "blur",
    },
    { validator: checkEmptyString, trigger: "blur" },
  ],
  color: [
    {
      required: true,
      message: "Vui lòng nhập",
      trigger: "blur",
    },
    { validator: checkEmptyString, trigger: "blur" },
  ],
  size: [
    {
      required: true,
      message: "Vui lòng nhập",
      trigger: "blur",
    },
  ],
  height: [
    {
      required: true,
      message: "Vui lòng nhập",
      trigger: "blur",
    },
  ],
  status: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "blur",
    },
  ],
  type: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "blur",
    },
  ],
});

if (props.shoe) {
  Object.assign(form, props.shoe);
}

const validateForm = () => {
  return formRef.value.validate();
};
defineExpose({ validateForm, values: form });
</script>

<template>
  <a-form
    :model="form"
    ref="formRef"
    :rules="formRules"
    name="basic"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    class="row"
  >
    <div class="col-lg-6">
      <div class="input-block">
        <a-form-item
          label="Nhãn hiệu"
          name="brand"
        >
          <a-select
            v-model:value="form.brand"
            style="width: 100%"
            :options="props.brands"
            placeholder="Vui lòng chọn"
          ></a-select>
        </a-form-item>
        <div
          v-if="form.brand == -1"
          class="d-flex align-items-start"
        >
          <img
            src="@/assets/img/other_brand_arrow.svg"
            alt="Other brand"
            style="margin-top: 18px"
          />
          <div class="input-block full-width ml-3">
            <a-form-item
              label="Nhãn hiệu khác"
              name="brandOtherText"
            >
              <a-input
                v-model:value="form.brandOtherText"
                class="full-width form-control"
                placeholder="Vui lòng nhập"
              />
            </a-form-item>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="input-block">
        <a-form-item
          label="Màu"
          name="color"
        >
          <a-input
            v-model:value="form.color"
            class="full-width form-control"
            placeholder="Vui lòng nhập"
          />
        </a-form-item>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="input-block">
        <form-input-number
          v-model="form.size"
          label="Size giày"
          name="size"
          :rules="formRules.size"
          input-class="full-width form-control"
          placeholder="Vui lòng nhập"
        ></form-input-number>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="input-block">
        <form-input-number
          v-model="form.height"
          label="Độ cao đế"
          name="height"
          :rules="formRules.height"
          input-class="full-width form-control"
          placeholder="Vui lòng nhập"
        ></form-input-number>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="input-block">
        <a-form-item
          label="Tình trạng giày"
          name="status"
        >
          <!-- <a-select
            v-model:value="form.status"
            style="width: 100%"
            :options="ShoeStatus"
            placeholder="Vui lòng chọn"
          ></a-select> -->
          <a-select
            v-model:value="form.status"
            style="width: 100%"
            :options="ShoeStatus"
            placeholder="Vui lòng chọn"
          ></a-select>
        </a-form-item>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="input-block">
        <a-form-item
          label="Loại giày"
          name="type"
        >
          <a-select
            v-model:value="form.type"
            style="width: 100%"
            :options="ShoeTypes"
            placeholder="Vui lòng chọn"
          ></a-select>
        </a-form-item>
      </div>
    </div>
  </a-form>
</template>
