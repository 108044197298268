import axiosInstance from "./axiosConfig";

const brandService = {
  async getBrands(params) {
    return axiosInstance
      .get("/brands", { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error("Fetching brands error: ", error);
      });
  },
};

export default brandService;
