<script setup>
import { ref, reactive, computed, watch } from "vue";
import dayjs from "dayjs";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { checkEmptyString, numberRules } from "@/utils/common";
import locale from "ant-design-vue/es/date-picker/locale/vi_VN";
import "dayjs/locale/vi";
dayjs.locale("vi");

const store = useStore();
const isSuccess = computed(() => store.getters["thing/getSuccess"]);
const loading = ref(false);
const props = defineProps({
  visible: { type: Boolean, required: true },
  id: { type: Number, required: true },
});
const emits = defineEmits(["close"]);
const formRef = ref();
const formState = reactive({
  from: dayjs(dayjs(), "YYYY-MM-DD"),
  to: dayjs(dayjs(), "YYYY-MM-DD"),
  dayOfWeeks: [0, 1, 2, 3, 4, 5, 6],
  price: "",
  type: null,
  nameInfo: "",
  phoneInfo: "",
  noteInfo: "",
});

const isChangingPrice = ref(false);

const formRules = reactive({
  from: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "change",
      type: "object",
    },
  ],
  to: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "change",
      type: "object",
    },
    {
      validator: async (_rule, value) => {
        if (formState.from) {
          const diff = dayjs(value).diff(dayjs(formState.from), "day");
          if (diff < 0)
            return Promise.reject("Vui lòng chọn bằng hoặc sau ngày bắt đầu");
        }
      },
      trigger: "change",
    },
  ],
  dayOfWeeks: [
    {
      type: "array",
      required: true,
      message: "Vui lòng chọn",
      trigger: "change",
    },
  ],
  price: numberRules({ isRequired: true, min: 0, max: 10000 }),
  type: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "blur",
    },
  ],
  nameInfo: [
    {
      required: true,
      message: "Vui lòng nhập",
      trigger: "blur",
    },
    { validator: checkEmptyString, trigger: "blur" },
  ],
  phoneInfo: [
    {
      required: true,
      message: "Vui lòng nhập",
    },
    { validator: checkEmptyString, trigger: "blur" },
  ],
});

const onClose = () => {
  formRef.value.resetFields();
  isChangingPrice.value = false;
  emits("close");
};

const onSubmit = () => {
  loading.value = true;
  formRef.value
    .validate()
    .then(() => {
      const data = {
        from: dayjs(formState.from).format("YYYY-MM-DD"),
        to: dayjs(formState.to).format("YYYY-MM-DD"),
        dayOfWeeks: formState.dayOfWeeks,
        type: formState.type,
      };
      switch (formState.type) {
        case "on":
          data.price = formState.price;
          break;
        case "booked":
          data.nameInfo = formState.nameInfo;
          data.phoneInfo = formState.phoneInfo;
          data.noteInfo = formState.noteInfo;
          break;
        case "off":
          break;
        default:
          break;
      }

      store.dispatch("thing/updateCalendar", { id: props.id, data });
    })
    .catch((err) => {
      console.log("error", err);
      loading.value = false;
    });
};

watch(isSuccess, (newVal, oldVal) => {
  if (newVal !== undefined) {
    if (newVal === true) {
      message.success("Cập nhật thành công");
      onClose();
    } else {
      message.error("Có lỗi. Vui lòng thử lại");
    }
    loading.value = false;
    store.commit("thing/succeed", undefined);
  }
});

const disabledDate = (current) => {
  const twoYearsFromNow = new Date().getFullYear() + 2;
  const endOfTwoYearsFromNow = new Date(twoYearsFromNow, 11, 31);
  return (
    current &&
    (current < Date.now() - 60 * 60 * 24 ||
      current > endOfTwoYearsFromNow.setHours(24))
  );
};
</script>

<template>
  <a-drawer
    v-model:open="props.visible"
    title="Chỉnh sửa đồng loạt"
    placement="right"
    rootClassName="large-drawer"
    @close="onClose"
    :maskClosable="!loading"
  >
    <a-form
      :model="formState"
      ref="formRef"
      :rules="formRules"
      name="basic"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
    >
      <div class="row">
        <div class="col-md-6">
          <a-form-item
            label="Từ"
            name="from"
            class="label-medium"
          >
            <a-date-picker
              v-model:value="formState.from"
              class="full-width"
              :picker="date"
              :disabledDate="disabledDate"
              placeholder="Vui lòng nhập"
              :locale="locale"
            />
          </a-form-item>
        </div>
        <div class="col-md-6">
          <a-form-item
            label="Đến và bao gồm"
            name="to"
            class="label-medium"
          >
            <a-date-picker
              v-model:value="formState.to"
              class="full-width"
              :picker="date"
              :disabledDate="disabledDate"
              placeholder="Vui lòng nhập"
            />
          </a-form-item>
        </div>
        <div class="col-md-12">
          <a-form-item
            label="Bạn muốn áp dụng thay đổi cho ngày nào trong tuần"
            class="label-medium"
            name="dayOfWeeks"
          >
            <a-checkbox-group v-model:value="formState.dayOfWeeks">
              <a-checkbox
                :value="1"
                name="dayOfWeeks"
                >Thứ Hai</a-checkbox
              >
              <a-checkbox
                :value="2"
                name="dayOfWeeks"
                >Thứ Ba</a-checkbox
              >
              <a-checkbox
                :value="3"
                name="dayOfWeeks"
                >Thứ Tư</a-checkbox
              >
              <a-checkbox
                :value="4"
                name="dayOfWeeks"
                >Thứ Năm</a-checkbox
              >
              <a-checkbox
                :value="5"
                name="dayOfWeeks"
                >Thứ Sáu</a-checkbox
              >
              <a-checkbox
                :value="6"
                name="dayOfWeeks"
                >Thứ Bảy</a-checkbox
              >
              <a-checkbox
                :value="0"
                name="dayOfWeeks"
                >Chủ Nhật</a-checkbox
              >
            </a-checkbox-group>
          </a-form-item>
        </div>
      </div>
      <hr class="color-red" />
      <h4>Chỉnh sửa ngày</h4>
      <p
        class="mt-3 mb-3"
        v-if="formState.from && formState.to"
        >Thay đổi sẽ được thực hiện cho khoảng thời gian:
        {{ dayjs(formState.from).format("DD/MM/YYYY") }} tới ngày
        {{ dayjs(formState.to).format("DD/MM/YYYY") }}</p
      >
      <p
        class="mt-3 mb-3"
        v-else-if="props.from"
        >Thay đổi sẽ được tạo cho ngày: {{ props.from }}</p
      >
      <div class="row">
        <div class="col-md-12">
          <a-form-item
            class="label-medium"
            label="Trạng thái ngày"
            name="type"
          >
            <a-radio-group v-model:value="formState.type">
              <a-radio value="off">Tắt</a-radio>
              <a-radio value="on">Mở</a-radio>
              <a-radio value="booked">Thuê</a-radio>
            </a-radio-group>
          </a-form-item>
        </div>
        <div
          v-if="formState.type === 'booked'"
          class="col-md-12"
        >
          <div class="bg-danger-light p-3 border-3">
            <p class="mb-3"
              >Thêm ghi chú để lưu lại thông tin chi tiết ngày nhé!</p
            >
            <a-form-item
              label="Tên người thuê"
              name="nameInfo"
              class="label-medium"
            >
              <a-input
                v-model:value="formState.nameInfo"
                class="full-width"
                placeholder="Vui lòng nhập"
              />
            </a-form-item>
            <a-form-item
              label="Số điện thoại"
              name="phoneInfo"
              class="label-medium"
            >
              <a-input
                v-model:value="formState.phoneInfo"
                class="full-width"
                placeholder="Vui lòng nhập"
              />
            </a-form-item>
            <a-form-item
              label="Ghi chú thêm"
              name="noteInfo"
              class="label-medium"
            >
              <a-textarea
                v-model:value="formState.noteInfo"
                class="full-width"
                placeholder="Vui lòng nhập"
              />
            </a-form-item>
          </div>
        </div>

        <div
          class="col-md-12"
          v-if="formState.type === 'on'"
        >
          <div class="d-flex">
            <span class="mr-3">Bạn muốn thay đổi giá ? </span>
            <a-switch v-model:checked="isChangingPrice" />
          </div>
        </div>

        <div
          class="col-md-6"
          v-if="formState.type === 'on' && isChangingPrice"
        >
          <form-input-number
            v-model="formState.price"
            label="Giá"
            name="price"
            label-class="label-medium"
            :rules="formRules.price"
            help="Đơn vị tính là Việt Nam đồng"
            input-class="full-width"
            addon-after="K"
            placeholder="Vui lòng nhập"
          ></form-input-number>
        </div>
        <div>
          <hr class="color-red" />
        </div>
        <a-form-item :wrapper-col="{ span: 24 }">
          <div style="display: flex">
            <a-button
              @click="onClose"
              class="btn-border-default mr-3"
              >Hủy</a-button
            >
            <a-button
              class="btn-default"
              type="primary"
              @click.prevent="onSubmit"
              :loading="loading"
              >Lưu</a-button
            >
          </div>
        </a-form-item>
      </div>
    </a-form>
  </a-drawer>
</template>

<style scoped>
::v-deep .label-medium .ant-form-item-label label {
  font-weight: 600 !important;
  font-size: 15.5px !important;
}
::v-deep .ant-picker-clear {
  display: none;
}
::v-deep .ant-input-number-input-wrap {
  height: 30px;
  line-height: 30px;
}
</style>
