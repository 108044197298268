<template>
  <div class="listing-item">
    <div class="listing-img">
      <router-link :to="`/quan-ly/cap-nhat/${item.id}`">
        <img
          :src="
            item?.images?.length
              ? item.images[0].url
              : `https://ui-avatars.com/api/?name=NA&size=512`
          "
          class="img-fluid thing-img main-img"
          alt="KIA"
        />
      </router-link>
    </div>
    <div class="" v-if="isOwner">
      <span class="text-blue" style="font-weight: 500; margin-right: 5px"
        >Tình trạng:
      </span>
      <a-switch
        v-model:checked="isPublishedRef"
        checked-children="Hiện"
        un-checked-children="Ẩn"
        @change="onChangeStatus"
        :disabled="!item.images || item.images.length === 0"
      />
    </div>

    <div class="listing-content">
      <div class="listing-features title">
        <h3 class="listing-title">
          <router-link
            :to="`/quan-ly/cap-nhat/${item.id}`"
            class="title-thing"
            >{{ item.title }}</router-link
          >
        </h3>
      </div>
      <div class="listing-details-group d-flex justify-content-between px-1">
        <div class="d-flex gap-2">
          <span>
            <img
              v-if="['ship', 'both'].includes(item.deliveryType)"
              src="@/assets/img/check.svg"
              alt="check icon"
            />
            <img
              v-else
              src="@/assets/img/close.png"
              alt="close icon"
              style="width: 12px"
            />
          </span>
          <p>Giao tận nơi</p>
        </div>
        <div class="d-flex gap-2">
          <span>
            <img
              v-if="['autopick', 'both'].includes(item.deliveryType)"
              src="@/assets/img/check.svg"
              alt="check icon" />
            <img
              v-else
              src="@/assets/img/close.png"
              alt="close icon"
              style="width: 12px"
          /></span>
          <p>Tự đến lấy</p>
        </div>
      </div>
      <div class="listing-location-details">
        <div class="listing-price cut">
          <span class="me-2"><i class="feather-map-pin"></i></span>
          <a-tooltip placement="topLeft">
            <template #title>
              <span>{{
                item?.locations?.map((location) => location.name).join(", ")
              }}</span>
            </template>
            <span class="default">
              {{ item?.locations?.map((location) => location.name).join(", ") }}
            </span>
          </a-tooltip>
        </div>
        <div class="listing-price">
          <h6>{{ formatCurrency(item.price) }}K <span>/ Ngày</span></h6>
        </div>
      </div>
      <div
        class="listing-button d-flex"
        style="column-gap: 1rem"
        v-if="isOwner"
      >
        <router-link
          :to="`/quan-ly/cap-nhat/${item.id}/lich`"
          class="btn btn-order"
        >
          <span><i class="feather-calendar me-2"></i></span>Sửa lịch
        </router-link>
        <router-link :to="`/quan-ly/cap-nhat/${item.id}`" class="btn btn-order">
          <span><i class="feather-file-text me-2"></i></span>Sửa thông
          tin</router-link
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import debounce from "lodash.debounce";
import { useStore } from "vuex";
import { ref } from "vue";
import { formatCurrency } from "@/utils/common";
import { message } from "ant-design-vue";

const props = defineProps({
  item: {},
  isOwner: false,
});
const { item, isOwner } = props;
const store = useStore();
const isPublishedRef = ref(!!item.publishedAt);
const isPublished = ref(!!item.publishedAt);

const onChangeStatus = debounce(async (value) => {
  if (value !== isPublished.value) {
    if (value === true && item.price === 0) {
      isPublishedRef.value = false;
      message.error(
        "Bạn vui lòng set lịch cho sản phẩm trước khi hiển thị cho người dùng khác"
      );
      return;
    }
    isPublished.value = value;
    store.dispatch("thing/update", {
      id: item.id,
      data: { publishedAt: value ? new Date().toISOString() : null },
    });
  }
}, 1000);
</script>

<style scoped>
@media (min-width: 768px) {
  .listing-features.title {
    min-height: 72px;
  }
}

.main-img {
  height: 13vw;
}

@media only screen and (max-width: 2000px) {
  .main-img {
    height: 15vw;
  }
}

@media only screen and (max-width: 1800px) {
  .main-img {
    height: 17vw;
  }
}

@media only screen and (max-width: 1600px) {
  .main-img {
    height: 18vw;
  }
}

@media only screen and (max-width: 1400px) {
  .main-img {
    height: 15vw;
  }
}

@media only screen and (max-width: 1300px) {
  .main-img {
    height: 16vw;
  }
}

@media only screen and (max-width: 992px) {
  .main-img {
    height: 26vw;
  }
}

@media only screen and (max-width: 768px) {
  .main-img {
    height: 50vw;
  }
}

.main-img {
  object-fit: cover;
}

.cut {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  text-align: left;
}

.default {
  cursor: default;
}

.thing-img {
  object-fit: cover;
}

.title-thing {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  height: 3em;
}
</style>
