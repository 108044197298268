import locationService from "@/services/LocationService";

const state = {
  list: undefined,
};

const getters = {
  list: (state) => state.list || [],
};

const actions = {
  async getList({ commit, state }, data) {
    if (state.list === undefined) {
      const res = await locationService.getLocations(data);
      state.list = res.data;
      return res;
    }
    return state.list;
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
