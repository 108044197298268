<template>
  <!-- Why Choose Us -->
  <section class="section why-choose popular-explore">
    <div class="choose-left">
      <img
        src="@/assets/img/bg/choose-left.png"
        class="img-fluid"
        alt="Why Choose Us"
      />
    </div>
    <div class="container">
      <!-- Heading title-->
      <div class="section-heading" data-aos="fade-down">
        <h2>Tại sao bạn nên tìm thuê ở đây</h2>
        <p>Những lợi ích khi bạn tìm thuê tại website</p>
      </div>
      <!-- /Heading title -->
      <div class="why-choose-group">
        <div class="row">
          <div
            class="col-lg-4 col-md-6 col-12 d-flex"
            v-for="item in Choose"
            :key="item.id"
            data-aos="fade-down"
          >
            <div class="card flex-fill">
              <div class="card-body">
                <div :class="item.Class">
                  <img
                    :src="require(`@/assets/img/icons/${item.Image}`)"
                    alt=""
                  />
                </div>
                <div class="choose-content">
                  <h4>{{ item.Title }}</h4>
                  <p>
                    {{ item.Content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Why Choose Us -->

  <!-- About us Testimonials -->
  <section class="section about-testimonial testimonials-section">
    <div class="container">
      <!-- Heading title-->
      <div class="section-heading" data-aos="fade-down">
        <h2 class="title choose-header">Mọi người nói gì về chúng tôi</h2>
        <p class="description choose-header">
          Cùng xem ý kiến của những vị khách thân yêu
        </p>
      </div>
      <!-- /Heading title -->
      <div
        class="owl-carousel about-testimonials testimonial-group mb-0 owl-theme"
      >
        <!-- Carousel Item -->

        <Carousel
          :wrap-around="true"
          :settings="settings"
          :breakpoints="breakpoints"
        >
          <Slide v-for="item in Testimonials" :key="item.id">
            <div class="testimonial-item d-flex">
              <div class="card flex-fill">
                <div class="card-body">
                  <div class="quotes-head"></div>
                  <div class="review-box">
                    <div class="review-profile">
                      <div class="review-img">
                        <img :src="item.Image" class="img-fluid" alt="img" />
                      </div>
                    </div>
                    <div class="review-details">
                      <h6>{{ item.Name }}</h6>
                      <div class="list-rating">
                        <div class="list-rating-star">
                          <i class="fas fa-star filled me-1"></i>
                          <i class="fas fa-star filled me-1"></i>
                          <i class="fas fa-star filled me-1"></i>
                          <i class="fas fa-star filled me-1"></i>
                          <i class="fas fa-star filled"></i>
                        </div>
                        <p><span>(5.0)</span></p>
                      </div>
                    </div>
                  </div>
                  <p>
                    {{ item.Description }}
                  </p>
                </div>
              </div>
            </div>
          </Slide>
          <template #addons>
            <Pagination />
          </template>
        </Carousel>
        <!-- /Carousel Item  -->
      </div>
    </div>
  </section>
  <!-- About us Testimonials -->
</template>

<script setup>
import { Carousel, Pagination, Slide } from "vue3-carousel";
import { computed } from "vue";
import "vue3-carousel/dist/carousel.css";

const Choose = computed(() => [
  {
    id: 1,
    Class: "choose-img choose-black",
    Image: "bx-selection.svg",
    Title: "Tiện lợi và Trực quan",
    Content:
      "Bạn có thể xem thông tin chi tiết về sản phẩm và lịch trống của nó, bao gồm mô tả, hình ảnh và giá cả. Điều này giúp người thuê hiểu rõ hơn về sản phẩm trước khi đặt thuê.",
  },
  {
    id: 2,
    Class: "choose-img choose-secondary",
    Image: "bx-crown.svg",
    Title: "Khả năng Quản lý Lịch Trống Linh Hoạt",
    Content:
      "Với tính năng xem lịch trống, bạn có thể dễ dàng quản lý và cập nhật lịch thuê của sản phẩm của mình. Điều này giúp tiết kiệm thời gian và tránh sự phiền toái của việc xử lý các đơn đặt hàng trùng lặp.",
  },
  {
    id: 3,
    Class: "choose-img choose-primary",
    Image: "bx-user-check.svg",
    Title: "Đa dạng Sản phẩm và Lựa chọn",
    Content:
      "Trang web của chúng tôi cung cấp một loạt các sản phẩm thuê đa dạng, từ đồ gia dụng đến công cụ và thiết bị chuyên ngành. Bạn có thể dễ dàng tìm thấy sản phẩm phù hợp với nhu cầu của mình chỉ trong vài bước đơn giản.",
  },
]);
const Testimonials = computed(() => [
  {
    id: 1,
    Image: require("@/assets/img/profiles/avatar-huy.png"),
    Name: "Huy Trần",
    Description:
      "Ứng dụng này thật tiện lợi và đơn giản! Tôi đã dễ dàng tìm thấy những món đồ cần cho các sự kiện của mình",
  },
  {
    id: 2,
    Image: "https://ui-avatars.com/api/?name=Thanh+Le&size=300",
    Name: "Thành Lê Vũ",
    Description:
      "Rất hài lòng với dịch vụ! Đã tiết kiệm thời gian và chi phí khi thuê đồ cá nhân qua website này",
  },
  {
    id: 3,
    Image: "https://ui-avatars.com/api/?name=Viet+Dung&size=300",
    Name: "Việt Dũng",
    Description:
      "Trang web mang lại trải nghiệm tuyệt vời. Tôi đã thuê đồ cho chuyến du lịch của mình mà không gặp bất kỳ vấn đề nào.",
  },
  {
    id: 4,
    Image: require("@/assets/img/profiles/avatar-vuong.png"),
    Name: "Vương Nguyễn",
    Description:
      "Thật dễ dàng để tìm và thuê đồ qua dịch vụ chia sẻ này. Sẽ tiếp tục sử dụng và giới thiệu cho bạn bè!",
  },
]);
const settings = computed(() => ({
  itemsToShow: 1,
  snapAlign: "center",
}));

const breakpoints = computed(() => ({
  575: {
    itemsToShow: 1,
    snapAlign: "center",
  },
  767: {
    itemsToShow: 1,
    snapAlign: "center",
  },
  991: {
    itemsToShow: 3,
    snapAlign: "center",
  },
  1024: {
    itemsToShow: 2,
    snapAlign: "start",
  },
}));
</script>
<style scoped>
.choose-header {
  color: #ff930a;
}
</style>
