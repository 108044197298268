<template>
  <div id="app">
    <div class="main-wrapper">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { googleOneTap } from "vue3-google-login";
import { watch, computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();

const isSignupWithGoogle = computed(
  () => store.getters["auth/isSignupWithGoogle"]
);
const isAuthenticated = computed(() => store.getters["auth/isAuthenticated"]);
const showGoogleOneTab = ref(false);

watch(
  () => router.currentRoute.value,
  (newRoute) => {
    const { path } = newRoute;
    if (
      isAuthenticated.value === false &&
      !["/dang-nhap", "/dang-ky"].includes(path) &&
      !showGoogleOneTab.value
    ) {
      googleOneTap({ cancelOnTapOutside: false })
        .then((response) => {
          store.dispatch("auth/loginWithGoogle", {
            credential: response.credential,
          });
          showGoogleOneTab.value = true;
        })
        .catch((error) => {
          console.log(error);
          message.error("Lỗi hệ thống, vui lòng thử lại sau");
        });
    }
  }
);

watch(isSignupWithGoogle, (newVal) => {
  if (newVal !== undefined && newVal === true) {
    router.push("/dang-ky");
  }
});
</script>
