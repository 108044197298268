<template>
  <layout-header></layout-header>
  <template v-if="user !== undefined && user !== null">
    <div class="dashboard-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="dashboard-menu">
              <ul>
                <li v-for="item in tabs" :key="item.tab">
                  <a
                    v-bind:class="{
                      active: tab === item.tab,
                    }"
                    @click="tab = item.tab"
                  >
                    <img
                      :src="require(`@/assets/img/icons/${item.icon}`)"
                      alt="Icon"
                    />
                    <span> {{ item.text }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <product-list
      v-if="tab === 'list'"
      :isOwner="!id"
      :owner-id="id ?? authUser?.id"
      @navigate-to-profile="tab = 'profile'"
    />
    <user-profile v-if="tab === 'profile'" :isOwner="!id" />
    <layout-footer></layout-footer>
  </template>
  <template v-else-if="user !== undefined && user === null">
    <not-found></not-found
  ></template>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ProductList from "./user-product-list.vue";
import UserProfile from "./user-profile.vue";

import "../../../../node_modules/nprogress/nprogress.css";
import NProgress from "nprogress";
NProgress.configure({ showSpinner: false });

const store = useStore();
const router = useRouter();
const id = router.currentRoute.value.params.id;
const tab = ref("list");
const tabs = ref([
  {
    tab: "list",
    icon: "dashboard-icon.svg",
    text: "Sản phẩm của tôi",
  },
  {
    tab: "profile",
    icon: "settings-icon.svg",
    text: "Thông tin cá nhân",
  },
]);

const user = ref();
const currentUser = computed(() => store.getters["user/getUser"]);
const authUser = computed(() => store.getters["auth/currentUser"]);

watch(currentUser, (newVal) => {
  user.value = newVal;
  if (newVal !== undefined && newVal !== null) {
    document.title = `${newVal.fullName} - Trang cá nhân`;
  }
  NProgress.done();
});

onMounted(async () => {
  if (id) {
    tab.value = "profile";
    tabs.value = [
      {
        tab: "profile",
        icon: "settings-icon.svg",
        text: "Thông tin cá nhân",
      },
      {
        tab: "list",
        icon: "dashboard-icon.svg",
        text: "Sản phẩm",
      },
    ];
    NProgress.start();
    store.dispatch("user/getById", {
      id,
    });
  } else user.value = authUser.value;
});
</script>
