<template>
  <a-modal
    title=""
    v-model:visible="props.visible"
    :footer="null"
    :onCancel="onClose"
    :maskClosable="!loading"
  >
    <h4>Chỉnh sửa ngày</h4>

    <p class="mt-3 mb-3"
      >Thay đổi sẽ được tạo cho ngày:
      {{ formatDate(props.date, "DD/MM/YYYY") }}</p
    >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      name="basic"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
    >
      <div class="row">
        <div class="col-md-12">
          <a-form-item
            class="label-medium mb-0"
            label="Trạng thái ngày"
            name="type"
          >
            <a-radio-group v-model:value="formState.type">
              <a-radio value="off">Tắt</a-radio>
              <a-radio value="on">Mở</a-radio>
              <a-radio value="booked">Thuê</a-radio>
            </a-radio-group>
          </a-form-item>
        </div>
        <div
          v-if="formState.type === 'booked'"
          class="col-md-12 mt-3"
        >
          <div class="bg-danger-light p-3 border-3">
            <p class="mb-3"
              >Thêm ghi chú để lưu lại thông tin chi tiết ngày nhé!</p
            >
            <a-form-item
              label="Tên người thuê"
              name="nameInfo"
              class="label-medium"
            >
              <a-input
                v-model:value="formState.nameInfo"
                class="full-width"
                placeholder="Vui lòng nhập"
              />
            </a-form-item>
            <a-form-item
              label="Số điện thoại"
              name="phoneInfo"
              class="label-medium"
            >
              <a-input
                v-model:value="formState.phoneInfo"
                class="full-width"
                placeholder="Vui lòng nhập"
              />
            </a-form-item>
            <a-form-item
              label="Ghi chú thêm"
              name="noteInfo"
              class="label-medium"
            >
              <a-textarea
                v-model:value="formState.noteInfo"
                class="full-width"
                placeholder="Vui lòng nhập"
              />
            </a-form-item>
          </div>
        </div>
        <div class="my-3">
          <hr class="color-red" />
        </div>
        <div
          class="col-md-12"
          v-if="formState.type === 'on'"
        >
          <div class="d-flex">
            <span class="mr-3">Bạn muốn thay đổi giá ? </span>
            <a-switch v-model:checked="isChangingPrice" />
          </div>
        </div>
        <div
          class="col-md-6"
          v-if="formState.type === 'on' && isChangingPrice"
        >
          <form-input-number
            v-model="formState.price"
            name="price"
            label="Giá"
            label-class="label-medium"
            input-class="full-width"
            help="Đơn vị tính là Việt Nam đồng"
            :rules="rules.price"
            addon-after="K"
            placeholder="Vui lòng nhập"
          ></form-input-number>
          <div class="mt-3">
            <hr class="color-red" />
          </div>
        </div>
        <a-form-item
          :wrapper-col="{ span: 24 }"
          class="mt-3"
        >
          <div style="display: flex">
            <a-button
              @click="onClose"
              class="btn-border-default mr-3"
              >Hủy</a-button
            >
            <a-button
              class="btn-default"
              type="primary"
              @click.prevent="onSubmit"
              :loading="loading"
              >Lưu</a-button
            >
          </div>
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import { ref, reactive, computed, watch } from "vue";
import {
  checkEmptyString,
  formatDate,
  numberRules,
  isValidPhone,
} from "../../utils/common";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
const store = useStore();

const props = defineProps({
  visible: { type: Boolean, required: true },
  info: { type: Object, required: true },
  id: { type: Number, required: true },
  date: { type: Date, required: true },
});

const isSuccess = computed(() => store.getters["thing/getSuccess"]);
const loading = ref(false);

const emits = defineEmits(["close"]);
const isChangingPrice = ref(false);
const formRef = ref();

const formState = reactive(props.info);
const rules = reactive({
  price: numberRules({ isRequired: true, min: 0, max: 10000 }),
  type: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "blur",
    },
  ],
  nameInfo: [
    {
      required: true,
      message: "Vui lòng nhập",
      trigger: "blur",
    },
    { validator: checkEmptyString, trigger: "blur" },
  ],
  phoneInfo: [
    {
      required: true,
      message: "Vui lòng nhập",
    },
    { validator: checkEmptyString, trigger: "blur" },
    {
      validator: async (_rule, value) => {
        if (value && !isValidPhone(value)) {
          return Promise.reject(new Error("Số điện thoại không hợp lệ"));
        }
      },
      trigger: "blur",
    },
  ],
});

const onSubmit = () => {
  loading.value = true;
  formRef.value
    .validate()
    .then(() => {
      const data = {
        from: formatDate(props.date, "YYYY-MM-DD"),
        to: formatDate(props.date, "YYYY-MM-DD"),
        dayOfWeeks: [props.date.getDay()],
        type: formState.type,
      };
      switch (formState.type) {
        case "on":
          if (isChangingPrice.value) data.price = formState.price;
          break;
        case "booked":
          data.nameInfo = formState.nameInfo;
          data.phoneInfo = formState.phoneInfo;
          data.noteInfo = formState.noteInfo || "";
          break;
        case "off":
          break;
        default:
          break;
      }

      store.dispatch("thing/updateCalendar", { id: props.id, data });
    })
    .catch((err) => {
      loading.value = false;
    });
};

watch(isSuccess, (newVal, oldVal) => {
  if (newVal !== undefined) {
    if (newVal === true) {
      message.success("Cập nhật thành công");
      store.commit("thing/setCurrentDateView", new Date(props.date));
    } else {
      message.error("Có lỗi. Vui lòng thử lại");
    }
    loading.value = false;
    store.commit("thing/succeed", undefined);
  }
});

const onClose = () => {
  formRef.value.resetFields();
  isChangingPrice.value = false;
  emits("close");
};
</script>
<style scoped>
::v-deep label {
  font-weight: 600 !important;
  font-size: 16px !important;
}
::v-deep .ant-input-number-input-wrap {
  height: 30px;
  line-height: 30px;
}
</style>
