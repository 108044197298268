<template>
  <GoogleLogin
    class="full-width"
    :callback="onGoogleLoginCallback"
    popup-type="TOKEN"
  >
    <div class="social-login">
      <a
        href="javascript:;"
        class="d-flex align-items-center justify-content-center input-block btn google-login w-100"
        ><span
          ><img
            src="@/assets/img/icons/google.svg"
            class="img-fluid"
            alt="Google" /></span
        >Đăng nhập với Google</a
      >
    </div>
  </GoogleLogin>
</template>

<script setup>
import { useStore } from "vuex";
import { message } from "ant-design-vue";
const store = useStore();

const onGoogleLoginCallback = async (response) => {
  try {
    store.dispatch("auth/loginWithGoogle", {
      accessToken: response.access_token,
    });
  } catch (e) {
    console.log(e);
    message.error("Lỗi hệ thống, vui lòng thử lại sau");
  }
};
</script>
