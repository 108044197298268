<template>
  <a-modal
    title=""
    v-model:visible="props.visible"
    :footer="null"
    :onCancel="onCancel"
    :maskClosable="!loading"
  >
    <h4>Cài đặt cơ bản cho lịch</h4>
    <p class="mt-3 mb-3">Bạn đang đặt giá và đổi trạng thái cho nhiều ngày!</p>
    <a-form
      :model="formState"
      name="basic"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
      @finish="onFinish"
      @finishFailed="onFinishFailed"
    >
      <form-input-number
        v-model="formState.price"
        label="Giá cơ bản"
        name="price"
        :rules="numberRules({ isRequired: true, min: 0, max: 10000 })"
        help="Đơn vị tính là Việt Nam đồng"
        input-class="full-width"
        addon-after="K"
        placeholder="Vui lòng nhập"
      ></form-input-number>
      <a-form-item
        label="Thời điểm bắt đầu cho thuê"
        name="to"
        :rules="[{ required: true, message: 'Vui lòng nhập' }]"
      >
        <p class="grey mb-1">
          Vui lòng nhập thời gian có thể bắt đầu cho thuê sản phẩm
        </p>
        <a-date-picker
          v-model:value="formState.to"
          class="full-width"
          :picker="date"
          :disabledDate="disabledDate"
          placeholder="Vui lòng nhập"
          :locale="locale"
        />
      </a-form-item>

      <a-form-item name="isPublished">
        <div class="d-flex">
          <p class="mr-3">Bạn muốn hiển thị sản phẩm?</p>
          <a-switch v-model:checked="formState.isPublished"
        /></div>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 24 }">
        <div style="display: flex; justify-content: flex-end">
          <router-link to="/quan-ly/san-pham" class="btn-border-default mr-3"
            >Hủy</router-link
          >
          <a-button
            class="btn-default"
            type="primary"
            html-type="submit"
            :loading="loading"
            >Cài đặt</a-button
          >
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import { ref, reactive, watch, computed } from "vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import dayjs from "dayjs";
import { numberRules } from "@/utils/common";

import locale from "ant-design-vue/es/date-picker/locale/vi_VN";
import "dayjs/locale/vi";
dayjs.locale("vi");

const store = useStore();
const props = defineProps({
  visible: { type: Boolean, required: true },
  id: { type: Number, required: true },
});
const emits = defineEmits(["close", "submit"]);

const formState = reactive({
  price: "",
  to: "",
  isPublished: false,
});

const isSuccess = computed(() => store.getters["thing/getSuccess"]);
const loading = ref(false);

watch(isSuccess, (newValue, oldValue) => {
  if (newValue !== undefined) {
    if (newValue === true) {
      message.success("Cập nhật thành công");
      onCancel();
    } else {
      message.error("Có lỗi. Vui lòng thử lại");
    }
    loading.value = false;
    store.commit("thing/succeed", undefined);
  }
});

const onFinish = ({ price, to, isPublished }) => {
  loading.value = true;
  const isToday =
    dayjs(to).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD");

  store.dispatch("thing/updatePriceThenUpdateCalendar", {
    id: props.id,
    priceData: { price, action: "update" },
    calendarData: isToday
      ? null
      : {
          from: dayjs().format("YYYY-MM-DD"),
          to: dayjs(to).subtract(1, "day").format("YYYY-MM-DD"),
          dayOfWeeks: [0, 1, 2, 3, 4, 5, 6],
          type: "off",
        },
    isPublished,
  });
};

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const onCancel = () => {
  emits("close");
};

const disabledDate = (current) => {
  return current && current < Date.now() - 60 * 60 * 24;
};
</script>
<style scoped>
::v-deep label {
  font-weight: 600 !important;
  font-size: 16px !important;
}
::v-deep .ant-input-number-input-wrap {
  height: 30px;
  line-height: 30px;
}
</style>
