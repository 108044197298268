<template>
  <div class="main-wrapper login-body">
    <!-- Header -->
    <header class="log-header">
      <router-link to="/"
        ><img
          class="img-fluid logo-dark"
          src="@/assets/img/logo.svg"
          alt="Logo"
      /></router-link>
    </header>
    <!-- /Header -->

    <div class="login-wrapper">
      <div class="loginbox">
        <div class="login-auth">
          <div class="login-auth-wrap">
            <div class="sign-group">
              <router-link to="/" class="btn sign-up"
                ><span
                  ><i class="fe feather-corner-down-left" aria-hidden="true"></i
                ></span>
                Trở về trang chủ</router-link
              >
            </div>
            <h1>Đăng nhập</h1>
            <p class="account-subtitle"> Chào mừng bạn trở lại! </p>
            <google-login-form v-if="isSignupWithGoogle" />
            <Form
              v-else
              @submit="onSubmit"
              :validation-schema="schema"
              v-slot="{ errors }"
            >
              <div class="input-block">
                <label class="form-label"
                  >Email <span class="text-danger">*</span></label
                >
                <Field
                  name="email"
                  type="text"
                  value=""
                  class="form-control"
                  :class="{ 'is-invalid': errors.email }"
                />
                <div class="invalid-feedback">{{ errors.email }}</div>
                <div class="emailshow text-danger" id="email"></div>
              </div>
              <div class="input-block mt-3">
                <label class="form-label"
                  >Mật khẩu <span class="text-danger">*</span></label
                >
                <div class="pass-group">
                  <Field
                    name="password"
                    :type="showPassword ? 'text' : 'password'"
                    value=""
                    class="form-control pass-input mt-2"
                    :class="{ 'is-invalid': errors.password }"
                  />
                  <span
                    @click="showPassword = !showPassword"
                    class="toggle-password"
                  >
                    <i
                      :class="{
                        'fas fa-eye': showPassword,
                        'fas fa-eye-slash': !showPassword,
                      }"
                    ></i>
                  </span>
                  <div class="invalid-feedback">{{ errors.password }}</div>
                  <div class="emailshow text-danger" id="password"></div>
                </div>
              </div>
              <div class="input-block mt-3">
                <router-link class="forgot-link" to="/quen-mat-khau"
                  >Quên mật khẩu</router-link
                >
              </div>
              <b-button class="primary btn-block" type="submit"
                >Đăng nhập
              </b-button>
              <div class="login-or">
                <span class="or-line"></span>
                <span class="span-or-log">Hoặc</span>
              </div>
              <!-- /Social Login -->
              <google-login-button />
              <div class="text-center dont-have">
                Bạn chưa có tài khoản?
                <router-link to="/dang-ky">Đăng ký</router-link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <auth-footer></auth-footer>
    <!-- /Footer -->
  </div>

  <spinner-modal :visible="isLoading" />
</template>
<script setup>
import { ref, watch, computed } from "vue";
import { router } from "@/router";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";

const store = useStore();
const loginSucceed = computed(() => store.getters["auth/getSuccess"]);
const isSignupWithGoogle = computed(
  () => store.getters["auth/isSignupWithGoogle"]
);
const showPassword = ref(false);
const isLoading = ref(false);

watch(isSignupWithGoogle, (newVal) => {
  if (newVal !== undefined) {
    isLoading.value = false;
  }
});

watch(loginSucceed, (newVal, oldVal) => {
  if (newVal !== undefined) {
    isLoading.value = false;
    if (newVal === true) {
      store.commit("auth/succeed", undefined);
      router.push("/");
    } else {
      document.getElementById("password").innerHTML =
        "Email hoặc mật khẩu không hợp lệ";
    }
    store.commit("auth/succeed", undefined);
  }
});

const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email là trường bắt buộc")
    .email("Email không hợp lệ"),
  password: Yup.string()
    .min(8, "Mật khẩu phải có ít nhất 8 kí tự")
    .required("Mật khẩu là trường bắt buộc"),
});

const onSubmit = (values) => {
  store.dispatch("auth/login", {
    identifier: values.email.trim(),
    password: values.password,
  });
};
</script>
