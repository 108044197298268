<template>
  <footer class="log-footer">
    <div class="container-fluid">
      <!-- Copyright -->
      <div class="copyright">
        <div class="copyright-text">
          <p>© {{ new Date().getFullYear() }} thuedocanhan</p>
        </div>
      </div>
      <!-- /Copyright -->
    </div>
  </footer>
</template>
