import { createRouter, createWebHistory } from "vue-router";

import Index from "@/views/pages/index/index.vue";
import Register from "@/views/pages/pages/auth/register.vue";
import Login from "@/views/pages/pages/auth/login.vue";
import Listing_Grid from "@/views/pages/listing/listing-grid/listing-grid.vue";
import Listing_Deatils from "@/views/pages/listing/listing-details/listing-details.vue";
import Forgot_Password from "@/views/pages/pages/auth/forgot-password.vue";
import User from "@/views/pages/user/user.vue";
import UserDashboard from "@/views/pages/user/user-dashboard.vue";
import UpdateProductCalendar from "@/views/pages/user/product/update-calendar.vue";
import UserProductCreate from "@/views/pages/user/product/product-detail.vue";
import UserProductUpdate from "@/views/pages/user/product/product-detail.vue";
import Terms from "@/views/pages/terms.vue";
import PrivacyPolicy from "@/views/pages/privacy-policy.vue";
import Disclaimer from "@/views/pages/disclaimer.vue";
import store from "../store";
import authService from "@/services/AuthService";

const routes = [
  {
    path: "/",
    name: "home",
    component: Index,
    meta: {
      title: "Ứng dụng tìm kiếm và cho thuê đồ",
    },
  },
  {
    path: "/dang-nhap",
    name: "login",
    component: Login,
    meta: {
      title: "Đăng nhập",
    },
  },
  {
    path: "/dang-ky",
    name: "register",
    component: Register,
    meta: {
      title: "Đăng ký",
    },
  },
  {
    path: "/dieu-khoan",
    name: "terms",
    component: Terms,
    meta: {
      title: "Điều khoản",
    },
  },
  {
    path: "/chinh-sach-bao-mat",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: {
      title: "Chính sách bảo mật",
    },
  },
  {
    path: "/thong-bao-mien-tru-trach-nhiem",
    name: "disclaimer",
    component: Disclaimer,
    meta: {
      title: "Thông báo miễn trừ trách nhiệm",
    },
  },
  {
    path: "/quen-mat-khau",
    name: "forgot-password",
    component: Forgot_Password,
    meta: {
      title: "Quên mật khẩu",
    },
  },
  {
    path: "/:type(dien-thoai|may-tinh-bang|lightstick|may-tinh|membership|may-anh)",
    component: Listing_Grid,
    meta: {
      title: "Ứng dụng tìm kiếm và cho thuê đồ",
    },
  },
  {
    path: "/:type(dien-thoai|may-tinh-bang|lightstick|may-tinh|membership|may-anh)/:title",
    component: Listing_Deatils,
    meta: {
      title: "Ứng dụng tìm kiếm và cho thuê đồ",
    },
  },
  {
    path: "/quan-ly",
    component: User,
    children: [
      { path: "", redirect: "/quan-ly/san-pham" },
      {
        path: "san-pham",
        component: UserDashboard,
        meta: { requiresAuth: true, title: "Sản phẩm của tôi" },
      },
      {
        path: "them",
        component: UserProductCreate,
        meta: { requiresAuth: true, title: "Thêm đồ cho thuê" },
      },
      {
        path: "cap-nhat/:id",
        component: UserProductUpdate,
        meta: { requiresAuth: true, title: "Chỉnh sửa đồ cho thuê" },
      },
      {
        path: "cap-nhat/:id/lich",
        component: UpdateProductCalendar,
        meta: { requiresAuth: true, title: "Cập nhật lịch" },
      },
    ],
  },
  {
    path: "/nguoi-dung/:id",
    name: "user",
    component: UserDashboard,
    meta: {
      title: "Ứng dụng tìm kiếm và cho thuê đồ",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    redirect: { name: "home" },
  },
];

export const router = createRouter({
  history: createWebHistory("/"),
  linkActiveClass: "active",
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  const isAuthenticated = await isLogin();
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next({ name: "login" });
  } else if (["login", "register"].includes(to.name) && isAuthenticated) {
    next({ name: "home" });
  }
  if (to.path === "/quan-ly/them") {
    const user = store.getters["auth/currentUser"];
    if (!(user.facebook || user.zalo || user.phone)) next({ name: "home" });
  }

  window.scrollTo({ top: 0, behavior: "smooth" });
  next();
});

const isLogin = async () => {
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  if (isAuthenticated === undefined) {
    const token = window.$store.state.auth.token;
    if (token) {
      const user = await authService.getMe();
      if (user) {
        store.commit("auth/setCurrentUser", user);
        store.commit("auth/setIsAuthenticated", true);
      } else store.commit("auth/setIsAuthenticated", false);

      return !!user;
    }
    store.commit("auth/setIsAuthenticated", false);
    return false;
  }
  return isAuthenticated;
};
