<script setup>
import { checkEmptyString } from "@/utils/common";
import { ref, reactive } from "vue";

const props = defineProps({
  brands: { type: Array, required: true },
  years: { type: Array, required: true },
  lightstick: { type: Object, required: false },
});
const emits = defineEmits([]);

const formRef = ref();
const form = reactive({
  brand: null,
  brandOtherText: "",
  name: "",
  version: "",
  year: null,
  accessories: [],
});

const formRules = reactive({
  brand: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "blur",
    },
  ],
  brandOtherText: [
    {
      required: true,
      message: "Vui lòng nhập",
      trigger: "blur",
    },
    { validator: checkEmptyString, trigger: "blur" },
  ],
  name: [
    {
      required: true,
      message: "Vui lòng nhập",
      trigger: "blur",
    },
    { validator: checkEmptyString, trigger: "blur" },
  ],
  year: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "blur",
    },
  ],
  version: [
    {
      required: true,
      message: "Vui lòng nhập",
      trigger: "blur",
    },
    { validator: checkEmptyString, trigger: "blur" },
  ],
});

if (props.lightstick) {
  Object.assign(form, props.lightstick);
}

const validateForm = () => {
  return formRef.value.validate();
};
defineExpose({ validateForm, values: form });
</script>

<template>
  <a-form
    :model="form"
    ref="formRef"
    :rules="formRules"
    name="basic"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    class="row"
  >
    <div class="col-lg-6">
      <div class="input-block">
        <a-form-item
          label="Nghệ sĩ"
          name="brand"
        >
          <a-select
            v-model:value="form.brand"
            style="width: 100%"
            :options="props.brands"
            placeholder="Vui lòng chọn"
          ></a-select>
        </a-form-item>
        <div
          v-if="form.brand == -1"
          class="d-flex align-items-start"
        >
          <img
            src="@/assets/img/other_brand_arrow.svg"
            alt="Other brand"
            style="margin-top: 18px"
          />
          <div class="input-block full-width ml-3">
            <a-form-item
              label="Nghệ sĩ khác"
              name="brandOtherText"
            >
              <a-input
                v-model:value="form.brandOtherText"
                class="full-width form-control"
                placeholder="Vui lòng nhập"
              />
            </a-form-item>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="input-block">
        <a-form-item
          label="Tên lightstick"
          name="name"
        >
          <a-input
            v-model:value="form.name"
            class="full-width form-control"
            placeholder="Vui lòng nhập"
          />
        </a-form-item>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="input-block">
        <a-form-item
          label="Ver"
          name="version"
        >
          <a-input
            v-model:value="form.version"
            class="full-width form-control"
            placeholder="Vui lòng nhập"
          />
        </a-form-item>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="input-block">
        <a-form-item
          label="Năm sản xuất"
          name="year"
        >
          <a-select
            v-model:value="form.year"
            style="width: 100%"
            :options="props.years"
            placeholder="Vui lòng chọn"
          ></a-select>
        </a-form-item>
      </div>
    </div>
  </a-form>
</template>
