<template>
  <div class="col-lg-3 col-12 theiaStickySidebar">
    <div class="stickysidebar">
      <a-form
        :model="filter"
        ref="formRef"
        :rules="formRules"
        name="basic"
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        autocomplete="off"
        class="sidebar-form"
      >
        <div class="accordion" id="accordionMain1">
          <div class="card-header-new" id="headingOne">
            <h6 class="filter-title">
              <a
                href="javascript:void(0);"
                class="w-100"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Thương hiệu
                <span class="float-end"
                  ><i class="fa-solid fa-chevron-down"></i
                ></span>
              </a>
            </h6>
          </div>
          <div
            id="collapseOne"
            class="collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample1"
          >
            <div class="card-body-chat">
              <div class="row">
                <div class="col-md-12">
                  <div id="checkBoxes1">
                    <div class="selectBox-cont">
                      <a-checkbox-group v-model:value="filter.brands">
                        <a-checkbox
                          class="full-width my-1"
                          :value="item.value"
                          name="brands"
                          :key="item"
                          v-for="item in brands"
                        >
                          {{ item.label }}</a-checkbox
                        >
                      </a-checkbox-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Customer -->

        <div class="accordion" id="accordionMain2">
          <div class="card-header-new" id="headingTwo">
            <h6 class="filter-title">
              <a
                href="javascript:void(0);"
                class="w-100 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Địa điểm thuê
                <span class="float-end"
                  ><i class="fa-solid fa-chevron-down"></i
                ></span>
              </a>
            </h6>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample2"
          >
            <div id="checkBoxes2">
              <div class="card-body-chat">
                <a-radio-group v-model:value="filter.location">
                  <a-radio
                    class="full-width my-1"
                    :value="item.value"
                    name="locations"
                    :key="item"
                    v-for="item in locations"
                  >
                    {{ item.label }}</a-radio
                  >
                </a-radio-group>
              </div>
            </div>
          </div>
        </div>

        <!-- By Status -->
        <div
          v-if="accessories && accessories.length"
          class="accordion"
          id="accordionMain3"
        >
          <div class="card-header-new" id="headingThree">
            <h6 class="filter-title">
              <a
                href="javascript:void(0);"
                class="w-100 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                Hỗ trợ
                <span class="float-end"
                  ><i class="fa-solid fa-chevron-down"></i
                ></span>
              </a>
            </h6>
          </div>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample3"
          >
            <div class="card-body-chat">
              <div id="checkBoxes3">
                <div class="selectBox-cont">
                  <a-checkbox-group v-model:value="filter.accessories">
                    <a-checkbox
                      class="full-width my-1"
                      :value="item.value"
                      name="accessories"
                      :key="item"
                      v-for="item in accessories"
                    >
                      {{ item.label }}</a-checkbox
                    >
                  </a-checkbox-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /By Status -->

        <!-- Category -->
        <div class="accordion" id="accordionMain4">
          <div class="card-header-new" id="headingFour">
            <h6 class="filter-title">
              <a
                href="javascript:void(0);"
                class="w-100 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="true"
                aria-controls="collapseFour"
              >
                Địa điểm giao dịch
                <span class="float-end"
                  ><i class="fa-solid fa-chevron-down"></i
                ></span>
              </a>
            </h6>
          </div>
          <div
            id="collapseFour"
            class="collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample4"
          >
            <div class="card-body-chat">
              <div id="checkBoxes3">
                <div class="selectBox-cont">
                  <a-checkbox-group v-model:value="filter.deliveryType">
                    <a-checkbox
                      class="full-width my-1"
                      :value="DELIVERY_TYPE.AUTOPICK"
                      name="deliveryType"
                    >
                      Khách tới tận nơi lấy</a-checkbox
                    >
                    <a-checkbox
                      class="full-width my-1"
                      :value="DELIVERY_TYPE.SHIP"
                      name="deliveryType"
                    >
                      Bạn giao tận nơi cho khách</a-checkbox
                    >
                  </a-checkbox-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion" id="accordionMain5">
          <div class="card-header-new" id="headingFive">
            <h6 class="filter-title">
              <a
                href="javascript:void(0);"
                class="w-100 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="true"
                aria-controls="collapseFive"
              >
                Giá
                <span class="float-end"
                  ><i class="fa-solid fa-chevron-down"></i
                ></span>
              </a>
            </h6>
          </div>
          <div
            id="collapseFive"
            class="collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample5"
          >
            <div class="card-body-chat">
              <a-slider
                v-model:value="filter.priceRange"
                :min="0"
                :max="10000"
                :step="100"
                :marks="{
                  0: '0',
                  10000: '10tr',
                }"
                :tip-formatter="
                  (value) => {
                    return `${value}k`;
                  }
                "
                range
              />
            </div>
          </div>
        </div>
        <!-- /Category -->

        <div class="accordion" id="accordionMain6">
          <div class="card-header-new" id="headingSix">
            <h6 class="filter-title">
              <a
                href="javascript:void(0);"
                class="w-100 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="true"
                aria-controls="collapseSix"
              >
                Thời gian
                <span class="float-end"
                  ><i class="fa-solid fa-chevron-down"></i
                ></span>
              </a>
            </h6>
          </div>
          <div
            id="collapseSix"
            class="collapse"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample6"
          >
            <div class="card-body-chat">
              <p class="mb-2">Từ ngày</p>
              <a-form-item name="from">
                <a-date-picker
                  v-model:value="filter.from"
                  class="full-width"
                  :picker="date"
                  :disabledDate="disabledDate"
                  placeholder="Vui lòng chọn"
                  @change="(evt) => onDateChange('from', evt)"
                  :locale="locale"
                />
              </a-form-item>
              <p class="mb-2 mt-3">Đến ngày</p>
              <a-form-item name="to">
                <a-date-picker
                  v-model:value="filter.to"
                  class="full-width"
                  :picker="date"
                  :disabledDate="disabledDate"
                  placeholder="Vui lòng chọn"
                  @change="(evt) => onDateChange('to', evt)"
                  :locale="locale"
                />
              </a-form-item>
            </div>
          </div>
        </div>
        <button
          type="submit"
          @click="onSubmit"
          class="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary filter-btn"
        >
          <span><i class="feather-filter me-2"></i></span>Lọc
        </button>
        <a href="javascript:;" class="reset-filter" @click="onReset">Xoá lọc</a>
      </a-form>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "vuex";
import { ref, computed, watchEffect, reactive, watch } from "vue";
import { AccessoryOptions, DELIVERY_TYPE, PRODUCT_TYPE } from "@/constants";
import dayjs from "dayjs";
import locale from "ant-design-vue/es/date-picker/locale/vi_VN";
import "dayjs/locale/vi";
dayjs.locale("vi");
import isEmpty from "lodash.isempty";

const otherBrandOptionValue = -1;
const props = defineProps({
  type: "",
});
const emits = defineEmits(["onSubmit", "onReset"]);
const store = useStore();

const filter = ref({
  brands: [],
  location: null,
  accessories: [],
  priceRange: [0, 10000],
  deliveryType: [],
  from: null,
  to: null,
});
const accessories = ref([]);
const formRef = ref();
const formRules = reactive({
  from: [
    {
      validator: async (_rule, value) => {
        if (filter.value.to) {
          const diff = dayjs(value).diff(dayjs(filter.value.to), "day");
          if (diff > 0)
            return Promise.reject(
              "Vui lòng chọn bằng hoặc trước ngày kết thúc"
            );
        }
      },
      trigger: "change",
    },
  ],
  to: [
    {
      validator: async (_rule, value) => {
        if (filter.value.from) {
          const diff = dayjs(value).diff(dayjs(filter.value.from), "day");
          if (diff < 0)
            return Promise.reject("Vui lòng chọn bằng hoặc sau ngày bắt đầu");
        }
      },
      trigger: "change",
    },
  ],
});

watch(
  () => filter.value.from,
  () => {
    if (formRef.value) formRef.value.validate();
  }
);

watch(
  () => filter.value.to,
  () => {
    if (formRef.value) formRef.value.validate();
  }
);

const defaultFilter = computed(() => store.getters["thing/filters"]);

const brands = computed(() => {
  const list = store.getters["brand/list"].map((item) => {
    return {
      value: item.id,
      label: item.attributes.name,
    };
  });
  return [...list, { value: otherBrandOptionValue, label: "Khác" }];
});

const locations = computed(() =>
  store.getters["location/list"].map((item) => {
    return {
      value: item.id,
      label: item.attributes.name,
    };
  })
);

const onSubmit = () => {
  const { value } = filter;
  if (value.from && value.to) {
    if (dayjs(value.from).diff(dayjs(value.to), "day") > 0) {
      return;
    }
  }
  const data = {};

  if (value.location) data.locations = { id: value.location };
  if (value.deliveryType.length === 1)
    data.deliveryType = { $in: ["both", value.deliveryType[0]] };

  if (value.accessories.length) {
    const type = [
      PRODUCT_TYPE.PC,
      PRODUCT_TYPE.PHONE,
      PRODUCT_TYPE.TABLET,
    ].includes(props.type)
      ? "device"
      : props.type;

    data[type] = Object.fromEntries(
      value.accessories.map((accessory) => [accessory, true])
    );
  }
  if (value.brands.length) {
    if (!value.brands.includes(otherBrandOptionValue)) {
      data.brand = {
        id: {
          $in: value.brands,
        },
      };
    } else {
      if (value.brands.length === 1)
        data.brand = {
          id: {
            $null: true,
          },
        };
      else
        data["$or"] = [
          {
            brand: {
              id: {
                $null: true,
              },
            },
          },
          {
            brand: {
              id: {
                $in: value.brands.filter(
                  (item) => item !== otherBrandOptionValue
                ),
              },
            },
          },
        ];
    }
  }

  const [fromPrice, toPrice] = value.priceRange;

  if (fromPrice > 0 && toPrice < 10000)
    data.price = { $between: [fromPrice, toPrice] };
  else if (fromPrice > 0 && fromPrice < 10000) data.price = { $gte: fromPrice };
  else if (toPrice > 0 && toPrice < 10000) data.price = { $lte: toPrice };

  if (value.from && value.to) {
    const [from, to] = [value.from.toDate(), value.to.toDate()];
    const [fromDate, toDate] = [
      from.toISOString().slice(0, 10),
      to.toISOString().slice(0, 10),
    ];
    const time = [
      {
        availabilities: {
          id: { $null: true },
        },
      },
      {
        availabilities: {
          from: {
            $lte: fromDate,
          },
          to: {
            $gte: toDate,
          },
        },
      },
    ];
    if (data["$or"]) {
      data["$and"] = [
        {
          $or: data["$or"],
        },
        {
          $or: time,
        },
      ];
      delete data["$or"];
    } else {
      data["$or"] = time;
    }
  }

  emits("onSubmit", data);
};

watchEffect(() => {
  if (!isEmpty(defaultFilter.value)) {
    if (defaultFilter.value.from) {
      filter.value.from = defaultFilter.value.from;
    }
    if (defaultFilter.value.to) {
      filter.value.to = defaultFilter.value.to;
    }
    if (defaultFilter.value.location) {
      filter.value.location = defaultFilter.value.location;
    }
    onSubmit();
    store.commit("thing/setFilters", null);
  }
});

store.dispatch("location/getList", {
  pagination: {
    pageSize: 100,
  },
  sort: "order",
});

const disabledDate = (current) => {
  return current && current < Date.now() - 60 * 60 * 24;
};

const onDateChange = (dateField, newDate) => {
  if (!newDate) {
    filter.value.from = filter.value.to = null;
  } else if (!filter.value[dateField === "from" ? "to" : "from"]) {
    filter.value[dateField === "from" ? "to" : "from"] =
      filter.value[dateField];
  }
};

const onReset = () => {
  filter.value = {
    brands: [],
    location: null,
    accessories: [],
    priceRange: [0, 0],
    deliveryType: [],
    from: null,
    to: null,
  };

  emits("onSubmit", {});
};

watchEffect(() => {
  accessories.value = AccessoryOptions[props.type];
  store.dispatch("brand/getList", {
    sort: "name",
    filters: { type: props.type },
  });
});

watch(
  () => props.type,
  () => {
    onReset();
  }
);
</script>
<style scoped>
::v-deep .ant-checkbox-wrapper,
.ant-radio-wrapper {
  color: #787878;
  font-size: 15px;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
